import { gql } from '@apollo/client';

export const GET_USER_RETIREMENT_CONTRIBUTIONS = gql`
  query getUserRetirementContributions($year: Uint!) {
    userRetirementContributions(where: { year: $year }) {
      userID
      year
      limits {
        year
        totalLimit
        traditionalIRALimit
        rothIRALimit
      }
      totals {
        combined {
          total
          totalWithPending
        }
        traditionalIRA {
          total
          totalWithPending
        }
        rothIRA {
          total
          totalWithPending
        }
      }
    }
  }
`;

export const GET_RETIREMENT_INFO = gql`
  query getRetirementInfo($state: String!) {
    retirementFundamentalInfo {
      withholding {
        federalWithholding {
          rate
        }
        stateWithholding(state: $state) {
          state
          class
          rate
          rateType
        }
      }
    }
  }
`;
