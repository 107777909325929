import React, { useMemo, useState } from 'react';

// FinTron
import {
  WebBodyText12,
  WebBodyText16,
  WebBodyText20,
  WebHeading38,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import ProgressBar from '@/components/common/ProgressBar/ProgressBar';

// FinTron SVGs
import { UserAccountAccountType } from '@fintronners/react-api/src';
import { TaxYearSelect } from './parts/TaxYearSelect';
import { Skeleton } from '@/components/common/Skeleton';
import {
  formatPercent,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { RothIRAIcon, TradIRAIcon } from '@/assets/icons';
import Image from 'next/image';
import { useAccountValues } from '@/hooks/api/useAccountValues';
import { useTenantBasedStrings } from '@/hooks/config/useTenantBasedStrings';
import useAllAccountsData from '@fintronners/react-widgets/src/hooks/market/useAllAccountsData';
import { Account } from './parts/Account';
import { calculateAccountMetrics } from './helper';
import { useContributions } from '@/hooks/api/useContributions';

const currentYear = new Date().getFullYear().toString();

const displayStrings = {
  title: 'Retirement Value',
  contributionLimit: 'Contribution limit:',
  description:
    'Your annual contribution limit is a combination of your Roth IRA and Traditional IRA. This may not exceed $7,000 if you are below 50 years old ($8,000 if you are above 50 years old).',
  legal: (brokerDealer: string, clearingFirm: string) =>
    `Brokerage services for registered securities are offered to self-directed customers by ${brokerDealer}. Cash and securities are custodied at ${clearingFirm}.`,
};

const RetirementIndexSidePanel: React.FC = () => {
  const [taxYear, setTaxYear] = useState<string>(currentYear);

  const { selfDirected, ira, rothIra } = useAllAccountsData();

  const {
    gainsLosses: brokerageGainsLosses,
    returnRate: brokerageReturnRate,
    loading: brokerageLoading,
  } = useAccountValues(UserAccountAccountType.AccountTypeBrokerage);

  const {
    gainsLosses: tradIraGainsLosses,
    returnRate: tradIraReturnRate,
    loading: tradIraLoading,
  } = useAccountValues(UserAccountAccountType.AccountTypeIra);

  const {
    gainsLosses: rothIraGainsLosses,
    returnRate: rothIraReturnRate,
    loading: rothIraLoading,
  } = useAccountValues(UserAccountAccountType.AccountTypeRothIra);

  const { brokerDealer, clearingFirm } = useTenantBasedStrings();

  const { userRetirementContributions, isContributionsLoading } = useContributions({
    taxYear,
  });

  const isLoading = isContributionsLoading || brokerageLoading || rothIraLoading || tradIraLoading;

  const currentRetirementContribution = useMemo(
    () => userRetirementContributions?.at(0),
    [userRetirementContributions],
  );

  // Total Currencies
  const totalWithPendingCurrency = thousandFormatNumbroCurrency(
    currentRetirementContribution?.totals.combined.totalWithPending,
  );
  const totalLimitCurrency = thousandFormatNumbroCurrency(
    currentRetirementContribution?.limits.totalLimit,
  );

  // Trad-IRA Currencies
  const tradIRAPendingCurrency = thousandFormatNumbroCurrency(
    currentRetirementContribution?.totals.traditionalIRA.totalWithPending,
  );
  const tradIRALimitCurrency = thousandFormatNumbroCurrency(
    currentRetirementContribution?.limits.traditionalIRALimit,
  );

  // Roth-IRA Currencies
  const rothIRAPendingCurrency = thousandFormatNumbroCurrency(
    currentRetirementContribution?.totals.rothIRA.totalWithPending,
  );
  const rothIRALimitCurrency = thousandFormatNumbroCurrency(
    currentRetirementContribution?.limits.rothIRALimit,
  );

  const { allAccountsValue, allAccountsReturnRate, allAccountsGainsLosses } = useMemo(() => {
    return calculateAccountMetrics({
      brokerage: {
        totalValue: selfDirected?.totalValue || 0,
        gainsLosses: brokerageGainsLosses,
        returnRate: brokerageReturnRate,
      },
      rothIra: {
        totalValue: rothIra?.totalValue || 0,
        gainsLosses: rothIraGainsLosses,
        returnRate: rothIraReturnRate,
      },
      tradIra: {
        totalValue: ira?.totalValue || 0,
        gainsLosses: tradIraGainsLosses,
        returnRate: tradIraReturnRate,
      },
    });
  }, [brokerageLoading, tradIraLoading, rothIraLoading]);

  const handleChangeTaxYear = (value: string) => setTaxYear(value);

  return (
    <React.Fragment>
      <SheetContent>
        <div>
          <SheetHeader>
            <SheetTitle>{displayStrings.title}</SheetTitle>
          </SheetHeader>

          {isLoading ? (
            <Skeleton height={57} />
          ) : (
            <WebHeading38.Bold className="text-darkestBlue">
              {thousandFormatNumbroCurrency(allAccountsValue)}
            </WebHeading38.Bold>
          )}

          {isLoading ? (
            <Skeleton height={21} className="mt-2" />
          ) : (
            <div className="mb-6 flex">
              <WebBodyText16.Bold
                className={allAccountsGainsLosses >= 0 ? 'text-green' : 'text-red'}
              >
                {thousandFormatNumbroCurrency(allAccountsGainsLosses)}
              </WebBodyText16.Bold>
              <WebBodyText16.Bold
                className={allAccountsReturnRate >= 0 ? 'text-green ml-2' : 'text-red ml-2'}
              >
                (${formatPercent(allAccountsReturnRate)})
              </WebBodyText16.Bold>
            </div>
          )}

          <div className="mb-4 mt-3 flex items-center">
            <WebBodyText20.Bold className="mr-4 text-darkestBlue">
              {displayStrings.contributionLimit}
            </WebBodyText20.Bold>

            <TaxYearSelect
              defaultValue={currentYear}
              value={taxYear}
              onValueChange={handleChangeTaxYear}
            />
          </div>

          <div className="mb-4">
            <WebBodyText16.Regular className="text-grey72">
              {displayStrings.description}
            </WebBodyText16.Regular>
          </div>

          {/* Total Contributions */}
          {isLoading ? (
            <Skeleton height={45} className="mb-2" />
          ) : (
            <div className="mb-4 flex flex-row flex-wrap">
              <div className="flex-shrink flex-grow">
                <WebBodyText16.Regular className="text-grey72">
                  Total {taxYear} Contributions
                </WebBodyText16.Regular>
              </div>

              <div className="flex-shrink flex-grow text-right">
                <WebBodyText16.Regular className="text-grey72">
                  {totalWithPendingCurrency} / {totalLimitCurrency}
                </WebBodyText16.Regular>
              </div>

              <div className="w-full">
                <ProgressBar total={currentRetirementContribution?.limits.totalLimit} noBg>
                  <ProgressBar.Segment
                    value={currentRetirementContribution?.totals.traditionalIRA.totalWithPending}
                  />
                  <ProgressBar.Segment
                    variant="secondary"
                    value={currentRetirementContribution?.totals.rothIRA.totalWithPending}
                  />
                  <ProgressBar.Segment fill />
                </ProgressBar>
              </div>
            </div>
          )}

          {/* Trad-IRA Contributions */}
          {isLoading ? (
            <Skeleton height={45} className="mb-2" />
          ) : (
            <div className="mb-4 flex flex-row flex-wrap">
              <div className="flex-shrink flex-grow">
                <WebBodyText16.Regular className="text-grey72">
                  Traditional IRA
                </WebBodyText16.Regular>
              </div>

              <div className="flex-shrink flex-grow text-right">
                <WebBodyText16.Regular className="text-grey72">
                  {tradIRAPendingCurrency} / {tradIRALimitCurrency}
                </WebBodyText16.Regular>
              </div>

              <div className="w-full">
                <ProgressBar total={currentRetirementContribution?.limits.traditionalIRALimit} noBg>
                  <ProgressBar.Segment
                    value={currentRetirementContribution?.totals.traditionalIRA.totalWithPending}
                  />
                  <ProgressBar.Segment fill />
                </ProgressBar>
              </div>
            </div>
          )}

          {/* Roth-IRA Contributions */}
          {isLoading ? (
            <Skeleton height={45} className="mb-2" />
          ) : (
            <div className="mb-4 flex flex-row flex-wrap">
              <div className="flex-shrink flex-grow">
                <WebBodyText16.Regular className="text-grey72">Roth IRA</WebBodyText16.Regular>
              </div>

              <div className="flex-shrink flex-grow text-right">
                <WebBodyText16.Regular className="text-grey72">
                  {rothIRAPendingCurrency} / {rothIRALimitCurrency}
                </WebBodyText16.Regular>
              </div>

              <div className="w-full">
                <ProgressBar total={currentRetirementContribution?.limits.rothIRALimit} noBg>
                  <ProgressBar.Segment
                    variant="secondary"
                    value={currentRetirementContribution?.totals.rothIRA.totalWithPending}
                  />
                  <ProgressBar.Segment fill />
                </ProgressBar>
              </div>
            </div>
          )}

          <hr className="my-8 border-grey55" />

          <div className="mb-6">
            <WebBodyText20.Bold className="text-darkestBlue">
              <span className="flex items-center">Accounts</span>
            </WebBodyText20.Bold>
          </div>

          {isLoading ? (
            <Skeleton height={65} />
          ) : (
            <Account
              title="Roth IRA"
              icon={<Image alt="Roth IRA icon" width={35} height={35} src={RothIRAIcon.src} />}
              total={rothIra?.totalValue}
              accountType="roth-ira"
            />
          )}

          {isLoading ? (
            <Skeleton height={65} className="mt-4" />
          ) : (
            <Account
              title="Traditional IRA"
              icon={
                <Image alt="Traditional IRA icon" width={35} height={35} src={TradIRAIcon.src} />
              }
              total={ira?.totalValue}
              accountType="trad-ira"
            />
          )}
        </div>

        <SheetFooter>
          <WebBodyText12.Regular className="text-center text-grey55">
            {displayStrings.legal(brokerDealer.name, clearingFirm.name)}
          </WebBodyText12.Regular>
        </SheetFooter>
      </SheetContent>
    </React.Fragment>
  );
};

export default RetirementIndexSidePanel;
