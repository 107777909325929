import { Button } from '@/components/common/Button';
import { SheetContent, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { SwitchRoot, SwitchThumb } from '@/components/common/Switch/Switch';
import { useSidePanel } from '@/context/SidePanelContext';
import {
  WebBodyText12,
  WebBodyText14,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { Skeleton } from '@/components/common/Skeleton';

const displayStrings = {
  title: 'Two-factor authentication',
  login: {
    label: 'Login',
    description:
      'We will send you an email with the two-factor authentication code for each login.',
  },
  cancel: 'Cancel',
};

export const MFASidePanelOptions = () => {
  const { userDetails, loading: isLoading } = useUserDetails();
  const { goTo, closeSidePanel } = useSidePanel();

  return (
    <SheetContent>
      <div>
        <SheetHeader>
          <SheetTitle>{displayStrings.title}</SheetTitle>
        </SheetHeader>
        <div className="w-full flex flex-col items-center justify-center">
          {isLoading ? (
            <Skeleton className="mt-6 w-full h-16" />
          ) : (
            <div className="mt-6 flex items-center">
              <div>
                <WebBodyText14.Bold className="text-darkestBlue">
                  {displayStrings.login.label}
                </WebBodyText14.Bold>
                <WebBodyText12.Regular className="text-grey55">
                  {displayStrings.login.description}
                </WebBodyText12.Regular>
              </div>
              <div className="ml-8">
                <SwitchRoot
                  defaultChecked={userDetails?.isLoginMfaEnabled}
                  onCheckedChange={() => goTo(1)}
                >
                  <SwitchThumb />
                </SwitchRoot>
              </div>
            </div>
          )}
        </div>
      </div>
      <Button className="w-full mb-4" variant="secondary" onClick={closeSidePanel}>
        {displayStrings.cancel}
      </Button>
    </SheetContent>
  );
};
