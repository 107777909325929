import React, { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import ReactMarkdown from 'react-markdown';
import { SheetContent, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { SearchInput } from '@/components/common/SearchInput';
import { ListItemButton } from '@/components/common/ListItemButton';
import { Button } from '@/components/common/Button';
import {
  WebBodyText16,
  WebBodyText18,
  WebBodyText20,
  WebHeading28,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';
import { InfoIcon, InfoPrimaryIcon, SuccessCircleIcon } from '@/assets/icons';
import { useSidePanel } from '@/context/SidePanelContext';
import { SupportFaqCategory } from '@fintronners/react-api/lib/typescript/src/graphql/types/graphql';
import ContactForm from '@/components/forms/ContactForm/ContactForm';
import { Contacts } from './parts/Contacts';
import { useAppStore } from '@/stores/app-store';

type SupportListItem = {
  id: string;
  title: string;
  subtitle: string;
  content: string;
  page?: number;
};

type FAQsSidePanelProps = {
  faqSection: SupportFaqCategory;
};

const displayStrings = {
  faqsSidePanelTitle: 'Contact us',
  faqsSidePanelSubmit: 'Submit',
  faqsSidePanelClose: 'Close',
  faqsSidePanelDone: 'Done',
  faqsSidePanelBack: 'Back',
  faqsSidePanelErrorTitle: 'Sorry',
  faqsSidePanelErrorTxt:
    'It looks like something is wrong. Please try again. If the problem continues, contact ',
  faqsSidePanelSuccessTitle: 'Form submitted',
  faqsSidePanelSuccessTxt: 'Thanks for submitting the form.',
  faqsSidePanelNoDocsAvailable: 'No docs available',
};

const FAQsSidePanel: React.FC<FAQsSidePanelProps> = ({ faqSection }) => {
  const [filteredSupportItems, setFilteredSupportItems] = useState<SupportListItem[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedSupportItem, setSelectedSupportItem] = useState<SupportListItem | null>(null);
  const { tenants } = useFeatureFlags();
  const { currentFlowStep, goTo, closeSidePanel, setShowBackLink } = useSidePanel();
  const supportEmail = useAppStore((state) => state.tenantSupportEmail);

  const title =
    currentFlowStep === 2
      ? displayStrings.faqsSidePanelTitle
      : currentFlowStep === 3 || currentFlowStep === 4
        ? ''
        : faqSection.name;

  const menuItems: SupportListItem[] = useMemo(
    () =>
      faqSection?.faqs?.map((faq, index) => ({
        id: faq.id,
        title: faq.type,
        subtitle: faq.title,
        content: faq.description,
        page: index + 1,
      })) ?? [],
    [faqSection],
  );

  const filterByText = () => {
    return menuItems.filter(
      (item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase()) ||
        item.subtitle.toLowerCase().includes(searchText.toLowerCase()) ||
        item.content.toLowerCase().includes(searchText.toLowerCase()),
    );
  };

  useEffect(() => {
    if (searchText !== '') {
      setFilteredSupportItems(filterByText());
    } else {
      setFilteredSupportItems(menuItems);
    }
  }, [menuItems, searchText]);

  return (
    <SheetContent>
      <div className="flex flex-col gap-5 h-full">
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        {currentFlowStep === 0 && (
          <div className="h-full flex flex-col gap-5">
            <SearchInput
              placeholder="Search"
              initialValue={searchText}
              onSearch={(value) => setSearchText(value)}
            />
            {filteredSupportItems?.length === 0 && (
              <div className="h-full flex justify-center items-center gap-2">
                <Image alt="Info icon" width={20} height={20} src={InfoIcon.src} />
                <WebBodyText20.Regular>
                  {displayStrings.faqsSidePanelNoDocsAvailable}
                </WebBodyText20.Regular>
              </div>
            )}
            <div>
              {filteredSupportItems?.length > 0 &&
                filteredSupportItems.map((faq) => (
                  <ListItemButton
                    key={faq.id}
                    title={faq.title}
                    subtitle={faq.subtitle}
                    onClick={() => {
                      setSelectedSupportItem(faq);
                      goTo(1);
                    }}
                  />
                ))}
            </div>
          </div>
        )}
        {currentFlowStep === 1 && selectedSupportItem && (
          <div className="flex flex-col h-full justify-between">
            <WebBodyText16.Regular className="text-grey72">
              <ReactMarkdown>{selectedSupportItem.content}</ReactMarkdown>
            </WebBodyText16.Regular>
            <div className="flex flex-col justify-center items-center w-full gap-[10px]">
              <Button className="w-full" onClick={() => goTo(2)}>
                {displayStrings.faqsSidePanelTitle}
              </Button>
              <Button onClick={closeSidePanel} className="w-full" variant="secondary">
                Close
              </Button>
            </div>
          </div>
        )}
        {currentFlowStep === 2 && (
          <>
            {tenants?.isAlight ? (
              <Contacts onBackClick={() => goTo(1)} />
            ) : (
              <ContactForm
                onSuccess={() => {
                  goTo(3);
                  setShowBackLink(false);
                }}
                onError={() => {
                  goTo(4);
                  setShowBackLink(false);
                }}
              />
            )}
          </>
        )}
        {currentFlowStep === 3 && (
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-col justify-center items-center gap-[30px]">
              <Image src={SuccessCircleIcon} width={50} height={50} alt="success" />
              <WebHeading28.Bold className="text-darkestBlue">
                {displayStrings.faqsSidePanelSuccessTitle}
              </WebHeading28.Bold>
              <WebBodyText18.Regular className="text-center text-grey72">
                {displayStrings.faqsSidePanelSuccessTxt}
              </WebBodyText18.Regular>
            </div>
            <Button className="w-full" onClick={closeSidePanel}>
              {displayStrings.faqsSidePanelDone}
            </Button>
          </div>
        )}
        {currentFlowStep === 4 && (
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-col justify-center items-center gap-[30px]">
              <Image src={InfoPrimaryIcon} width={50} height={50} alt="success" />
              <WebHeading28.Bold className="text-darkestBlue">
                {displayStrings.faqsSidePanelErrorTitle}
              </WebHeading28.Bold>
              <WebBodyText18.Regular className="text-center text-grey72">
                {displayStrings.faqsSidePanelErrorTxt}
                <span>
                  <a className="underline" href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </a>
                </span>
              </WebBodyText18.Regular>
            </div>
            <Button className="w-full" onClick={() => goTo(2)}>
              {displayStrings.faqsSidePanelBack}
            </Button>
          </div>
        )}
      </div>
    </SheetContent>
  );
};

export default FAQsSidePanel;
