import type { BeneficiaryType } from '@/components/common/SidePanels/AddBeneficiarySidePanel/AddBeneficiarySidePanel';
import {
  BeneficiaryBeneficiaryRelationType,
  GetAllUserAccountsStatusFragmentFragment,
} from '@fintronners/react-api/src';
import { UserKycEnum } from '@fintronners/react-experience/src/Helpers/GraphQLMapUtilsTS';

export type Beneficiary =
  | NonNullable<GetAllUserAccountsStatusFragmentFragment['beneficiariesPrimary']>['beneficiaries']
  | NonNullable<
      GetAllUserAccountsStatusFragmentFragment['beneficiariesContingent']
    >['beneficiaries'];

export const beneficiariesDataAdapter = (data: Beneficiary, type: BeneficiaryType) => {
  if (!data) return [];

  return data.map((d) => {
    return {
      beneficiaryType: type,
      dateOfBirth: d.dateOfBirth,
      firstName: d.firstName,
      middleName: d.middleName,
      lastName: d.lastName,
      phoneNumber: d.phoneNumber,
      email: d.email ?? '',
      streetAddress: d.streetAddress ?? '',
      additionalStreetAddress: d.additionalStreetAddress ?? '',
      city: d.city,
      state: d.state,
      zipCode: d.zipCode,
      country: d.country,
      ssn: d.ssn?.replaceAll('-', '') ?? '',
      percentage: d.percentage ?? 0,
      relationship: d.relationship,
      isPending: d.beneficiarySet.isPending,
    };
  });
};

export const beneficiaryRelationshipOptions = Object.values(BeneficiaryBeneficiaryRelationType).map(
  (value) => ({
    value,
    label: UserKycEnum[value],
  }),
);
