import React, { MouseEventHandler, ReactNode, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { StyleSheet } from 'react-native';

// Trabian
import AccountsMenu from '@/components/common/Layout/AccountsMenu';
import AccountsMenuItem from '@/components/common/Layout/AccountsMenuItem';
import SettingsMenu from '@/components/common/Layout/SettingsMenu';
import { JwtIdToken } from '~middleware';
import { colors } from '~utils/theme';
import { PATHS } from '~utils/constants';
import { useConfigStore } from '@/stores/config-store';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';
import cn from '~utils/tailwind';

// FinTron
import { BodyText40, Heading60 } from '@fintronners/react-ui/src/GlobalStyling';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useAllAccountsData from '@fintronners/react-widgets/src/hooks/market/useAllAccountsData';

// FinTron SVGs
import { IconSettingsSvg, LogOutSvg } from '@fintronners/react-ui/src/Images';
import SvgHome from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/Home';
import SvgHamburgerMenu from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/HamburgerMenu';
import ReturnIcon from '@/assets/icons/return.svg';
import { useAccountValues } from '@/hooks/api/useAccountValues';
import { UserAccountAccountType } from '@fintronners/react-api/src';
import { getBackHomeUrl } from '@/utils/RuntimeEnvGRPCAPI';

const MyAuxLink: React.FC<{
  title: string;
  icon: ReactNode;
  href?: string;
  onClick?: MouseEventHandler;
  isSelected?: boolean;
}> = (props) => {
  return (
    <Link
      className="flex flex-row flex-nowrap items-center"
      href={props.href ? props.href : ''}
      onClick={props.onClick}
    >
      <div className="mr-2">{props.icon}</div>
      <WebBodyText14.Medium>
        <span className={cn('text-grey72', { 'text-primary': props.isSelected })}>
          {props.title}
        </span>
      </WebBodyText14.Medium>
    </Link>
  );
};

const Menu: React.FC<{
  idToken: JwtIdToken | null;
  closeHandler: () => void;
  isMobileBreakpoint: boolean;
  isMobileMenuOpen: boolean;
}> = (props) => {
  const router = useRouter();
  const { theme, config } = useConfigStore();
  const { featureFlags: ff } = useFeatureFlags();

  const { selfDirected, ira, rothIra } = useAllAccountsData();
  const { gainsLosses: gainsLossesBrokerage } = useAccountValues(
    UserAccountAccountType.AccountTypeBrokerage,
  );
  const { gainsLosses: gainsLossesIra } = useAccountValues(UserAccountAccountType.AccountTypeIra);
  const { gainsLosses: gainsLossesRothIra } = useAccountValues(
    UserAccountAccountType.AccountTypeRothIra,
  );

  const totalAccountValue = useMemo(() => {
    const selfDirectedTotal = selfDirected?.totalValue || 0;
    const rothIraTotal = rothIra?.totalValue || 0;
    const iraTotal = ira?.totalValue || 0;

    const total = parseFloat(selfDirectedTotal) + parseFloat(rothIraTotal) + parseFloat(iraTotal);

    return total;
  }, [selfDirected, rothIra, ira]);

  const totalAccountGainLoss = useMemo(() => {
    if (totalAccountValue === 0 || !totalAccountValue) return 0;

    const total = (gainsLossesBrokerage + gainsLossesIra + gainsLossesRothIra) / totalAccountValue;

    return total;
  }, [gainsLossesBrokerage, gainsLossesIra, gainsLossesRothIra, totalAccountValue]);

  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [returnHomeLink, setReturnHomeLink] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const RETURN_TO_HOME_LINK: string = getBackHomeUrl(window.location.host);
      setReturnHomeLink(RETURN_TO_HOME_LINK);
    }
  }, []);

  /**
   * @todo Using <Link> will close the mobile menu (good!) but will result in a
   * full page refresh. Is this undesireable? Should this be using router.push()
   * instead and then we manually close the mobile menu?
   * @returns
   */

  const styles = StyleSheet.create({
    placeholderWidget: {
      width: '100%',
    },
    username: {
      color: colors.gray,
      fontSize: 14,
    },
    subHeading: {
      color: colors.gray,
    },
  });

  const baseClasses = [
    'bg-white',
    'flex',
    'flex-col',
    'flex-nowrap',
    'flex-grow-0',
    'flex-shrink-0',
    'p-6',
    'shadow-md',
  ];

  const mobileClasses = [
    'max-md:absolute',
    'max-md:top-0',
    'max-md:right-0',
    'max-md:bottom-0',
    'max-md:left-0',
    'max-md:z-[900]',
  ];

  if (props.isMobileBreakpoint && !props.isMobileMenuOpen) {
    // Hide the menu if we're at a mobile breakpoint and the menu isn't open.
    mobileClasses.push('max-lg:hidden');
  }

  const desktopClasses = ['md:w-[260px]', 'md:px-6', 'md:py-8'];

  const hasMultipleAccounts = useMemo(() => {
    const accounts = [selfDirected, ira, rothIra];
    const existingAccounts = accounts.filter((account) => !!account).length;

    return existingAccounts > 1;
  }, [selfDirected, ira, rothIra]);

  return (
    <div
      className={`${baseClasses.join(' ')} ${mobileClasses.join(' ')} ${desktopClasses.join(' ')}`}
    >
      <div className="mb-8 flex flex-shrink-0 flex-grow-0 flex-row flex-nowrap justify-end lg:hidden">
        <IconButton
          className="rounded-none bg-transparent p-0 hover:bg-transparent"
          onClick={() => {
            props.closeHandler();
          }}
        >
          <SvgHamburgerMenu width={20} height={20} color={theme.colors.primary} />
        </IconButton>
      </div>
      <div
        className={cn('flex flex-shrink-0 flex-grow-0 flex-row flex-wrap items-center pb-[30px]', {
          'mb-8 border-b border-gray-100': hasMultipleAccounts,
        })}
      >
        <div className="flex items-center justify-center gap-[15px]">
          <div className="flex-shrink-0 flex-grow-0">
            <Link
              href={!hasMultipleAccounts ? '/accounts/self-directed/portfolio' : '/accounts'}
              onClick={() => {
                setShowSettingsMenu(false);
              }}
            >
              {config.images.darkBrandLogoS.uri ? (
                <Image
                  src={config.images.darkBrandLogoS.uri}
                  alt="Logo"
                  width={40 * (config.images.darkBrandLogoS.logoRatio || 1)}
                  height={40}
                  className="inline"
                />
              ) : null}
            </Link>
          </div>
          <div className="w-full flex-shrink flex-grow">
            <div className="max-w-60 lg:max-w-40 -mt-1 overflow-hidden text-ellipsis">
              <Heading60.Bold numberOfLines={1} ellipsizeMode="tail">
                <span className="leading-none" title={props.idToken?.preferred_username}>
                  {props.idToken?.preferred_username}
                </span>
              </Heading60.Bold>
            </div>
            {!ff.shouldHideEmail && (
              <div className="max-w-60 lg:max-w-40 -mt-1 overflow-hidden text-ellipsis">
                <BodyText40.Regular style={styles.username} numberOfLines={1} ellipsizeMode="tail">
                  <span className="leading-none" title={props.idToken?.email}>
                    {props.idToken?.email}
                  </span>
                </BodyText40.Regular>
              </div>
            )}
          </div>
        </div>
        {hasMultipleAccounts && (
          <div className="mt-[30px] w-full">
            <AccountsMenuItem
              variant={router.asPath === '/accounts' ? 'selected' : 'primary'}
              SvgIcon={SvgHome}
              label="All accounts"
              value={totalAccountValue.toString()}
              percentageString={totalAccountGainLoss.toString()}
              onClick={() => {
                setShowSettingsMenu(false);

                if (props.isMobileBreakpoint) {
                  props.closeHandler();
                }

                router.push('/accounts');
              }}
            />
          </div>
        )}
      </div>
      <div className="flex-grow overflow-y-auto">
        {!showSettingsMenu && <AccountsMenu closeHandler={props.closeHandler} />}
        {showSettingsMenu && (
          <div className={cn(!hasMultipleAccounts && 'pt-6')}>
            <SettingsMenu closeHandler={props.closeHandler} />
          </div>
        )}
      </div>
      <div className="mt-8 border-t border-gray-100 pt-8">
        <ul>
          <li
            className={cn('hover:bg-gray-50border-2 rounded border-transparent px-3.5 py-1.5', {
              'bg-primary/10': showSettingsMenu,
            })}
          >
            <MyAuxLink
              title="Settings"
              icon={
                <span className={cn('text-gray-400', { 'text-primary': showSettingsMenu })}>
                  <IconSettingsSvg width={20} height={20} />
                </span>
              }
              onClick={(e) => {
                setShowSettingsMenu(!showSettingsMenu);
                e.preventDefault();
                return false;
              }}
              isSelected={showSettingsMenu}
            />
          </li>
          <li className="mt-4 rounded border-2 border-transparent px-3.5 py-1.5 hover:bg-gray-50">
            {!ff.shouldDisplayReturnHome ? (
              <MyAuxLink
                title="Log out"
                icon={
                  <span className="text-red-600">
                    <LogOutSvg width={20} height={20} />
                  </span>
                }
                href={PATHS.LOGOUT}
              />
            ) : (
              <MyAuxLink
                title="Return to Home"
                icon={
                  <span className="text-red-600">
                    <Image src={ReturnIcon} width={16} height={16} alt="" />
                  </span>
                }
                href={returnHomeLink}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
