import { useSidePanel } from '@/context/SidePanelContext';
import { RiskProfileSidePanelForm } from './RiskProfileSidePanelForm';
import { RiskProfileSidePanelSuccess } from './RiskProfileSidePanelSuccess';

export const RiskProfileSidePanel = () => {
  const { currentFlowStep } = useSidePanel();

  return (
    <>
      {currentFlowStep === 0 && <RiskProfileSidePanelForm />}
      {currentFlowStep === 1 && <RiskProfileSidePanelSuccess />}
    </>
  );
};
