/**
 * The maximum file size in bytes
 * 8MB
 */
export const MAX_FILE_SIZE = 1024 * 1024 * 8;

/**
 * Allowed MIME image file types
 * 8MB
 */
export const ACCEPTED_IMAGE_FILE_MIMES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/jpg',
];

/**
 * Convert a file to base64
 * @param file - The file to convert
 * @returns A promise that resolves with the base64 string
 */
export const convertFileToBase64 = (file: File): Promise<string> => {
  if (!file) {
    return Promise.reject('No file provided');
  }

  if (file.size > MAX_FILE_SIZE) {
    return Promise.reject('File size exceeds 10MB');
  }

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
