import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  sorry: 'Desculpe',
  oops: 'Ops!',
  existingUser:
    'Parece que você já tem algumas informações registradas. Por razões de segurança, uma pessoa pode ter apenas um nome de usuário.',
  existingAccount:
    'Parece que algumas de suas informações já estão registradas. Tente fazer login para continuar.',
  createUser: `Não foi possível criar seu perfil neste momento. Por favor, confirme se todas as informações foram inseridas corretamente. Se o problema persistir, entre em contato com o suporte em ${FinTronConfig.supportEmail}.`,
  createAccount: `Não foi possível criar sua conta neste momento. Por favor, confirme se todas as informações foram inseridas corretamente. Se o problema persistir, entre em contato com o suporte em ${FinTronConfig.supportEmail}.`,
  enrollPreferences:
    'Houve um erro ao salvar suas preferências de inscrição. Por favor, verifique suas configurações e tente novamente. Se o problema persistir, entre em contato com o suporte.',
  required: 'Campo obrigatório',
  minLength: 'No mínimo {{minLength}} caracteres',
  maxLength: 'No máximo {{maxLength}} caracteres',
  minAmount: 'No mínimo ${{minAmount}}',
  maxAmount: 'No máximo ${{maxAmount}}',
  minPercentAmount: 'A porcentagem deve ser no mínimo {{minAmount}}%',
  maxPercentAmount: 'A porcentagem deve ser menor que {{maxAmount}}%',
  invalidUsername: 'O nome de usuário não pode conter caracteres especiais',
  invalidSSN: 'O SSN deve ter exatamente 9 dígitos',
  duplicateSSN: 'O SSN já existe',
  duplicateEmail: 'O e-mail já está em uso',
  duplicatePhoneNumber: 'O número de celular já está em uso',
  passwordDoNotMatch: 'As senhas não correspondem',
  invalidPassword: 'A senha deve conter pelo menos 1 caractere especial (! @ # $…)',
  invalidPasswordUppercase: 'A senha deve conter pelo menos uma letra maiúscula',
  invalidPasswordLowercase: 'A senha deve conter pelo menos uma letra minúscula',
  invalidPasswordNumber: 'A senha deve conter pelo menos um número',
  invalidPasswordSpecialCharacter: 'A senha deve conter pelo menos um caractere especial',
  invalidPasswordLeadingTrailingSpace: 'A senha não pode ter espaços em branco no início ou no fim',
  invalidName: '{{field}} não pode conter caracteres especiais e espaços',
  invalidInputAscend: '{{field}} deve conter apenas letras, números e símbolos simples (, & - /)',
  invalidCode: 'O código deve ter exatamente 6 dígitos',
  invalidEmail: 'Formato de e-mail inválido',
  invalidPhoneNumber: 'Número de celular inválido',
  invalidPhoneNumberAdmin: 'O número de celular deve ter 10 dígitos e incluir +1',
  birthCountryError:
    'Enquanto estiver com visto, não pode definir o país de nascimento como Estados Unidos',
  citizenshipCountryError:
    'Enquanto estiver com visto, não pode definir o país de cidadania como Estados Unidos',
  invalidDate: 'Data inválida',
  duplicatedField: '{{type}} já está em uso. Por favor, utilize outro.',
  phoneNumberMismatch: 'O número de celular não corresponde ao país selecionado',
  phoneNumberFormatBR: 'O número de celular deve seguir o formato +55 (AA) 9NNNN-NNNN',
  areaCodeInvalid: 'O código de área não é válido para o país selecionado',
  phoneNumberFormatInvalid: 'Formato de número de celular inválido',
  phoneNumberFormatUS: 'O número de celular deve seguir o formato +1 (NNN) NNN-NNNN',
  imageFileRequired: 'Envie uma imagem. Apenas os formatos PDF, JPEG, JPG ou PNG são suportados',
  wrongCode: 'Código de segurança incorreto.',
  noAddressProvided: 'Por favor, forneça um endereço',
  networkErrors: {
    noConnection: 'Sem conexão com a internet',
    noConnectionBody:
      'Parece que você está tendo problemas com sua conexão com a internet! Verifique sua rede Wi-Fi ou serviço móvel e tente fazer login novamente.',
    error413: 'As imagens enviadas são muito grandes.',
    error415: 'As imagens enviadas têm um tipo de mídia não suportado.',
    errorTitleDefault: 'Aviso',
    errorBodyDefault:
      'Ocorreu um erro inesperado e não pudemos processar sua solicitação neste momento.',
  },
  genericErrorCodes: {
    CodeMismatchException: 'O código de verificação que você inseriu está incorreto.',
    LimitExceededException:
      'Você excedeu o número máximo de tentativas. Por favor, tente novamente mais tarde.',
    ExpiredCodeException: 'O código de verificação que você inseriu expirou.',
  },
  transferErrors: {
    TRANSFER_ERROR_CODE_INSUFFICIENT_FUNDS:
      'Fundos insuficientes para concluir a transferência. Por favor, certifique-se de ter saldo suficiente.',
    TRANSFER_ERROR_CODE_UNAPPROVED:
      'A transferência não foi aprovada. Entre em contato com o suporte para obter assistência.',
    TRANSFER_ERROR_CODE_EXCEEDS_DAILY_LIMIT:
      'O valor da transferência excede seu limite diário. Por favor, tente novamente amanhã.',
    TRANSFER_ERROR_CODE_INVALID_AMOUNT:
      'Valor de transferência inválido. Por favor, insira um valor válido e tente novamente.',
    TRANSFER_ERROR_CODE_INVALID_ORDER:
      'A ordem de transferência é inválida. Por favor, revise os detalhes da sua transferência e tente novamente.',
    TRANSFER_ERROR_CODE_OTHER:
      'Ocorreu um erro inesperado durante a transferência. Por favor, tente novamente mais tarde ou entre em contato com o suporte.',
    TRANSFER_ERROR_CODE_SERVER_ERROR:
      'Ocorreu um erro inesperado durante a transferência. Por favor, tente novamente mais tarde ou entre em contato com o suporte.',
    TRANSFER_ERROR_CODE_INVALID_ACCOUNT_STATUS:
      'A transferência falhou devido ao status da conta inválido. Entre em contato com o suporte ao cliente para obter assistência.',
    TRANSFER_ERROR_CODE_UNSUPPORTED: 'Ocorreu um erro inesperado durante a transferência.',
    TRANSFER_ERROR_CODE_VALIDATION_FAILED: 'Ocorreu um erro inesperado durante a transferência.',
  },
  wrongUsernameOrPassword: 'Nome de usuário ou senha incorretos. Tente novamente.',
};
