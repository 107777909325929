export type TenantFlags = ReturnType<typeof getTenants>;
export type Tenant = keyof TenantFlags extends `is${infer U}` ? U : never;

// NOTE: defenitely we should improve this config file and the way tenants are handled

export const getTenants = (newApiUrl: string | null) => ({
  isAlight: /alight/.test(newApiUrl ?? ''),
  isFintron: /ftron/.test(newApiUrl ?? ''),
  isRepublic: /republic/.test(newApiUrl ?? ''),
  isFranklin: /franklin/.test(newApiUrl ?? ''),
});

export const getLocalFeatureFlags = (tenants: TenantFlags) => {
  return {
    shouldHideEmail: tenants.isAlight,
    shouldDisplayReturnHome: tenants.isAlight,
    shouldHideTransferRecurrentDeposit: tenants.isAlight,
    shouldHideTradeRepeatInvestment: tenants.isAlight,
    shouldHideSettingsAdvisorAccount: tenants.isAlight,
    shouldHideSettingsSecurity: tenants.isAlight,
  };
};

export const supportEmailList: Record<Tenant, string> = {
  Fintron: 'support@fintroninvest.com',
  Alight: 'AlightFScustomerservice@alight.com',
  Franklin: 'MyFiHelp@franklintempleton.com',
  Republic: 'investors@republic.co',
} as const;

export const getTenantSupportEmail = (tenant: string): string => {
  return supportEmailList[tenant as keyof typeof supportEmailList] ?? supportEmailList.Fintron;
};

export const supportPageList: Record<Tenant, string> = {
  Fintron: 'https://www.fintroninvest.com/contact-us',
  Alight: 'https://www.alight.com/about/contact-us',
  Franklin: 'https://www.franklintempleton.com/about-us/contact-us',
  Republic: 'https://republic.com/contact',
} as const;

export const getTenantSupportPage = (tenant: string): string => {
  return supportPageList[tenant as keyof typeof supportPageList] ?? supportPageList.Fintron;
};

// TODO: we need to get the rest of the phone numbers
export const supportPhoneList: Record<Tenant, string> = {
  Fintron: '',
  Alight: '1-866-415-3383',
  Franklin: '',
  Republic: '',
} as const;

export const getTenantSupportPhone = (tenant: string): string => {
  return supportPhoneList[tenant as keyof typeof supportPhoneList] ?? supportPhoneList.Fintron;
};

export const getPrivacyPolicyLink = (tenant: string): string => {
  const privacyPolicies: Record<Tenant, string> = {
    Fintron: '',
    Alight:
      'https://www.alight.com/privacy?_ga=2.25691389.1328006058.1730230126-282020774.1711637053',
    Franklin: '',
    Republic: '',
  };

  return privacyPolicies[tenant as Tenant];
};

// TODO: we should move this to config in utils or something
export const getCurrentTenant = (newApiUrl: string | null) => {
  const tenants = getTenants(newApiUrl);
  const currentTenant = Object.entries(tenants)
    .find(([, v]) => v)?.[0]
    .replace('is', '');

  return currentTenant ?? null;
};
