import GraphQlClient from '@fintronners/react-api/src/GraphQlClient';
import { GET_USER_DETAILS_V2 } from '@fintronners/react-api/src/graphql/queries';
import { GetUserDetailsV2Query } from '@fintronners/react-api/src/graphql/types/graphql';

/**
 * @deprecated For migrating old screens to GraphQL. Use useQuery hook for new screens
 */
const GqlUser = () => {
  const me = async () => {
    await GraphQlClient.client.cache.reset();
    return GraphQlClient.client.query<GetUserDetailsV2Query>({
      query: GET_USER_DETAILS_V2,
      fetchPolicy: 'no-cache',
    });
  };

  return {
    me,
  };
};

export default GqlUser();
