import Image from 'next/image';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import { Radio } from '@/components/common/Radio/Radio';
import { MailIcon, PhoneIcon } from '@/assets/icons';

import { SendCodeFormData, SendCodeMethods, sendCodeSchema } from '../schema';
import { useAppStore } from '@/stores/app-store';
import { useSidePanel } from '@/context/SidePanelContext';
import useAuthService from '@/hooks/api/useAuthService';
import { showToast } from '@/components/common/Toaster/Toaster';

const displayStrings = {
  title: 'Two-factor authentication method',
  subtitle: 'How would you like to receive your code to activate two-fator authentication?',
  failedToSendCode: 'Failed to send code',
  cancel: 'Cancel',
  sendMethod: {
    [SendCodeMethods.SMS]: 'Send text message',
    [SendCodeMethods.EMAIL]: 'Send email',
  },
  send: 'Send',
};

const optionsIcon = {
  [SendCodeMethods.SMS]: PhoneIcon,
  [SendCodeMethods.EMAIL]: MailIcon,
};

const defaultValues = {
  method: SendCodeMethods.SMS,
};

export const MFASidePanelSendCodeOptions = () => {
  const { goTo, closeSidePanel } = useSidePanel();
  const userProfile = useAppStore((state) => state.userDetails?.profile);
  const authService = useAuthService();

  const { handleSubmit, control } = useForm<SendCodeFormData>({
    resolver: yupResolver(sendCodeSchema),
    defaultValues,
  });

  const handleSendCode = async (data: SendCodeFormData) => {
    try {
      if (data.method === SendCodeMethods.SMS) {
        await authService.authServiceSendVerifyPhone({
          phoneNumber: userProfile?.phoneNumber,
        });

        goTo(2, {
          method: data.method,
        });
        return;
      }

      await authService.authServiceSendVerifyEmail({
        email: userProfile?.email,
      });

      goTo(2, {
        method: data.method,
      });

      return;
    } catch (error) {
      showToast({
        variant: 'error',
        message: displayStrings.failedToSendCode,
        showCloseButton: true,
      });
    }
  };

  return (
    <SheetContent>
      <div>
        <SheetHeader className="mb-6">
          <SheetTitle>{displayStrings.title}</SheetTitle>
          <WebBodyText16.Regular className="text-grey72">
            {displayStrings.subtitle}
          </WebBodyText16.Regular>
        </SheetHeader>
        <ul className="list-none space-y-4">
          {Object.values(SendCodeMethods).map((method) => (
            <li key={method}>
              <Controller
                name="method"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Radio.Input
                      id={method}
                      value={method}
                      onChange={onChange}
                      checked={method === value}
                    />
                    <Radio.Label
                      htmlFor={method}
                      className="w-full peer-checked:border-2 border-2 border-transparent peer-checked:border-primary peer-checked:bg-primary/10 block p-4 rounded-lg"
                    >
                      <div className="flex items-center gap-4">
                        <Image
                          src={optionsIcon[method].src}
                          alt={`${method} icon`}
                          width={24}
                          height={24}
                        />
                        <WebBodyText16.Bold className="text-darkestBlue">
                          {displayStrings.sendMethod[method]}
                        </WebBodyText16.Bold>
                      </div>
                    </Radio.Label>
                  </>
                )}
              />
            </li>
          ))}
        </ul>
      </div>
      <SheetFooter>
        <div className="flex w-full flex-col gap-4">
          <Button
            variant="primary"
            className="w-full"
            type="submit"
            onClick={handleSubmit(handleSendCode)}
          >
            {displayStrings.send}
          </Button>
          <Button variant="secondary" className="w-full" onClick={closeSidePanel}>
            {displayStrings.cancel}
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
