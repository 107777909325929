import { useMemo } from 'react';
import { useGetRetirementInfo } from '@fintronners/react-api/src/hooks/useGetRetirementInfo';
import { useGetUserSettings } from '@fintronners/react-api/src/hooks/useGetUserSettings';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { StateWithholdingClass, WithholdingRateType } from '@fintronners/react-api/src';
import { getStateName } from '@/utils/strings';

export const useWithholdings = () => {
  const { userDetails, loading: isUserDetailsLoading } = useUserDetails();
  const { userSettings, userSettingsDataLoading } = useGetUserSettings();

  const userState = userDetails?.profile?.state || 'CA';
  const defaultWithholdingState = userSettings?.retirement?.defaultWithholding?.state;
  const userStateRate = userSettings?.retirement?.defaultWithholding?.stateWithholding?.percentage;

  const { retirementFundamentalInfo, userRetirementInfoLoading } = useGetRetirementInfo({
    state: userState || 'CA',
    skip: isUserDetailsLoading,
  });

  const stateName = useMemo(() => {
    const stateValue = userSettings?.retirement?.defaultWithholding.state || userState;
    return stateValue ? getStateName(stateValue) : null;
  }, [userSettings, retirementFundamentalInfo]);

  const state = useMemo(() => {
    const stateWithholdingInfo = retirementFundamentalInfo?.withholding?.stateWithholding?.at(0);

    return {
      class:
        stateWithholdingInfo?.class || StateWithholdingClass.StateWithholdingClassMayNotBeElected,
      rate: stateWithholdingInfo?.rate || '0',
      rateType: stateWithholdingInfo?.rateType || WithholdingRateType.WithholdingRateTypeFixed,
    };
  }, [retirementFundamentalInfo]);

  const defaultWithholding = userSettings?.retirement?.defaultWithholding || {
    federalWithholding: {
      waive: parseFloat(retirementFundamentalInfo?.withholding?.federalWithholding?.rate) === 0,
      percentage: retirementFundamentalInfo?.withholding?.federalWithholding?.rate || '0',
    },
    stateWithholding: {
      waive:
        parseFloat(retirementFundamentalInfo?.withholding?.stateWithholding?.at(0)?.rate) === 0 ||
        retirementFundamentalInfo?.withholding?.stateWithholding?.at(0)?.class ===
          StateWithholdingClass.StateWithholdingClassMayNotBeElected,
      percentage: retirementFundamentalInfo?.withholding?.stateWithholding?.at(0)?.rate || '0',
    },
  };

  const federalWithholdingRate = parseFloat(
    defaultWithholding?.federalWithholding?.percentage || 0,
  );

  const stateWithholdingRate =
    Number(defaultWithholding?.stateWithholding?.percentage) ||
    (state?.rate
      ? Number(parseFloat(defaultWithholding?.stateWithholding?.percentage).toFixed(2))
      : 0);

  const isFederalWaived = defaultWithholding?.federalWithholding?.waive;
  const isStateWaived = defaultWithholding?.stateWithholding?.waive;

  const loading = isUserDetailsLoading || userSettingsDataLoading || userRetirementInfoLoading;

  return {
    state: { ...state, name: stateName },
    userState,
    userStateRate,
    defaultWithholdingState,
    federalWithholdingRate,
    isFederalWaived,
    stateWithholdingRate,
    isStateWaived,
    loading,
  };
};

export type UseWithholdingsReturnType = ReturnType<typeof useWithholdings>;
