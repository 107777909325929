import fLogger from './fLogger';

export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

/**
 * @param days number of days that the funtion will return
 * @param skip number of days including weekends to skip before getting the options
 * @param startDate date that will be used as a start point to get the other dates. Defaults to the current date
 * @returns array of weekdays in a specific string format
 */

export const getNextNWeekdays = (days: number, skip: number, startDate?: string): Date[] => {
  const result = [];
  const currentDate = startDate ? new Date(startDate) : new Date();
  currentDate.setHours(0, 0, 0, 0);
  let skippedDates = 0;

  while (result.length < days) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      if (skippedDates >= skip) {
        result.push(new Date(currentDate.getTime()));
      }
    }
    skippedDates++;
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};

/**
 * @param dateString string that should be in a date format
 * @returns boolean based on the validity of the date string given
 */

export const isValidDateString = (dateString?: string) => {
  return !isNaN(Date.parse(dateString ?? ''));
};

/**
 * @param date optional string date
 * @returns the date passed as a Data object or the actual date
 */
export const getDateOrFallback = (date?: string) => {
  if (!date) return new Date();
  return new Date(date);
};

export const getEndOfToday = (plusDays: number = 0) => {
  const t = new Date();
  t.setDate(t.getDate() + plusDays);
  t.setSeconds(59);
  t.setMilliseconds(999);
  t.setMinutes(59);
  t.setHours(23);
  return t;
};

export const getBeginningOfToday = (plusDays: number = 0) => {
  const t = new Date();
  t.setDate(t.getDate() + plusDays);
  t.setSeconds(0);
  t.setMilliseconds(0);
  t.setMinutes(0);
  t.setHours(0);
  return t;
};

export const getBeginningOfTodayUtc = () => {
  const t = new Date();
  t.setUTCHours(0, 0, 0, 0);
  return t;
};

export const newDateWithDays = (date: Date | string, days: number = 0) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 * Helper method to check if the incoming string is a valid ISO date string
 * and only a date.
 */
export const isIsoDateOnlyDate = (isoDateString?: string) => {
  if (isoDateString?.length !== 10) {
    return false;
  }
  const date = new Date(isoDateString);
  return date.toISOString().endsWith('T00:00:00.000Z');
};

/**
 * Converts an ISO date string to a display string formatted as MM-DD-YYYY.
 * @param {string} isoDateString - The ISO date string to convert (e.g., "2023-07-28T12:34:56.789Z").
 * @param {string} separator - The separator symbol (e.g '-', '/')
 * @example
 * const isoDateString = '2023-07-28T12:34:56.789Z';
 * const displayString = convertISODateStringToObject(isoDateString);
 * // 07-28-2023
 * @returns {string} The display string formatted as MM-DD-YYYY.
 */
export const convertISODateStringToDisplayString = (
  isoDateString?: string | null,
  separator: string = '-',
): string => {
  if (!isoDateString) {
    return 'N/A';
  }

  const date = new Date(isoDateString);

  if (isNaN(date.getTime())) {
    fLogger.error('Failed to create a valid Date object from:', isoDateString);
    return '--/--/----';
  }

  return date
    .toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: isIsoDateOnlyDate(isoDateString) ? 'UTC' : undefined,
    })
    .replace(/\//g, separator);
};

/**
 * Converts an ISO date string to a display string formatted as YYYY-MM-DD.
 * @param {string} isoDateString - The ISO date string to convert (e.g., "2023-07-28T12:34:56.789Z").
 * @example
 * const isoDateString = '2023-07-28T12:34:56.789Z';
 * const displayString = convertISODateStringToObject(isoDateString);
 * // 2023-07-23
 * @returns {string} The display string formatted as YYYY-MM-DD.
 */
export const convertISODateStringToISO8601String = (
  isoDateString?: string,
): string | undefined | null => {
  if (!isoDateString) {
    return undefined;
  }

  const date = new Date(isoDateString);

  if (isNaN(date.getTime())) {
    fLogger.error('Failed to create a valid Date object from:', isoDateString);
    return null;
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

/**
 * Gets the current date plus daysLater
 * @param daysLater number of days to add to the current date
 */
const getDatePlusDays = (daysLater: number) => {
  const today = new Date();
  today.setDate(today.getDate() + daysLater);
  return today;
};

/**
 * Gets the difference in years and months between two dates
 * @param date1 first date
 * @param date2 second date
 * @returns an object with the difference in years and months
 */
const getDateDifference = (date1: Date, date2?: Date): { years: number; months: number } => {
  date2 = date2 ?? new Date();

  let years = date1.getFullYear() - date2.getFullYear();
  let months = date1.getMonth() - date2.getMonth();

  if (
    date1.getMonth() < date2.getMonth() ||
    (date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate())
  ) {
    years--;
    months = (12 + date1.getMonth() - date2.getMonth()) % 12;
  }

  if (date1.getDate() < date2.getDate()) {
    months--;
    if (months < 0) {
      months = 11;
      years--;
    }
  }

  return { years, months };
};

/**
 * Formats a date to 'Month - Year' with a custom separator
 * @param date the date to format
 * @param separator the custom separator between month and year
 * @returns formatted date string
 */
const formatDateToMonthYear = (date: Date, separator: string = ' - '): string => {
  const formattedDate = date.toLocaleDateString(undefined, { year: 'numeric', month: 'long' });

  const [month, year] = formattedDate.split(' ');
  return `${month}${separator}${year}`;
};

export default {
  getDatePlusDays,
  getDateDifference,
  formatDateToMonthYear,
};

/**
 * Check if the given string is a valid ISO date string.
 * @param dateString The string to check.
 * @returns True if the string is a valid ISO date string, false otherwise.
 */
export const isValidDateISO = (dateString?: string) => {
  if (!dateString) {
    return false;
  }

  const dateRegex = /^-?\d{4}-\d{2}-\d{2}$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const [year, month, day] = dateString.split('-').map(Number);

  if (month < 1 || month > 12) {
    return false;
  }

  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
