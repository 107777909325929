import * as React from 'react';
import * as Switch from '@radix-ui/react-switch';
import { clsx } from 'clsx';

interface SwitchRootProps extends React.ComponentPropsWithoutRef<typeof Switch.Root> {
  children: React.ReactNode;
  className?: string;
}

const SwitchRoot = React.forwardRef<HTMLButtonElement, SwitchRootProps>(
  ({ children, className, ...props }) => {
    return (
      <Switch.Root
        className={clsx(
          `relative w-[40px] h-[24px] bg-grey25 rounded-full transition-all data-[state=checked]:bg-primary`,
          className,
        )}
        {...props}
      >
        {children}
      </Switch.Root>
    );
  },
);

interface SwitchThumbProps extends React.ComponentPropsWithoutRef<typeof Switch.Thumb> {
  className?: string;
}

const SwitchThumb = React.forwardRef<HTMLButtonElement, SwitchThumbProps>(
  ({ className, ...props }, forwardedRef) => (
    <Switch.Thumb
      className={clsx(
        'block w-[18px] h-[18px] bg-white rounded-full shadow-[0_2px_2px_var(--black-a7)] transition-transform duration-100 transform translate-x-[3px] will-change-transform data-[state=checked]:translate-x-[19px]',
        className,
      )}
      {...props}
      ref={forwardedRef}
    />
  ),
);

export default Switch;
export { SwitchThumb, SwitchRoot };
