import { getHexAlpha } from '@fintronners/react-utils/src/utilFunctions';
import { HexColorCode } from '@fintronners/react-utils/types/colors';

type Colors = {
  [key: string]: HexColorCode;
};

export const colors = {
  linkColor: '#30bc96',
  activeTabColor: '#FFFFFF',
  inactiveTabColor: '#A9A9A9',
  disabledButtonColor: '#4FC3F7',
  backgroundColor: '#0c233f',
  pickerGray: '#4a4a4a',
  investBackgroundColor1: '#5664C3',
  investBackgroundColor2: '#00D3FF',
  bankingBackgroundColor: '#a5ddf7',
  boxBorder: '#DBDBDB',
  Cream: '#F9EBEA',
  elementBackground: '#FFFFFF',
  warning: '#F56100',
  labelColor: '#9E9E9E',
  textGray: '#9B9B9B',
  black: '#000000',
  red: '#ff2d55',
  blood: '#8a0303',
  white: '#ffffff',
  lightGreen: '#01b5ac',
  lawnGreen: '#7CFC00',
  midGreen: '#6BE83E',
  green: '#049F00',
  darkGreen: '#007618',
  transparent: '#00000000',
  darkGrayText: '#454b59',
  lightNotification: '#FFFF89',
  editBlueButton: '#2185d0',
  backgroundGray: '#BFBFBF',
  backButton: '#007AFF',
  deepSkyBlue: '#00a3d1',
  lightGray: '#f1f1f1',
  mediumGray: '#DBDBDB',
  logoColor: '#68C9FA',
  darkGray: '#A9A9A9',
  darkYellow: '#B28900',
  darkPink: '#9D82BA',
  darkGray2: '#555454',

  //new
  primary: '#FFFFFF',
  secondary: '#000000',
  borders: '#FAFAFA',
  background: '#F0F0F0',
  activeArea: '#FFFFFF',
  gradientEnd: '#F8F8F8',
  d: '#68C9FA',
  darkBlue: '#0B1B32',
  grayBorder: '#DCDCDC',
  darkGray1: '#666666',

  //v2
  primaryButton: '#26B1E6',
  secondaryButton: '#0e73ba',
  placeholderDark: '#000000',
  segmentColor: '#26B1E688',
  border: '#00000019',
  checkbox: '#0e73ba',
  chartFillColor: getHexAlpha('#FFFFFF', 80),
  tintLoading: '#8A9ABE',
  masterBudgetAmountColor: '#0098db',
  investTableListRowColor: getHexAlpha('#0C233F', 79),
  segmentBackgroundColor: '#00a7e3',
  newLinkColor: '#37DB9D',
  negativeColor: '#DB4848',
  negativeBackgroundColor: '#F7E8E5',
  chartTooltipColor: '#12233D',
  investDollarColor: '#015C2D',
  bestBidOfferColor1: getHexAlpha('#0A2C57', 90),
  bestBidOfferColor2: getHexAlpha('#4158D5', 90),
  imagePickerTextColor: '#4881d6',
  darkPurple: '#001A31',
  pieChartColor1: '#FBaed2',
  pieChartColor2: '#960080',
  pieChartColor3: '#3E4B9E',
  pieChartColor4: '#26A7DF',
  pieChartColor5: '#8766AC',
  pieChartColor6: '#fab962',
  pieChartColor7: '#f48688',
  pieChartColor8: '#3e4b9e',
  pieChartColor9: '#b3539f',
  pieChartColor10: '#e4f2ff',
  achRequirementColor: '#26A8DF',
  masterBudgetPieChartInvestColor: '#6ED6A1',
  masterBudgetBarChartCornerColor: '#a7b1ab',
  chartLineColor: '#21AAE1',
  radioColor: '#3e4b9e',
  // version 2.0
  brandBlack: '#0C243F',
  blue2: '#26A8DF',
  brandBlue30: getHexAlpha('#26A7DF', 70),
  brandGray: '#747474',
  darkBlue10: '#EAF1F8',
  gray: '#707070',
  lightBlue: '#AFE7F0',
  orange: '#FF9D0B',
  purple2: '#2B3197',
  mediumWhite: '#FFFFFFB5',
  grayText: '#808080',
  grayText40: getHexAlpha('#808080', 60),
  grayText55: '#737373',
  positive: '#009E00',
  positiveText: '#009E00',
  negativeText: '#B01700',
  keyboardWhite: '#F7F7F7',
  windowsBlue: '#327ABF',
  modalDark: '#0F0F0F',
  pieChartColorHighStart: '#3E4B9E',
  pieChartColorHighEnd: '#26A7DF',
  pieChartColorMidStart: '#3E4B9E',
  pieChartColorMidEnd: '#43B3E3',
  pieChartColorLowStart: '#3279BF',
  pieChartColorLowEnd: '#92D3EF',
  pieChartColor2High: '#FAB962',
  pieChartColor2Mid: '#FABF70',
  pieChartColor2Low: '#FCDCB0',
  barChart1High: '#3E4B9E',
  barChart1Low: '#3279BF',
  barChart1BackHigh: '#0B243F',
  barChart1BackLow: '#3E4B9E',
  barChart2High: '#3278BE',
  barChart2Low: '#26A7DF',
  barChart2BackHigh: '#3E4B9E',
  barChart2BackLow: '#3279BF',
  barChart3High: '#26A7DF',
  barChart3Low: '#92D3EF',
  barChart3BackHigh: '#3279BF',
  barChart3BackLow: '#26A7DF',
  barChart4High: '#AF1705',
  barChart4Low: '#FB3D08',
  barChart4BackHigh: '#7E1F04',
  barChart4BackLow: '#BC2E06',
  browserBackground: '#252525',
  drawerTop: '#FFFFFF',
  drawerFooter: '#F2F2F2',
  blue3: '#00B4EA',
  darkCyan: '#0097A7',
  purple: '#8766AC',
  borderGrey: '#BFC1C3',
  fieldErrorTextRed: '#D85C4A',
  fieldErrorTextGray: '#707070',
  helperTextGray: '#474747',
  fieldInputText: '#0B243F',
  fieldInputPinText: '#000000',
  fieldInputBackground: '#F0F0F0',
  fieldLabel: '#747474',
  inputTextLabel: '#474747',
  inputTextValue: '#0C243F',
  pinFieldErrorTextRed: '#B01700',
  pinFieldErrorBackgroundColor: getHexAlpha('#B01700', 90),
  brandCashGreen: '#009E00',
  brandCashGreen10: '#E5F9F0',
  brandDarkBlue: '#3278BE',
  brandPrimaryBlue: '#26B1E6',
  brandGreyText: '#747474',
  brandPrimaryBlue6030: getHexAlpha('#7DCAEC', 70),
  brandPrimaryBlue10: '#E9F6FC',
  brandLightGrey: '#F0F0F0',
  brandAccountTypeStocks: '#26A8DF',
  brandAccountTypeCyrpto: '#8766AC',
  brandAccountTypeGoalOne: '#6FA0D1',
  brandAccountTypeGoalTwo: '#7DCAEC',
  brandAccountTypeGeneralInvesting: '#B3539F',
  brandAccountTypeTraditionalIRA: '#0C243F',
  brandAccountTypeRothIRA: '#3E4B9E',
  brandStockSymbol: '#3E4B9E',
  brandGreyOne: '#F0F0F0',
  brandGreyTwo: '#BFC1C3',
  brandGreyThree: '#969696',
  brandGreyFour: '#747474',
  brandGreyFive: '#737373',
  brandDarkestGrey: '#808080',
  warningOrange: '#FF9D0B',
  warningOrange50: getHexAlpha('#FF9D0B', 95),
  warningOrange15: getHexAlpha('#FF9D0B', 85),
  modalOverlayColor: getHexAlpha('#000000', 70),
  pendingTextColor: '#52B9E5',
  pendingBackgroundColor: '#E5F5FB',
  textBodyDefault: '#474747',
  textHighContrast: '#0C243F',
  textBodyLowContrast: '#737373',
  textBodyLowerContrast: '#969696',
  textLearnMoreDefault: '#969696',
  iconBackgroundBase: '#2954F0',
  iconInfoBackground: '#969696',
  iconBackgroundGreyDark1: '#969696',
  dividerBorderDarkGrey: '#737373',
  dividerBorderLighterGrey: '#BFBFBF',
  boxBackgroundLight: '#F7F7F7',
  progressBarComplete: '#52B9E5',
  textHyperlink: '#737373',
  tagBackgroundLabelSolidGrey: '#969696',
  tagTextLabelSolid: '#FFFFFF',
  tagTextLabelLightGreen: '#009E00',
  tagBackgroundLabelLightGreen: '#E5F5E5',
  tagTextLabelLightOrange: '#FF9D0B',
  tagBackgroundLabelLightOrange: '#FFF0DA',
  tagTextLabelLightGrey: '#474747',
  tagBackgroundLabelLightGrey: '#F0F0F0',
  selectableTileTextTitle: '#0C243F',
  selectableTileBackgroundSelected: '#2954F0',
  selectableTileBorderSelected: '#2954F0',

  //theme colors. Get these from the app theme
  _primary: '#FF9D0B',
  _primaryDark: '#3E4B9E',
} as const satisfies Colors;

export const defaultETFHoldingsChartColors = [
  '#26A8DF',
  '#1B779F',
  '#A9E0F7',
  '#8E97CC',
  '#5C6FEB',
  '#3E4B9E',
  '#0D6C42',
  '#139F61',
  '#16B8AD',
  '#1CEC8F',
  '#ECD21C',
  '#FAB962',
  '#AC9914',
  '#F76704',
  '#C00213',
  '#C04F59',
  '#E4689C',
  '#D99BCC',
  '#B3539F',
  '#843600',
  '#624A7C',
  '#5A3AAB',
  '#8766AC',
  '#C393F9',
  '#E7D4FC',
];
