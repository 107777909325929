import { getSixDigitsCodeSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import * as yup from 'yup';

export enum SendCodeMethods {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export const sendCodeSchema = yup.object({
  method: yup
    .string()
    .oneOf(Object.values(SendCodeMethods))
    .required()
    .default(SendCodeMethods.SMS),
});

export const codeSchema = yup.object({
  code: getSixDigitsCodeSchema(),
});

export type SendCodeFormData = yup.InferType<typeof sendCodeSchema>;
export type CodeFormData = yup.InferType<typeof codeSchema>;
