import { yupResolver } from '@hookform/resolvers/yup';
import { Button as MuiButton } from '@mui/material';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';

// Trabian
import { Button } from '@/components/common/Button';
import TextField from '@/components/common/Forms/fields/TextField';
import BodyText from '~components/shims/BodyText';
import LoadingIcon from '@/assets/icons/loading.svg';
import { loginSchema } from '~pages/api/login';
import { getCookiesFromHeader } from '~utils/cookie';

// FinTron
import { getData } from './api/getToken';
import { getRegisterSSO } from './api/registerSSO';
import { getNewApiUrl } from '~utils/RuntimeEnvGRPCAPI';
import { useConfigStore } from '@/stores/config-store';
import { getCurrentTenant } from '@/config';
import { useTenantBasedStrings } from '@/hooks/config/useTenantBasedStrings';
import { useSidePanel } from '@/context/SidePanelContext';
import { getCognitoUrl } from '@/utils/cognito';

const styles = StyleSheet.create({
  formError: {
    color: '#f00',
    fontWeight: '700',
  },
});

export const getServerSideProps: GetServerSideProps = async ({ req, res, query }) => {
  const cookies = getCookiesFromHeader(req.headers.cookie);
  const { host } = req.headers;
  const newApiUrl = getNewApiUrl(host);
  const tenant = getCurrentTenant(newApiUrl);
  const { code } = query;

  if (code) {
    const authAndRegister = async (cognitoCode: string) => {
      try {
        const tokenResponse = await getData(host, cognitoCode);

        if (tokenResponse) {
          const { accessToken, refreshToken, idToken } = tokenResponse;

          const ssoRegisterResponse = await getRegisterSSO(
            host,
            JSON.stringify({ accessToken, refreshToken, idToken }),
            res,
            cookies,
          );

          if (ssoRegisterResponse) {
            return true;
          }
        }
      } catch (e) {
        console.error('couldnt get token or register sso');
        console.error(e);
      }

      return false;
    };
    const authResult = await authAndRegister(code as string);
    if (authResult) {
      return {
        redirect: {
          permanent: false,
          // NOTE: only Alight have SSO
          destination: '/accounts/self-directed/portfolio',
        },
      };
    }
  }

  return { props: { newApiUrl, tenant } };
};

const REDIRECT_PORTFOLIO = 'redirectTo=%2Faccounts%2Fself-directed%2Fportfolio';
const REDIRECT_ACCOUNTS = 'redirectTo=%2Faccounts';

export default function Login() {
  const router = useRouter();
  const { theme, config } = useConfigStore();
  const tenantBasedStrings = useTenantBasedStrings();
  const isRedirect =
    router.asPath.indexOf(REDIRECT_PORTFOLIO) > -1 || router.asPath.indexOf(REDIRECT_ACCOUNTS) > -1;

  const [formError, setFormError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { openSidePanel } = useSidePanel();

  const myStyles = StyleSheet.create({
    container: {
      borderTopColor: theme.colors.primary,
    },
    forgotPasswordLink: {
      color: theme.colors.primary,
    },
  });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const [ssoCognitoUrl, setSsoCognitoUrl] = useState('');

  useEffect(() => {
    const cognitoUrl = getCognitoUrl();
    setSsoCognitoUrl(cognitoUrl);

    if (router.asPath.indexOf(REDIRECT_ACCOUNTS) > -1) {
      const urlPath = `/login?${REDIRECT_PORTFOLIO}`;
      const params = new URLSearchParams(urlPath.split('?')[1]);
      const redirectTo = params.get('redirectTo') ?? '/accounts';
      router.push(redirectTo);
    }
  }, []);

  const onSubmit = async (data: { username: string; password: string }) => {
    setIsLoading(true);
    setFormError(null);

    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to submit the data. Please try again.');
      }

      const responseData = await response.json();

      if (responseData.redirect) {
        router.push('/accounts');
      } else {
        throw new Error(responseData.message);
      }
    } catch (error: any) {
      setFormError(error?.message || error || 'An unknown error occurred');
      setIsLoading(false);

      console.error('Error during login:', error);
    }
  };

  return (
    <React.Fragment>
      <Head>
        <title>{tenantBasedStrings.title}Web | Login</title>
      </Head>
      {isRedirect && (
        <div className="flex w-full justify-center items-center h-full">
          <Image src={LoadingIcon} className="h-10 w-10 animate-spin mr-2" alt="loading" />
        </div>
      )}
      {!isRedirect && (
        <div className="flex h-full w-full flex-col flex-nowrap items-center justify-center bg-gray-100 p-8">
          <div
            className="w-[560px] max-w-full rounded-md border-t-8 bg-white px-10 pb-12 pt-10 shadow-md"
            style={myStyles.container}
          >
            <div className="mb-12 text-center">
              {config.images.darkBrandLogoL.uri ? (
                <Image
                  src={config.images.darkBrandLogoL.uri}
                  alt="Logo"
                  width={150 * (config.images.darkBrandLogoL.logoRatio || 1)}
                  height={150}
                  className="inline"
                  priority
                />
              ) : null}
            </div>
            <div className="mx-auto w-[420px] max-w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                {formError && (
                  <div style={styles.formError}>
                    <div
                      className="bg-red-200 text-red-800 mb-4 flex items-center rounded-lg p-4 text-sm"
                      role="alert"
                    >
                      <svg
                        className="me-3 inline h-4 w-4 flex-shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>{formError}</div>
                    </div>
                  </div>
                )}
                <div className="mb-6">
                  <Controller
                    control={control}
                    disabled={isLoading}
                    name="username"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        autoComplete="username"
                        fieldError={fieldState.error}
                        label="Username"
                        placeholder="Enter username"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="mb-6">
                  <Controller
                    control={control}
                    disabled={isLoading}
                    name="password"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        autoComplete="current-password"
                        fieldError={fieldState.error}
                        label="Password"
                        placeholder="Enter password"
                        type="password"
                      />
                    )}
                  />
                </div>
                <div className="nowrap flex flex-col items-center">
                  <Button className="mb-6 w-[420px] max-w-full" disabled={isLoading} type="submit">
                    {isLoading ? 'Loading...' : 'Log In'}
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-[420px] max-w-full"
                    onClick={() => {
                      // Redirect to the welcome screen.
                      router.push('/');
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div className="mt-6 text-center">
                  <BodyText>
                    <Link
                      href="#"
                      className="underline"
                      style={myStyles.forgotPasswordLink}
                      onClick={(e) => {
                        e.preventDefault();
                        // eslint-disable-next-line no-alert
                        alert('onClick');
                        return false;
                      }}
                    >
                      Forgot username or password?
                    </Link>
                  </BodyText>
                </div>
                <div className="nowrap mt-6 flex flex-col items-center">
                  <Button
                    className="w-[420px] max-w-full"
                    onClick={() => {
                      // Redirect to the welcome screen.
                      router.push(ssoCognitoUrl);
                    }}
                  >
                    SSO Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-8 text-center">
            <MuiButton
              variant="text"
              className="bg-transparent p-0 capitalize text-gray-500 hover:bg-transparent hover:text-gray-700"
              onClick={() => openSidePanel('ThemeCustomizer')}
            >
              Customize Theme
            </MuiButton>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
