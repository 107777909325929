type CognitoInfo = {
  url: string;
  clientId: string;
  domain: string;
};

const alightProdCognitoInfo: CognitoInfo = {
  url: 'https://prod-alight.auth.us-east-2.amazoncognito.com',
  clientId: '438ljr1m986pttbi73eejf9jh5',
  domain: 'alight.fintron.co',
};

const alightUatCognitoInfo: CognitoInfo = {
  url: 'https://uat-alight.auth.us-east-2.amazoncognito.com',
  clientId: '45mrmjfno331f3puni8uajpo10',
  domain: '',
};

export const getCognitoUrl = () => {
  const encodedRedirectUri =
    window.location.origin.indexOf('localhost') > -1
      ? encodeURIComponent('http://localhost:3000/login')
      : encodeURIComponent(`${window.location.origin}/login`);

  switch (window.location.hostname) {
    case alightProdCognitoInfo.domain:
      return `${alightProdCognitoInfo.url}/oauth2/authorize?client_id=${alightProdCognitoInfo.clientId}&response_type=code&scope=email+openid&redirect_uri=${encodedRedirectUri}`;
    default:
      return `${alightUatCognitoInfo.url}/oauth2/authorize?client_id=${alightUatCognitoInfo.clientId}&response_type=code&scope=email+openid&redirect_uri=${encodedRedirectUri}`;
  }
};
