import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetRetirementInfoQuery } from '@fintronners/react-api/src';
import { GET_RETIREMENT_INFO } from '@fintronners/react-api/src/graphql/queries/retirement';

type useGetRetirementInfoProps = {
  state: string;
} & QueryHookOptions;

export const useGetRetirementInfo = ({ state, ...options }: useGetRetirementInfoProps) => {
  const { data: userRetirementInfo, loading: userRetirementInfoLoading } =
    useQuery<GetRetirementInfoQuery>(GET_RETIREMENT_INFO, {
      skip: !state || options.skip,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: {
        state,
      },
      ...options,
    });

  return {
    ...userRetirementInfo,
    userRetirementInfoLoading,
  };
};
