import { AddACHAccountSidePanel } from '@/components/common/SidePanels/AddACHAccountSidePanel';
import { RemoveACHAccountSidePanel } from '@/components/common/SidePanels/RemoveACHAccountSidePanel/RemoveACHAccountSidePanel';
import { registerSidePanel } from '@/context/SidePanelContext';
import { EditProfileInfoSidePanel } from '../EditProfileInfoSidePanel';
import UpdatePasswordSidePanel from '../UpdatePasswordSidePanel/UpdatePasswordSidePanel';
import FAQsSidePanel from '../FAQsSidePanel/FAQsSidePanel';
import { RetirementIndexSidePanel } from '../RetirementIndexSidePanel';
import { SidePanelTest } from '../SidePanelTest';
import { TradeSidePanel } from '../TradeSidePanel';
import { TransferSidePanel } from '../TransferSidePanel/TransferSidePanel';
import { RiskProfileSidePanel } from '../RiskProfileSidePanel/RiskProfileSidePanel';
import { InvestorProfileSidePanel } from '../InvestorProfileSidePanel/InvestorProfileSidePanel';
import ThemeCustomizerSidePanel from '../ThemeCustomizerSidePanel/ThemeCustomizerSidePanel';
import SupplementalQuestionsSidePanel from '../SupplementalQuestionsSidePanel/SupplementalQuestionsSidePanel';
import AddBeneficiarySidePanel from '../AddBeneficiarySidePanel/AddBeneficiarySidePanel';
import FPSLOptInSidePanel from '../FPSLOptInSidePanel/FPSLOptInSidePanel';
import { WithholdingElectionsSidePanel } from '../WithholdingElections/WithholdingElections';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { MFASidePanel } from '../MFASidePanel/MFASidePanel';

// When creating a new SidePanel you'll need to add the type here and register it below
export type SidePanelName =
  | 'AddACHAccount'
  | 'AddBeneficiary'
  | 'EditProfileInfo'
  | 'FAQsSidePanel'
  | 'FPSLOptIn'
  | 'InvestorProfile'
  | 'MFA'
  | 'RemoveACHAccount'
  | 'RetirementIndex'
  | 'RiskProfileSidePanel'
  | 'SidePanelTest'
  | 'ThemeCustomizer'
  | 'SupplementalQuestions'
  | 'Trade'
  | 'Transfer'
  | 'UpdatePassword'
  | 'WithholdingElections'
  | 'ErrorSidePanel';

registerSidePanel('AddACHAccount', AddACHAccountSidePanel);
registerSidePanel('AddBeneficiary', AddBeneficiarySidePanel);
registerSidePanel('RemoveACHAccount', RemoveACHAccountSidePanel);
registerSidePanel('EditProfileInfo', EditProfileInfoSidePanel);
registerSidePanel('FAQsSidePanel', FAQsSidePanel);
registerSidePanel('FPSLOptIn', FPSLOptInSidePanel);
registerSidePanel('InvestorProfile', InvestorProfileSidePanel);
registerSidePanel('MFA', MFASidePanel);
registerSidePanel('RetirementIndex', RetirementIndexSidePanel);
registerSidePanel('RiskProfileSidePanel', RiskProfileSidePanel);
registerSidePanel('SidePanelTest', SidePanelTest);
registerSidePanel('ThemeCustomizer', ThemeCustomizerSidePanel);
registerSidePanel('Transfer', TransferSidePanel);
registerSidePanel('Trade', TradeSidePanel);
registerSidePanel('UpdatePassword', UpdatePasswordSidePanel);
registerSidePanel('SupplementalQuestions', SupplementalQuestionsSidePanel);
registerSidePanel('WithholdingElections', WithholdingElectionsSidePanel);
registerSidePanel('ErrorSidePanel', ErrorSidePanel);
