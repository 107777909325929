import { getBackHomeUrl } from '@/utils/RuntimeEnvGRPCAPI';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function useInactivityTimer() {
  const timeUntilInactivityAlert = 1000 * 60 * 5; // 5 min
  const timeUntilRedirection = 1000 * 60 * 0.5; // 30 seconds
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [inactiveRedirectUrl, setInactiveRedirectUrl] = useState<string>('');
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const RETURN_TO_HOME_LINK: string = getBackHomeUrl(window.location.host);
      setInactiveRedirectUrl(RETURN_TO_HOME_LINK);
    }
  }, []);

  useEffect(() => {
    let inactivityTimer: number;
    let modalTimer: number;

    const resetTimer = () => {
      setIsInactive(false);
      setIsModalVisible(false);
      clearTimeout(inactivityTimer);
      clearTimeout(modalTimer);
      inactivityTimer = window.setTimeout(() => {
        setIsInactive(true);
        setIsModalVisible(true);
        modalTimer = window.setTimeout(() => {
          router.push(inactiveRedirectUrl);
        }, timeUntilRedirection);
      }, timeUntilInactivityAlert);
    };

    const activityEvents: string[] = ['mousemove', 'keydown', 'scroll', 'pointerdown'];
    activityEvents.forEach((event) => window.addEventListener(event, resetTimer));
    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      clearTimeout(modalTimer);
      activityEvents.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [inactiveRedirectUrl, router]);

  return { isInactive, isModalVisible, resetTimer: () => setIsInactive(false) };
}
