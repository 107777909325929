import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useRouter } from 'next/router';

// Trabian
import AccountsMenuItem from '@/components/common/Layout/AccountsMenuItem';
import BodyText from '@/components/shims/BodyText';
import Submenu from '@/components/common/Layout/Submenu';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useSubmenu, { TSubmenuEntry } from '@/hooks/utils/useSubmenu';
import useAccountPriceData from '@fintronners/react-widgets/src/hooks/useAccountPriceData';
import useAllAccountsData from '@fintronners/react-widgets/src/hooks/market/useAllAccountsData';
import { useTenantBasedStrings } from '@/hooks/config/useTenantBasedStrings';

// FinTron SVGs
import { ChevronLeftSvg } from '@fintronners/react-ui/src/Images';

// SVGs
import SvgStocks from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/Stocks';
import SvgTraditional from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconTraditional';
import SvgRoth from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconRoth';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';

const getSubmenuEntries = (accountType?: string, tab?: string): Array<TSubmenuEntry[]> => [
  // Self-Directed
  [
    {
      title: 'Portfolio',
      url: `/accounts/${accountType}/portfolio`,
      children: [
        {
          title: 'Account value',
          url:
            tab && tab === 'portfolio'
              ? '#account-value'
              : `/accounts/${accountType}/portfolio#account-value`,
          children: [],
        },
        {
          title: 'Watchlist',
          url:
            tab && tab === 'portfolio'
              ? '#watchlist'
              : `/accounts/${accountType}/portfolio#watchlist`,
          children: [],
        },
        //TODO: Waiting for the repeat investment panels
        // {
        //   title: 'Repeat investment',
        //   url: '#repeat-investment',
        //   children: [],
        // },
      ],
    },
    {
      title: 'Search',
      url: `/accounts/${accountType}/search`,
      children: [],
    },
    {
      title: 'Activity history',
      url: `/accounts/${accountType}/activity-history`,
      children: [],
    },
    {
      title: 'Documents',
      url: `/accounts/${accountType}/documents`,
      children: [],
    },
  ],
  // Traditional IRA
  [
    {
      title: 'Other',
      url: '/accounts',
      children: [],
    },
  ],
];

const MyBackButton: React.FC<{ onClick: MouseEventHandler }> = (props) => {
  return (
    <Button
      className="absolute left-5 top-7 rounded-none bg-transparent p-0 hover:bg-transparent"
      onClick={props.onClick}
    >
      <div className="mr-2">
        <span className="text-gray-400">
          <ChevronLeftSvg width={12} height={12} />
        </span>
      </div>
      <BodyText className="text-base capitalize">Back</BodyText>
    </Button>
  );
};

type TAccountsMenuProps = {
  closeHandler: () => void;
};

const AccountsMenu: React.FC<TAccountsMenuProps> = (props) => {
  const router = useRouter();
  const { selfDirected, ira, rothIra } = useAllAccountsData();

  const tenantBasedString = useTenantBasedStrings();
  const { tenants } = useFeatureFlags();

  const getAccountType = useCallback(() => {
    if (!router.query?.type && tenants.isAlight) {
      return 'trad-ira';
    }

    if (!router.query?.type && !tenants.isAlight) {
      return 'self-directed';
    }

    return router.query?.type as string;
  }, [tenants.isAlight, router.query]);

  const { isBreakpoint } = useBreakpoint();

  const { entriesArray, setEntriesArray, selectedPathIndices, setSelectedPathIndices, goBack } =
    useSubmenu();

  const [selectedAccountIndex, setSelectedAccountIndex] = useState(-1);

  const submenuEntries = getSubmenuEntries(getAccountType(), router.query?.type as string);

  useEffect(() => {
    setEntriesArray(submenuEntries);
  }, [router.query?.type]);

  useEffect(() => {
    if (isBreakpoint('xs') || isBreakpoint('sm')) {
      setSelectedAccountIndex(0);
    }
  }, [isBreakpoint]);

  const handleMenuItemClick = (path: string) => {
    router.push(path);

    if (isBreakpoint('xs') || isBreakpoint('sm')) {
      setSelectedPathIndices([]);
    }
  };

  const { priceData: selfDirectedPriceData } = useAccountPriceData(selfDirected?.id);
  const { priceData: rothIraPriceData } = useAccountPriceData(rothIra?.id);
  const { priceData: iraPriceData } = useAccountPriceData(ira?.id);

  return (
    <div className="grid gap-4">
      <div>
        <div className="flex flex-col gap-4">
          {selfDirected && (
            <AccountsMenuItem
              variant={router.asPath.includes('/accounts/self-directed') ? 'selected' : 'default'}
              SvgIcon={SvgStocks}
              label={tenantBasedString.selfDirected}
              value={selfDirectedPriceData?.price}
              percentageString={selfDirectedPriceData?.gainLossPercent.toString()}
              onClick={() => handleMenuItemClick(submenuEntries[0][0].url)}
            />
          )}
          {/* We're not working on goals at the moment */}
          {/* {goals &&
            goals.map((goal, index) => (
              <AccountsMenuItem
                key={index}
                SvgIcon={SvgGoal}
                label="Goal"
                value={goal?.accountPortfolio.totalValue}
                dayGainLoss={{
                  totalDayGainLossValue: 0,
                  currencyString: '',
                  percentageString: '0',
                }}
              />
            ))} */}
          {(rothIra || ira) && (
            <div className="flex flex-col gap-4">
              {ira && (
                <AccountsMenuItem
                  variant={router.asPath.includes('/accounts/trad-ira') ? 'selected' : 'default'}
                  SvgIcon={SvgTraditional}
                  label={tenantBasedString.accounts['trad-ira']}
                  value={iraPriceData?.price}
                  percentageString={iraPriceData?.gainLossPercent.toString()}
                  onClick={() => handleMenuItemClick('/accounts/trad-ira/portfolio')}
                />
              )}
              {rothIra && (
                <AccountsMenuItem
                  variant={router.asPath.includes('/accounts/roth-ira') ? 'selected' : 'default'}
                  SvgIcon={SvgRoth}
                  label={tenantBasedString.accounts['roth-ira']}
                  value={rothIraPriceData?.price}
                  percentageString={rothIraPriceData?.gainLossPercent.toString()}
                  onClick={() => handleMenuItemClick('/accounts/roth-ira/portfolio')}
                />
              )}
            </div>
          )}
        </div>
        {selectedAccountIndex === 0 && (
          <div className="mt-4 md:hidden">
            <Submenu
              closeHandler={props.closeHandler}
              entriesArray={entriesArray}
              topLevelIndex={0}
              pathIndices={selectedPathIndices}
              setPathIndices={setSelectedPathIndices}
              backButton={<MyBackButton onClick={goBack} />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountsMenu;
