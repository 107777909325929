import { WebBodyText14, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import ProgressBar from '@/components/common/ProgressBar/ProgressBar';
import { Skeleton } from '../../Skeleton';

type ContributionWidgetProps = {
  limit: number;
  contributed: number;
  limitLeft: number;
  isLoading?: boolean;
};

const displayStrings = {
  remaining: 'Remaining:',
};

export const ContributionWidget = ({
  limit,
  limitLeft,
  contributed,
  isLoading,
}: ContributionWidgetProps) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        {isLoading ? (
          <>
            <Skeleton className="h-4 w-20 mb-2" />
            <Skeleton className="h-4 w-20 mb-2" />
          </>
        ) : (
          <>
            <WebBodyText14.Regular className="text-grey72">
              {thousandFormatNumbroCurrency(contributed)} / {thousandFormatNumbroCurrency(limit)}
            </WebBodyText14.Regular>
            <WebCaption12.Regular className="text-grey72">
              {displayStrings.remaining}{' '}
              {thousandFormatNumbroCurrency(Math.sign(limitLeft) === -1 ? 0 : limitLeft)}
            </WebCaption12.Regular>
          </>
        )}
      </div>

      {isLoading ? (
        <Skeleton className="h-2 w-full" />
      ) : (
        <ProgressBar total={limit} noBg>
          <ProgressBar.Segment value={contributed} />
          <ProgressBar.Segment fill />
        </ProgressBar>
      )}
    </div>
  );
};
