import {
  useGetUserRetirementContributionsQuery,
  UserAccountAccountType,
} from '@fintronners/react-api/src';

import useCurrentAccountType from '../utils/useCurrentAccountType';
import { useEffect } from 'react';

type UseContributionsProps = {
  taxYear?: number | string;
  skip?: boolean;
};

export const useContributions = ({ taxYear, skip = false }: UseContributionsProps) => {
  const accountType = useCurrentAccountType();
  const currentTaxYear = taxYear ?? new Date().getFullYear().toString();

  const {
    data: rawContributions,
    loading: isContributionsLoading,
    refetch,
  } = useGetUserRetirementContributionsQuery({ skip, variables: { year: currentTaxYear } });

  useEffect(() => {
    if (!skip) {
      refetch();
    }
  }, [taxYear]);

  const contributions = rawContributions?.userRetirementContributions?.find(
    (contribution) => contribution?.year === Number(currentTaxYear),
  );

  const limit =
    accountType === UserAccountAccountType.AccountTypeIra
      ? contributions?.limits.traditionalIRALimit
      : contributions?.limits.rothIRALimit;

  const rothIraContributions = {
    current: contributions?.totals.rothIRA.totalWithPending,
  };

  const traditionalIraContributions = {
    current: contributions?.totals.traditionalIRA.totalWithPending,
  };

  const contributed =
    accountType === UserAccountAccountType.AccountTypeIra
      ? traditionalIraContributions
      : rothIraContributions;

  const limitLeft =
    parseFloat(contributions?.limits.totalLimit) -
    parseFloat(contributions?.totals.combined.totalWithPending);

  return {
    ...rawContributions,
    isContributionsLoading,
    overall: {
      limit,
      contributions,
      rothIraContributions,
      traditionalIraContributions,
      contributed,
      limitLeft,
    },
    refetchContributions: refetch,
  };
};
