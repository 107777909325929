import Image from 'next/image';
import { InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import cn from '@/utils/tailwind';

interface InactivityDialogProps {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  confirmAction?: () => void;
}

const InactivityDialog = ({ open, setModalOpen, confirmAction }: InactivityDialogProps) => {
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>Inactivity Alert</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <div className={cn('whitespace-pre-line text-grey72', 'text-center')}>
          Are you still with us? Please click [Continue] in the next 30 seconds or we’ll navigate
          you back to the Alight Worklife Experience
        </div>

        <ResponsiveDialog.Footer>
          <div className="flex w-full flex-col space-y-5">
            <Button className="w-full" variant="primary" onClick={confirmAction}>
              Continue
            </Button>
          </div>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};

export default InactivityDialog;
