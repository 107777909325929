import React from 'react';
import {
  WebBodyText14,
  WebBodyText18,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { StateWithholdingClass } from '@fintronners/react-api/src';
import { formatPercent } from '@fintronners/react-utils/src/numberUtilsTSX';

import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { useWithholdings } from '@/hooks/api/useWithholdings';

import { Button } from '@/components/common/Button';

const displayStrings: Record<string, string> = {
  withholdingState: 'Withholding state:',
  federalWithholding: 'Federal withholding:',
  stateWithholding: 'State withholding:',
  title: 'Withholding elections',
  description: 'You may edit information related to your withholdings.',
  edit: 'Edit',
  cancel: 'Close',
};

interface InformationDialogProps {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  onEdit?: () => void;
}

const InformationDialog: React.FC<InformationDialogProps> = ({ open, setModalOpen, onEdit }) => {
  const { state, federalWithholdingRate, stateWithholdingRate, isFederalWaived, isStateWaived } =
    useWithholdings();

  const federalWithholdingRateFormatted = isFederalWaived
    ? 'Waived'
    : formatPercent(federalWithholdingRate / 100, {
        mantissa: 0,
      });

  const stateWithholdingRateFormatted = isStateWaived
    ? 'Waived'
    : (state.rateType as string) === 'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL'
      ? formatPercent(((stateWithholdingRate / 100) * federalWithholdingRate) / 100, {
          mantissa: 0,
        })
      : formatPercent(stateWithholdingRate / 100, { mantissa: 0 });

  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content className="flex flex-col items-center">
        <ResponsiveDialog.Header>
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {displayStrings.description}
        </WebBodyText18.Regular>

        <div className="bg-grey7 p-5 min-w-72 rounded-md">
          <div className="flex justify-between mb-5">
            <WebBodyText14.Regular>{displayStrings.withholdingState}</WebBodyText14.Regular>
            <WebBodyText14.Regular className="font-bold">{state.name}</WebBodyText14.Regular>
          </div>
          <div className="flex justify-between mb-5">
            <WebBodyText14.Regular>{displayStrings.federalWithholding}</WebBodyText14.Regular>
            <WebBodyText14.Regular className="font-bold">
              {federalWithholdingRateFormatted}
            </WebBodyText14.Regular>
          </div>
          {state.class !== StateWithholdingClass.StateWithholdingClassMayNotBeElected && (
            <div className="flex justify-between">
              <WebBodyText14.Regular>{displayStrings.stateWithholding}</WebBodyText14.Regular>
              <WebBodyText14.Regular className="font-bold">
                {stateWithholdingRateFormatted}
              </WebBodyText14.Regular>
            </div>
          )}
        </div>

        <ResponsiveDialog.Footer className="w-full">
          <ResponsiveDialog.Close asChild>
            <div className="flex w-full flex-col gap-5">
              <Button className="w-full" onClick={onEdit}>
                {displayStrings.edit}
              </Button>
              <Button className="w-full">{displayStrings.cancel}</Button>
            </div>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};

export default InformationDialog;
