import {
  getAscendStringRequiredSchema,
  getOneOfEnumSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import * as yup from 'yup';
import i18n from '@fintronners/react-language/src/Lang/lang';

export const PoliticallyExposedSchema = yup.object({
  isPoliticallyExposed: getOneOfEnumSchema(YES_OR_NO).required(),
  familyMembers: yup.array().when('isPoliticallyExposed', {
    is: YES_OR_NO.YES,
    then: (schema) =>
      schema.of(
        yup.object({
          value: getAscendStringRequiredSchema(
            i18n.t('onboardingIsPoliticallyExposed.familyMemberName'),
          ),
        }),
      ),
  }),
  organizationName: yup.string().when('isPoliticallyExposed', {
    is: YES_OR_NO.YES,
    then: () =>
      getAscendStringRequiredSchema(
        i18n.t('onboardingIsPoliticallyExposed.politicalOrganizationName'),
      ),
  }),
});

export type PoliticallyExposedFormData = yup.InferType<typeof PoliticallyExposedSchema>;
