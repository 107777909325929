import React, { useState, useRef, useLayoutEffect } from 'react';
import { useRouter } from 'next/router';
import Menu from '@/components/common/Layout/Menu';
import MobileMenu from '@/components/common/Layout/MobileMenu';
import Loading from '@/components/common/Loading/Loading';
import useBreakpointResizeMatcher, { Breakpoints } from 'hooks/utils/useBreakpointResizeMatcher';
import { useUserStore } from '@/stores/user-store';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import useLoadingBar from '@/hooks/utils/useLoadingBar';
import cn from '@/utils/tailwind';
import type { JwtIdToken } from '@/middleware';
import type { ConfigType, ThemeType } from '@/config/utils';

type LayoutProps = {
  idToken: JwtIdToken | null;
  children: React.ReactNode;
  theme: ThemeType;
  config: ConfigType;
  className?: string | undefined;
};

/* constants */
const PADDING_TOP = 'pt-4';
const ACCOUNTS_PAGE_ROUTE = '/accounts/[type]/[tab]';

const Layout: React.FC<LayoutProps> = ({ idToken, theme, config, className, children }) => {
  const setUserData = useUserStore((state) => state.setUserData);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const layoutRef = useRef<HTMLDivElement>(null);

  const loadingBar = useLoadingBar();
  const { pathname } = useRouter();

  useUserDetails('cache-and-network', (userData) => {
    const { firstName, lastName, phoneNumber, streetAddress, city, state, zipCode, email } =
      userData.users.edges?.at(0)?.node?.profile ?? {};

    setUserData({
      firstName,
      lastName,
      phoneNumber,
      streetAddress,
      city,
      state,
      zipCode,
      email,
    });
  });

  const isMediumOrSmaller = useBreakpointResizeMatcher(Breakpoints.MediumOrSmaller);

  /*
   * Force pt-0 in account pages if the device is not mobile.
   * This is needed in order to avoid gaps for the sticky secondary header navigation.
   */
  useBreakpointResizeMatcher(Breakpoints.Mobile, (isMobile) => {
    if (!layoutRef.current) return;
    const l = layoutRef.current;

    const isAccountsPage = pathname === ACCOUNTS_PAGE_ROUTE;

    if (!isAccountsPage) {
      if (!l.classList.contains(PADDING_TOP)) {
        l.classList.add(PADDING_TOP);
      }

      return;
    }

    const addClasses = isMobile ? PADDING_TOP : '!pt-0';
    const removeClasses = isMobile ? '!pt-0' : PADDING_TOP;

    l.classList.remove(removeClasses);
    l.classList.add(addClasses);
  });

  // Undo forcing pt-0 for non account pages on layout mount
  useLayoutEffect(() => {
    if (pathname === ACCOUNTS_PAGE_ROUTE) return;
    layoutRef.current?.classList.remove('!pt-0');
  }, [pathname]);

  return (
    <div
      className={cn('flex h-screen flex-col flex-nowrap overflow-hidden md:flex-row', className)}
    >
      {/* Loading bar */}
      <Loading isRouteChanging={loadingBar.isRouteChanging} key={loadingBar.loadingKey} />

      {/* Desktop Menu */}
      <Menu
        idToken={idToken}
        isMobileBreakpoint={isMediumOrSmaller}
        isMobileMenuOpen={isMobileMenuOpen}
        closeHandler={() => {
          setIsMobileMenuOpen(false);
        }}
      />

      <div className="stable-scrollbar-gutter w-full overflow-y-auto border-l border-gray-100">
        {/* Mobile Menu */}
        <MobileMenu
          config={config}
          theme={theme}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />

        {/* Page Content */}
        <div
          className={cn('px-4 pb-4 lg:px-6 lg:py-8', PADDING_TOP)}
          ref={layoutRef}
          data-page-layout
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
