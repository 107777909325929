import * as yup from 'yup';
import {
  getAscendStringRequiredSchema,
  getBooleanSchema,
  getEmailSchema,
  getNewPhoneNumberSchema,
  getStringRequiredSchema,
  getYesOrNoSchema,
  getOneOfEnumSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import i18n from '@fintronners/react-language/src/Lang/lang';
import { BeneficiaryBeneficiaryRelationType } from '@fintronners/react-api/src/graphql/types/graphql';
import { NonDiscretionaryAccountKycUserMaritalStatus } from '@fintronners/react-api/src';
import { NEW_REG_SSN } from '@fintronners/react-utils/src/regexUtils';
import { AvailableCountries } from '@fintronners/react-utils/src/countries';

export enum BeneficiariesFormKeys {
  HAS_BENEFICIARIES = 'hasBeneficiaries',
  PRIMARY = 'primary',
  CONTINGENT = 'contingent',
}

export const beneficiaryFormSchema = yup.object().shape({
  firstName: getAscendStringRequiredSchema(i18n.t('onboardingBasicInfo.firstName')),
  middleName: getAscendStringRequiredSchema(i18n.t('onboardingBasicInfo.middleName')).notRequired(),
  lastName: getAscendStringRequiredSchema(i18n.t('onboardingBasicInfo.lastName')),
  phoneNumber: getNewPhoneNumberSchema(AvailableCountries.US),
  dateOfBirth: getStringRequiredSchema(),
  email: getEmailSchema().test(
    'unique-email',
    'Email must be unique and cannot match your own email',
    function (value) {
      if (!value) return true;

      const { userEmail } = this.options.context?.emailContext || {};
      const isUserEmail = value === userEmail;

      return !isUserEmail;
    },
  ),
  streetAddress: getStringRequiredSchema(),
  additionalStreetAddress: yup.string(),
  city: getStringRequiredSchema(),
  state: getStringRequiredSchema(),
  country: getStringRequiredSchema(),
  zipCode: getStringRequiredSchema(),
  isPending: getBooleanSchema(),
  isDeleted: getBooleanSchema(),
  isNew: getBooleanSchema(),
  id: yup.string(),
  ssn: yup.string().test('ssn-validation', 'Invalid SSN', function (value) {
    const { existingSSNs, isEditing, editingSSN, isSSNRequired } =
      this.options.context?.ssnContext || {};

    if (!isSSNRequired && !value) {
      return true;
    }

    if (isSSNRequired && !value) {
      return this.createError({ message: 'SSN is required' });
    }

    if (value && !NEW_REG_SSN.test(value)) {
      return this.createError({ message: 'Invalid SSN' });
    }

    if (value && value.length !== 9) {
      return this.createError({ message: 'SSN must be exactly 9 digits' });
    }

    if (value && isEditing && value === editingSSN) {
      return true;
    }

    if (value && existingSSNs?.includes(value)) {
      return this.createError({ message: 'This SSN already exists' });
    }

    return true;
  }),
  relationship: getOneOfEnumSchema(BeneficiaryBeneficiaryRelationType)
    .notRequired()
    .test(
      'valid-spouse-relationship',
      'Cannot select SPOUSE unless marital status is MARRIED',
      function (value) {
        const { maritalStatus } = this.options.context || {};

        if (
          value === BeneficiaryBeneficiaryRelationType.BeneficiaryRelationTypeSpouse &&
          maritalStatus !== NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusMarried
        ) {
          return this.createError({
            message: 'Cannot select SPOUSE unless marital status is MARRIED',
          });
        }

        return true;
      },
    ),
  beneficiaryType: getStringRequiredSchema(),
  //need to be > 0 and <= 100
  percentage: yup
    .number()
    .required('Percentage is required')
    .min(1, 'Percentage must be at least 1%')
    .max(100, 'Percentage cannot exceed 100%'),
});

export const beneficiariesSchema = yup.object().shape({
  [BeneficiariesFormKeys.HAS_BENEFICIARIES]: getYesOrNoSchema().required(),
  [BeneficiariesFormKeys.PRIMARY]: yup
    .array()
    .of(
      beneficiaryFormSchema.shape({
        percentage: yup
          .number()
          .required('Percentage is required')
          .min(1, 'Percentage must be at least 1%')
          .max(100, 'Percentage cannot exceed 100%'),
      }),
    )
    .when(BeneficiariesFormKeys.HAS_BENEFICIARIES, {
      is: (value: string) => value === 'YES',
      then: (schema) =>
        schema
          .min(1, 'At least one primary beneficiary is required')
          .test(
            'total-percentage-primary',
            'Total percentage for primary beneficiaries must equal 100%',
            (beneficiaries) => {
              if (!beneficiaries || beneficiaries.length === 0) return true;

              const activeBeneficiaries = beneficiaries.filter((b) => !b.isDeleted);

              if (activeBeneficiaries.length === 0) return true;

              const totalPercentage = activeBeneficiaries.reduce(
                (acc, beneficiary) => acc + (beneficiary.percentage || 0),
                0,
              );

              return totalPercentage === 100;
            },
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
  [BeneficiariesFormKeys.CONTINGENT]: yup
    .array()
    .of(
      beneficiaryFormSchema.shape({
        percentage: yup
          .number()
          .required('Percentage is required')
          .min(1, 'Percentage must be at least 1%')
          .max(100, 'Percentage cannot exceed 100%'),
      }),
    )
    .test(
      'total-percentage-contingent',
      'Total percentage for contingent beneficiaries must equal 100%',
      (beneficiaries) => {
        if (!beneficiaries || beneficiaries.length === 0) return true;

        const activeBeneficiaries = beneficiaries.filter((b) => !b.isDeleted);

        if (activeBeneficiaries.length === 0) return true;

        const totalPercentage = activeBeneficiaries.reduce(
          (acc, beneficiary) => acc + (beneficiary.percentage || 0),
          0,
        );

        return totalPercentage === 100;
      },
    ),
});
export type BeneficiaryFormData = yup.InferType<typeof beneficiaryFormSchema>;
export type BeneficiariesFormData = yup.InferType<typeof beneficiariesSchema>;
