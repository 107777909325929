import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppStore } from '@/stores/app-store';
import type { ServerSideProps } from '@/ssr/common';
import { UserProfile } from '@fintronners/react-api/src';

type AppProviderProps = React.PropsWithChildren & ServerSideProps;

const ERROR_MESSAGES = {
  userDetails: '"userDetails" is undefined. Make sure is exported from getServerSideProps.',
};

const BYPASS_PATHNAMES = ['/', '/login', '/logout', '/not-found'];

/**
 * This Provider is used to share various types of global data across components.
 * Use one Effect per data initialization.
 */
const AppProvider: React.FC<AppProviderProps> = ({ children, userDetails }) => {
  const { pathname } = useRouter();
  const setUserDetails = useAppStore((state) => state.setUserDetails);

  useEffect(() => {
    if (BYPASS_PATHNAMES.includes(pathname)) return;
    if (!userDetails) return console.error(ERROR_MESSAGES.userDetails);

    const user = userDetails.users.edges?.[0]?.node;

    if (!user) return;
    const { id } = user;

    setUserDetails({
      id,
      profile: user.profile as UserProfile,
    });
  }, [pathname]);

  return <>{children}</>;
};

export default AppProvider;
