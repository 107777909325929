import {
  GetUserDetailsV2Query,
  UserAccountAccountType,
  MarketDataAnswers,
  V1UserFilingStatus,
} from '@fintronners/react-api/src';

export type SupplementalQuestionsOnboardingData = {
  hasSecurityProfessionalDataStored: boolean;
  hasRothIRAAccount: boolean;
  hasMarketDataStored: boolean;
  hasFilingStatusAndAGIDataStored: boolean;
  answers: {
    onboarding?: {
      isSecuritiesProfessional?: boolean | null;
    };
    marketDataAnswers?: Omit<MarketDataAnswers, 'id'> | null;
    filingStatusAndAGI?: {
      filingStatus: V1UserFilingStatus | null;
      annualAGI: number | null;
    };
  };
};

const getOnboardingData = (
  userDetails?: GetUserDetailsV2Query | null,
): SupplementalQuestionsOnboardingData | null => {
  if (!userDetails) return null;

  const data = userDetails?.users.edges?.[0]?.node;
  if (!data) return null;

  const accounts = data.accounts.edges;

  const marketDataAnswers = data?.profile?.marketDataAnswers ?? null;
  const hasMarketDataStored = Boolean(Object.keys(marketDataAnswers ?? {}).length);
  const filingStatusAndAGI = {
    filingStatus: data.profile?.filingStatus ?? null,
    annualAGI: data.profile?.annualGrossIncome ?? null,
  };

  const hasFilingStatusAndAGIDataStored =
    !!data.profile?.filingStatus &&
    !!(data.profile?.annualGrossIncome || data.profile?.annualGrossIncome === 0);

  const hasRothIRAAccount = Boolean(
    accounts?.find((a) => a?.node?.type === UserAccountAccountType.AccountTypeRothIra),
  );

  const isSecuritiesProfessional = hasMarketDataStored
    ? marketDataAnswers?.advisorOrConsultant ||
      marketDataAnswers?.investForOther ||
      marketDataAnswers?.personalUse === false ||
      marketDataAnswers?.registeredWithSecOrCftc ||
      marketDataAnswers?.requiresSecOrCftcRegistration ||
      marketDataAnswers?.investForTheBenefitOfOther ||
      marketDataAnswers?.gettingDataFromExternalSource ||
      marketDataAnswers?.sharesProfit ||
      marketDataAnswers?.registeredWithEntity
    : null;

  const hasSecurityProfessionalDataStored =
    hasMarketDataStored && isSecuritiesProfessional !== null;

  const onboardingData: SupplementalQuestionsOnboardingData = {
    hasRothIRAAccount,
    hasSecurityProfessionalDataStored,
    hasMarketDataStored,
    hasFilingStatusAndAGIDataStored,
    answers: {
      onboarding: {
        isSecuritiesProfessional,
      },
      marketDataAnswers,
      filingStatusAndAGI,
    },
  };

  return onboardingData;
};

export default getOnboardingData;
