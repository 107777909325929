import React from 'react';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ControlPersonFormData, ControlPersonSchema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { InvestmentQuestionsRadio } from '../InvestmentQuestionsRadio';
import { getSimpleEnumKeysAsArray } from '@fintronners/react-utils/src';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import Image from 'next/image';
import { DeleteIcon } from '@/assets/icons';
import { V1UpdateProfileRequest } from '@fintronners/react-api/src';
import { useSidePanel } from '@/context/SidePanelContext';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import useProfileService from '@/hooks/api/useProfileService';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';
import { useSidepanelError } from '@/hooks/utils/useSidepanelError';

const displayStrings = {
  title: 'Are you a control person?',
  description: `If you aren't sure, it likely doesn't apply.`,
  answer: 'I am a control person',
  controlPersonOptions: {
    yes: 'Yes',
    no: 'No',
  },
  symbolLabel:
    'Please enter the ticker symbol(s) of the company/companies for which you are a control person.',
  symbolPlaceholder: 'Symbol of company',
  addACompany: '+ Add a company',
  isControlPersonDescriptionTitle: 'What’s A Control Person',
  isControlPersonDescription:
    'A control person could be defined as a direct, officer, or other affiliate of a publicly traded company, who has control over management or policies. It could also be a stockholder who owns 10% or more of any class of a corporation’s voting securities.',
  save: 'Save',
  back: 'Back',
};

type ControlPersonFormProps = {
  isPending: boolean;
};

export const ControlPersonForm: React.FC<ControlPersonFormProps> = ({ isPending }) => {
  const userApiService = useProfileService();
  const { userDetails, refetch } = useUserDetails();

  const { goBack, setShowBackLink, setShowCloseButton } = useSidePanel();

  const { hasError, handleError, onErrorSidePanelBack } = useSidepanelError(
    setShowBackLink,
    setShowCloseButton,
  );

  const controlPersonDetails = userDetails?.profile?.controlPersonDetails;

  const {
    control,
    formState: { isValid, isDirty },
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ControlPersonSchema),
    values: {
      isControlPerson: controlPersonDetails?.isControlPerson ? YES_OR_NO.YES : YES_OR_NO.NO,
      companySymbols: controlPersonDetails?.companySymbols
        ? controlPersonDetails?.companySymbols.map((symbol) => ({
            value: symbol,
          }))
        : [{ value: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companySymbols',
  });

  const isControlPerson = watch('isControlPerson');

  const onUpdateControlPerson = async (data: ControlPersonFormData) => {
    const request: V1UpdateProfileRequest = {
      profileParameters: {
        controlPersonDetails: {
          isControlPerson: data.isControlPerson === YES_OR_NO.YES,
          companySymbols:
            data.isControlPerson === YES_OR_NO.YES
              ? data.companySymbols?.map((symbol) => symbol.value.trim())
              : [],
        },
      },
    };

    try {
      await userApiService.userServiceUpdateProfile(request);

      await refetch();
      goBack();
    } catch (error) {
      handleError();
    }
  };

  if (hasError) return <ErrorSidePanel onBack={onErrorSidePanelBack} />;

  return (
    <SheetContent>
      <form className="flex h-full flex-col" onSubmit={handleSubmit(onUpdateControlPerson)}>
        <SheetHeader>
          <SheetTitle>{displayStrings.title}</SheetTitle>
        </SheetHeader>

        <div className="flex grow flex-col gap-7">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.description}
          </WebBodyText14.Regular>

          {isPending && <PendingChangesNotice />}

          <InvestmentQuestionsRadio
            title={displayStrings.answer}
            name="isControlPerson"
            dialogTitle={displayStrings.isControlPersonDescriptionTitle}
            dialogContent={displayStrings.isControlPersonDescription}
            items={getSimpleEnumKeysAsArray(
              YES_OR_NO,
              displayStrings.controlPersonOptions as Record<string, string>,
            )}
            control={control}
          />

          {isControlPerson === YES_OR_NO.YES && (
            <div className="flex flex-col gap-2">
              <ul className="flex flex-col gap-4">
                {fields.map((item, index) => (
                  <li key={item.id}>
                    <div className="flex justify-between gap-2">
                      <div className="w-full">
                        <Controller
                          control={control}
                          name={`companySymbols.${index}.value`}
                          render={({ field, fieldState }) => (
                            <FormTextField
                              {...field}
                              fieldError={fieldState.error}
                              label={index === 0 ? displayStrings.symbolLabel : undefined}
                              placeholder={displayStrings.symbolPlaceholder}
                              rightComponent={
                                index > 0 && (
                                  <button type="button" onClick={() => remove(index)}>
                                    <Image
                                      alt="Delete symbol icon"
                                      width={16}
                                      height={16}
                                      src={DeleteIcon.src}
                                    />
                                  </button>
                                )
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <Button
                className="mt-2 w-[150px]"
                variant="ghost"
                onClick={() => append({ value: '' })}
              >
                {displayStrings.addACompany}
              </Button>
            </div>
          )}
        </div>

        <SheetFooter>
          <div className="mt-8 flex w-full flex-col">
            <Button
              variant="primary"
              type="submit"
              className="mb-4"
              disabled={!isDirty || !isValid}
            >
              {displayStrings.save}
            </Button>
            <Button variant="secondary" type="button" onClick={goBack}>
              {displayStrings.back}
            </Button>
          </div>
        </SheetFooter>
      </form>
    </SheetContent>
  );
};
