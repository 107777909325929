import { ContributionWidget } from './parts/ContributionsBar';
import { TaxYearSelect, TaxYearSelectProps } from './parts/TaxYearSelect';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { UserAccountAccountType } from '@fintronners/react-api/src';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import useCurrentAccountType from '@/hooks/utils/useCurrentAccountType';
import { useState, useLayoutEffect } from 'react';
import { useContributions } from '@/hooks/api/useContributions';

const getIraTypeString = (accountType: UserAccountAccountType) => {
  switch (accountType) {
    case UserAccountAccountType.AccountTypeIra:
      return 'Traditional IRA';
    case UserAccountAccountType.AccountTypeRothIra:
      return 'Roth IRA';
    default:
      return '';
  }
};

const displayStrings = {
  contributionSelectDescription: (contributionLimit: string, accountType: UserAccountAccountType) =>
    `Your ${getIraTypeString(accountType)} has a ${contributionLimit} annual contribution limit.`,
};

type ContributionsSelectWidgetProps = {
  showContributionDescription?: boolean;
  handleToggleFormState?: () => void;
  setSelectedTaxYear?: (year: string) => void;
} & TaxYearSelectProps;

const currentYear = new Date().getFullYear().toString();

export const ContributionsSelectWidget = ({
  showContributionDescription = false,
  handleToggleFormState,
  setSelectedTaxYear,
  ...props
}: ContributionsSelectWidgetProps) => {
  const [taxYear, setTaxYear] = useState(props.value ? props.value : currentYear);
  const accountType = useCurrentAccountType();
  const {
    overall: { limit, contributed, limitLeft },
    isContributionsLoading,
  } = useContributions({ taxYear });

  const handleTaxYearChange = (year: string) => {
    if (props.onValueChange) {
      props.onValueChange(year);
    } else {
      setTaxYear(year);
      setSelectedTaxYear && setSelectedTaxYear(year);
    }
  };

  useLayoutEffect(() => {
    if (
      props.value &&
      contributed.current &&
      parseFloat(contributed.current) >= parseFloat(limit)
    ) {
      handleToggleFormState && handleToggleFormState();
    }
  }, [taxYear, contributed, props.value]);

  return (
    <div className="space-y-2 lg:col-span-2">
      <TaxYearSelect
        {...props}
        value={taxYear}
        defaultValue={taxYear}
        onValueChange={handleTaxYearChange}
      />
      {showContributionDescription && (
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.contributionSelectDescription(
            thousandFormatNumbroCurrency(limit, { trimMantissa: true }),
            accountType,
          )}
        </WebBodyText14.Regular>
      )}
      <ContributionWidget
        limit={limit}
        limitLeft={limitLeft}
        contributed={contributed.current}
        isLoading={isContributionsLoading}
      />
    </div>
  );
};
