import i18n from '@fintronners/react-language/src/Lang/lang';

export const FORM_ERROR_MESSAGES = {
  required: i18n.t('errors.required'),
  minLength: (minLength: string | number) => i18n.t('errors.minLength', { minLength }),
  maxLength: (maxLength: string | number) => i18n.t('errors.maxLength', { maxLength }),
  minAmount: (minAmount: string | number) => i18n.t('errors.minAmount', { minAmount }),
  maxAmount: (maxAmount: string | number) => i18n.t('errors.maxAmount', { maxAmount }),
  minPercentAmount: (minAmount: string | number) =>
    i18n.t('errors.minPercentAmount', { minAmount }),
  maxPercentAmount: (maxAmount: string | number) =>
    i18n.t('errors.maxPercentAmount', { maxAmount }),
  invalidUsername: i18n.t('errors.invalidUsername'),
  invalidSSN: i18n.t('errors.invalidSSN'),
  duplicateSSN: i18n.t('errors.duplicateSSN'),
  duplicateEmail: i18n.t('errors.duplicateEmail'),
  duplicatePhoneNumber: i18n.t('errors.duplicatePhoneNumber'),
  passwordDoNotMatch: i18n.t('errors.passwordDoNotMatch'),
  invalidPassword: i18n.t('errors.invalidPassword'),
  invalidPasswordUppercase: i18n.t('errors.invalidPasswordUppercase'),
  invalidPasswordLowercase: i18n.t('errors.invalidPasswordLowercase'),
  invalidPasswordNumber: i18n.t('errors.invalidPasswordNumber'),
  invalidPasswordSpecialCharacter: i18n.t('errors.invalidPasswordSpecialCharacter'),
  invalidPasswordLeadingTrailingSpace: i18n.t('errors.invalidPasswordLeadingTrailingSpace'),
  invalidName: (field: string) => i18n.t('errors.invalidName', { field }),
  invalidInputAscend: (field: string) => i18n.t('errors.invalidInputAscend', { field }),
  invalidCode: i18n.t('errors.invalidCode'),
  invalidEmail: i18n.t('errors.invalidEmail'),
  invalidPhoneNumber: i18n.t('errors.invalidPhoneNumber'),
  invalidPhoneNumberAdmin: i18n.t('errors.invalidPhoneNumberAdmin'),
  birthCountryError: i18n.t('errors.birthCountryError'),
  citizenshipCountryError: i18n.t('errors.citizenshipCountryError'),
  invalidDate: i18n.t('errors.invalidDate'),
  duplicatedField: (type: string) => i18n.t('errors.duplicatedField', { type }),
};
