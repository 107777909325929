import React from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/common/Alert/Alert';
import {
  WebBodyText14,
  WebBodyText18,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

const displayStrings = {
  title: 'Pending changes',
  description:
    "*New values have been submitted but aren't yet confirmed, you may still continue to submit additional changes.",
};

const PendingChangesNotice: React.FC = () => {
  return (
    <Alert variant="info">
      <AlertTitle>
        <WebBodyText18.Bold>{displayStrings.title}</WebBodyText18.Bold>
      </AlertTitle>
      <AlertDescription>
        <WebBodyText14.Regular>{displayStrings.description}</WebBodyText14.Regular>
      </AlertDescription>
    </Alert>
  );
};

export default PendingChangesNotice;
