import { isAfter, isBefore, startOfDay } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/common/Select';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { SelectProps } from '@radix-ui/react-select';

export type TaxYearSelectProps = {} & SelectProps;

const displayStrings = {
  contributionYear: 'Contribution Year:',
};

const currentDate = new Date();
const startOfYear = startOfDay(new Date(currentDate.getFullYear(), 0, 1));
const taxFilingDeadline = startOfDay(new Date(currentDate.getFullYear(), 3, 15)); // April 15th

const showPreviousYear =
  isAfter(currentDate, startOfYear) && isBefore(currentDate, taxFilingDeadline);
const years = showPreviousYear
  ? [currentDate.getFullYear(), currentDate.getFullYear() - 1]
  : [currentDate.getFullYear()];

export const TaxYearSelect = ({ value, ...props }: TaxYearSelectProps) => (
  <div className="flex items-center gap-2">
    <WebBodyText14.Bold className="text-darkestBlue text-nowrap">
      {displayStrings.contributionYear}
    </WebBodyText14.Bold>

    <Select {...props} value={value} disabled={false}>
      <SelectTrigger className="max-w-24 bg-white">
        <SelectValue>
          <p className="first-letter:capitalize">{value}</p>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {years.map((year) => {
            return (
              <SelectItem key={year} value={year.toString()} className="first-letter:capitalize">
                <p className="first-letter:capitalize">{year}</p>
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  </div>
);
