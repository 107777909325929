import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import GoogleStaticMap from '@/components/common/GoogleStaticMap/GoogleStaticMap';
import { Controller, useForm } from 'react-hook-form';
import { showToast } from '@/components/common/Toaster/Toaster';
import { Button } from '@/components/common/Button';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';
import InputPhone from '@/components/common/InputPhone/InputPhone';
import LabelText from '@/components/shims/LabelText';
import { clsx } from 'clsx';
import { stateAbbreviationsOptionsArray } from '@fintronners/react-ui/src/Components/FormFields/GenericPickerFormField/data';
import { ConfirmProfileUpdateDialog } from '@/components/features/settings/profile/ConfirmProfileUpdateDialog';
import usePendingProfileChanges from '@/hooks/api/usePendingProfileChanges';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import {
  ProfileFieldUpdateRequests,
  getProfileFieldUpdateRequests,
  getProfileFormFieldValue,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/profile';

import GooglePlacesAutocomplete, {
  type Place,
  type PlaceChangeHandler,
} from '@/components/common/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import {
  MFAMethodsTypes,
  MFAVerifyCodeDialog,
} from '@/components/features/settings/profile/MFAVerifyCodeDialog';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import VerifyButton from '@/components/features/settings/profile/VerifyButton';
import cn from '@/utils/tailwind';
import {
  WebBodyText14,
  WebBodyText16,
  WebCaption12,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/common/Select';
import { ProfileFormData, profileFormSchema } from './schema';
import useProfileService from '@/hooks/api/useProfileService';
import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { AuthServiceApi } from '@fintronners/react-api/src/tsoai';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';

const displayStrings = {
  title: 'Profile information',
  personalSection: 'Personal information',
  addressSection: 'Address',
  saveChanges: 'Save changes',
  cancel: 'Cancel',
};

const EditProfileInfoSidePanel: React.FC = () => {
  const { closeSidePanel } = useSidePanel();
  const { userDetails, loading: loadingUserDetails, refetch } = useUserDetails();
  const { pendingProfileUpdateRequests, isFieldInPendingState } = usePendingProfileChanges();

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [mfaVerificationType, setMFAVerificationType] = useState<MFAMethodsTypes>(
    MFAMethodsTypes.EMAIL,
  );
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [fieldsToUpdate, setFieldsToUpdate] = useState<{ [key: string]: any }>({});
  const [profileUpdateRequests, setProfileUpdateRequests] = useState<ProfileFieldUpdateRequests>(
    [],
  );

  const mapRef = useRef<HTMLDivElement>(null);
  const {
    control,
    formState: { isDirty, isValid, dirtyFields },
    watch,
    reset,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(profileFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      email: '',
      streetAddress: '',
      additionalStreetAddress: '',
      city: '',
      state: '',
      zipCode: '',
    },
  });

  useEffect(() => {
    if (userDetails) {
      const updateRequests = getProfileFieldUpdateRequests(
        userDetails?.profile?.updateRequests.edges,
      );
      setProfileUpdateRequests(updateRequests);
    }
  }, [userDetails]);

  useEffect(() => {
    if (profileUpdateRequests) {
      reset({
        firstName: getProfileFormFieldValue(
          profileUpdateRequests,
          'firstName',
          userDetails?.profile?.firstName,
        ),
        lastName: getProfileFormFieldValue(
          profileUpdateRequests,
          'lastName',
          userDetails?.profile?.lastName,
        ),
        phoneNumber:
          getProfileFormFieldValue(
            profileUpdateRequests,
            'phoneNumber',
            userDetails?.profile?.phoneNumber,
          ) ?? '',
        email: getProfileFormFieldValue(
          profileUpdateRequests,
          'email',
          userDetails?.profile?.email,
        ),
        streetAddress: getProfileFormFieldValue(
          profileUpdateRequests,
          'streetAddress',
          userDetails?.profile?.streetAddress,
        ),
        additionalStreetAddress: getProfileFormFieldValue(
          profileUpdateRequests,
          'additionalStreetAddress',
          userDetails?.profile?.additionalStreetAddress,
        ),
        city: getProfileFormFieldValue(profileUpdateRequests, 'city', userDetails?.profile?.city),
        state: getProfileFormFieldValue(
          profileUpdateRequests,
          'state',
          userDetails?.profile?.state,
        ),
        zipCode: getProfileFormFieldValue(
          profileUpdateRequests,
          'zipCode',
          userDetails?.profile?.zipCode,
        ),
        dateOfBirth: getProfileFormFieldValue(
          profileUpdateRequests,
          'dateOfBirth',
          userDetails?.profile?.dateOfBirth,
        ),
      });
    }
  }, [profileUpdateRequests]);

  const profileService = useProfileService();
  const { authApiBaseUrl } = useGrpcApiV1Config();

  const onSubmit = useCallback(
    async (data: ProfileFormData) => {
      setFieldsToUpdate({});
      const changedFields = Object.keys(dirtyFields).reduce<Partial<ProfileFormData>>(
        (acc, fieldName) => {
          const key = fieldName as keyof ProfileFormData;
          acc[key] = data[key];
          return acc;
        },
        {},
      );

      const addressFields = Object.keys(dirtyFields).some((field) =>
        ['streetAddress', 'additionalStreetAddress', 'city', 'state', 'zipCode'].includes(field),
      )
        ? {
            streetAddress: data.streetAddress,
            additionalStreetAddress:
              data.additionalStreetAddress !== '' ? data.additionalStreetAddress : 'N/A',
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
          }
        : {};

      const completeFieldsToUpdate = { ...changedFields, ...addressFields };

      if (changedFields.phoneNumber || changedFields.email) {
        const { data: isEmailAndPhoneNumberUnique } = await RuntimeEnvGRPCAPI.getAuthService(
          AuthServiceApi,
          authApiBaseUrl || '',
        ).authServiceCheckUniqueness({
          email: changedFields.email,
          phoneNumber: changedFields.phoneNumber,
        });

        let someUniqueValueIsAlreadyInUse = false;

        if (changedFields.email && !isEmailAndPhoneNumberUnique.email) {
          someUniqueValueIsAlreadyInUse = true;
          setError('email', { message: FORM_ERROR_MESSAGES.duplicateEmail });
        }

        if (changedFields.phoneNumber && !isEmailAndPhoneNumberUnique.phoneNumber) {
          someUniqueValueIsAlreadyInUse = true;
          setError('phoneNumber', { message: FORM_ERROR_MESSAGES.duplicatePhoneNumber });
        }

        if (someUniqueValueIsAlreadyInUse) {
          return;
        }
      }

      setFieldsToUpdate(completeFieldsToUpdate);
      setShowConfirmPopup(true);
    },
    [dirtyFields],
  );

  const onUpdateProfile = async () => {
    setShowConfirmPopup(false);

    try {
      await profileService.userServiceUpdateProfile({
        profileParameters: fieldsToUpdate,
      });

      closeSidePanel();

      showToast({
        variant: 'success',
        message: 'Profile information updated successfully.',
        showCloseButton: true,
      });
    } catch (err) {
      setHasError(true);

      showToast({
        variant: 'error',
        message: 'Failed to update profile information. Please try again.',
        showCloseButton: true,
      });
    }
  };

  const placeChangeHandler = useCallback<PlaceChangeHandler>((place) => {
    setSelectedPlace(place);

    setValue('additionalStreetAddress', 'N/A', { shouldValidate: true });
    place?.city && setValue('city', place.city, { shouldDirty: true });
    place?.state && setValue('state', place.state, { shouldDirty: true });
    place?.zipCode && setValue('zipCode', place.zipCode, { shouldDirty: true });
  }, []);

  const email = watch('email');
  const phoneNumber = watch('phoneNumber');

  return hasError ? (
    <ErrorSidePanel onBack={() => setHasError(false)} />
  ) : (
    <>
      <MFAVerifyCodeDialog
        open={showMFA}
        email={email}
        phoneNumber={phoneNumber}
        onConfirm={() => {
          setShowMFA(false);
          refetch();
        }}
        setModalOpen={setShowMFA}
        type={mfaVerificationType}
      />
      <ConfirmProfileUpdateDialog
        open={showConfirmPopup}
        setModalOpen={setShowConfirmPopup}
        onConfirm={onUpdateProfile}
      />
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{displayStrings.title}</SheetTitle>
        </SheetHeader>
        <form className="flex h-full flex-col" onSubmit={handleSubmit(onSubmit)}>
          {pendingProfileUpdateRequests.length > 0 && (
            <div className="mb-6">
              <PendingChangesNotice />
            </div>
          )}
          <div className="flex flex-col gap-3">
            <div>
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label={`Legal first name${isFieldInPendingState('firstName') ? '*' : ''}`}
                    placeholder="First name"
                    disabled
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label={`Legal last name${isFieldInPendingState('lastName') ? '*' : ''}`}
                    placeholder="Last name"
                    disabled
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="dateOfBirth"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label={`Date of birth${isFieldInPendingState('dateOfBirth') ? '*' : ''}`}
                    placeholder="Date of birth"
                    disabled
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field, fieldState }) => {
                  const isPending = isFieldInPendingState('phoneNumber');
                  return (
                    <>
                      <LabelText
                        htmlFor={field.name}
                        className={cn(
                          'mb-2 block text-sm font-semibold',
                          `${isPending ? 'text-primary' : ''}`,
                        )}
                      >
                        {`Phone number${isPending ? ' *' : ''}`}
                      </LabelText>

                      <div className="flex items-center gap-5">
                        <div className="w-full">
                          <InputPhone
                            {...field}
                            allowedCountries={['us']}
                            defaultCountry="us"
                            inputProps={{
                              className: clsx({
                                '!bg-rose-100': fieldState.error,
                                'bg-primary/20 text-primary': isPending,
                              }),
                            }}
                          />
                          {fieldState.error && (
                            <div className="ml-1 mt-1">
                              <WebCaption12.Regular className="text-red">
                                {fieldState.error.message}
                              </WebCaption12.Regular>
                            </div>
                          )}
                        </div>
                        <VerifyButton
                          isVerified={userDetails?.profile?.unverifiedPhoneNumber === null}
                          onVerify={() => {
                            setMFAVerificationType(MFAMethodsTypes.SMS);
                            setShowMFA(true);
                          }}
                        />
                      </div>
                    </>
                  );
                }}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label="Email"
                    placeholder="Email"
                    type="email"
                    isPending={isFieldInPendingState('email')}
                    rightComponent={
                      <>
                        {!loadingUserDetails && (
                          <VerifyButton
                            isVerified={userDetails?.profile?.unverifiedEmail === null}
                            onVerify={() => {
                              setMFAVerificationType(MFAMethodsTypes.EMAIL);
                              setShowMFA(true);
                            }}
                          />
                        )}
                      </>
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-7">
            <WebBodyText16.Bold className="text-darkestBlue mb-2">
              {displayStrings.addressSection}
            </WebBodyText16.Bold>
            <div className="mb-4">
              <Controller
                control={control}
                name="streetAddress"
                render={({ field, fieldState }) => (
                  <>
                    <LabelText htmlFor={field.name} className="mb-2 block text-sm font-semibold">
                      {`Address 1${isFieldInPendingState('streetAddress') ? '*' : ''}`}
                    </LabelText>
                    <GooglePlacesAutocomplete
                      {...field}
                      mapRef={mapRef}
                      placeholder="Address 1"
                      onPlaceChange={placeChangeHandler}
                    />
                    {fieldState.error && (
                      <WebCaption12.Regular className="text-red">
                        {fieldState.error.message}
                      </WebCaption12.Regular>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                control={control}
                name="additionalStreetAddress"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label="Apartment, suite, unit, building, floor, etc."
                    isPending={isFieldInPendingState('additionalStreetAddress')}
                    placeholder="Address 2"
                  />
                )}
              />
            </div>
            <div className="mb-4 flex flex-row flex-nowrap gap-4">
              <div className="flex-grow-1 flex-shrink-1 w-full">
                <Controller
                  control={control}
                  name="city"
                  render={({ field, fieldState }) => (
                    <FormTextField
                      {...field}
                      fieldError={fieldState.error}
                      label="City"
                      isPending={isFieldInPendingState('city')}
                      placeholder="City"
                    />
                  )}
                />
              </div>
              <div className="w-[80px] flex-shrink-0 flex-grow-0">
                <WebBodyText14.Regular className="text-grey72 mb-1 font-medium">
                  {`State${isFieldInPendingState('state') ? '*' : ''}`}
                </WebBodyText14.Regular>
                <Controller
                  control={control}
                  name="state"
                  render={({ field }) => {
                    return (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="State" />
                        </SelectTrigger>

                        <SelectContent className="text-grey55 ">
                          {stateAbbreviationsOptionsArray.map((option, index) => {
                            return (
                              <SelectItem key={index} value={option.value}>
                                {option.label}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    );
                  }}
                />
              </div>
              <div className="w-[80px] flex-shrink-0 flex-grow-0">
                <Controller
                  control={control}
                  name="zipCode"
                  render={({ field, fieldState }) => (
                    <FormTextField
                      {...field}
                      fieldError={fieldState.error}
                      label="Zip code"
                      isPending={isFieldInPendingState('zipCode')}
                      placeholder="Zip"
                    />
                  )}
                />
              </div>
            </div>
            <GoogleStaticMap
              ref={mapRef}
              location={selectedPlace?.description ?? userDetails?.profile?.streetAddress}
            />
          </div>
          <SheetFooter>
            <div className="mt-8 flex w-full flex-col">
              <Button
                variant="primary"
                type="submit"
                className="mb-4"
                disabled={!isValid || !isDirty}
              >
                {displayStrings.saveChanges}
              </Button>
              <Button variant="secondary" type="button" onClick={closeSidePanel}>
                {displayStrings.cancel}
              </Button>
            </div>
          </SheetFooter>
        </form>
      </SheetContent>
    </>
  );
};

export default EditProfileInfoSidePanel;
