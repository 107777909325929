import { useEffect, useState } from 'react';
import {
  SecurityAsset,
  SecurityMutualFundDetails,
  useGetMfSecurityBuyScreenInformationQuery,
} from '@fintronners/react-api/src';
import Image from 'next/image';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/common/Select';
import { Button } from '@/components/common/Button';
import { AssetOverview } from '@/components/common/AssetOverview';
import { OrderTypeDialog } from '@/components/features/trade/OrderTypeDialog';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { OrderType, TradeFormData, TradeSide } from './schema';
import { UseExternalAccount } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';
import { MIN_SELL, getMinimumTradeAmount } from './constants';
import { rawThousandFormatNumbro } from '@fintronners/react-utils/src/numberUtilsTSX';
import { TradeForm } from './parts/TradeForm';
import { WebBodyText20, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { InfoIcon } from '@/assets/icons';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';
import MutualFundDetailsWidget from '@/components/features/securities/MutualFundDetailsWidget/MutualFundsDetailsWidget';
import { FriendlyReminderDialog } from '@/components/features/trade/FriendlyReminderDialog/FriendlyReminderDialog';
import { MutualFundSellCautionDialog } from '@/components/features/trade/MutualFundSellCautionDialog/MutualFundSellCautionDialog';
import { useTranslation } from 'react-i18next';

interface TradeSidePanelFormProps {
  side: TradeSide;
  asset: SecurityAsset;
  tradableInCash: number;
  tradableUnits: number;
  account?: UseExternalAccount | null;
  loading?: boolean;
  onContinue: () => void;
  isMutualFund?: boolean;
}

const displayStrings = {
  withdrawalThreshold: (amount: string) =>
    `Sell orders of $${amount} or greater will automatically Sell All.`,
  withdrawalPortfolioNote:
    'If your sell is 90% or greater of your mutual fund holding, then we will automatically Sell All of the holding.',
  buy: 'Buy',
  sell: 'Sell',
  tradeMarketForm: 'Trade market form',
  marketBuy: 'Market buy',
  limitBuy: 'Limit buy',
  repeatInvestment: 'Repeat investment',
  marketSell: 'Market sell',
  stopLoss: 'Stop loss',
  mutualFundsExecution:
    "Mutual Fund orders entered before 3:45 EST will execute after market hours at the Mutual Fund's NAV. Orders entered after 3:45 EST will execute the next trading day, after market hours at the Mutual Fund's NAV.",
  continue: 'Continue',
};

export const TradeSidePanelForm = ({
  side,
  asset,
  tradableInCash,
  tradableUnits,
  account,
  loading,
  onContinue,
  isMutualFund,
}: TradeSidePanelFormProps) => {
  const { t } = useTranslation();
  const [isAccountValid, setIsAccountValid] = useState(true);
  const { control, setValue } = useFormContext<TradeFormData>();
  const [amount, orderType, limitPrice, interval, stopLoss] = useWatch({
    control,
    name: ['amount', 'orderType', 'limitPrice', 'interval', 'stopLoss'],
  });

  useEffect(() => {
    if (isMutualFund && orderType === OrderType.MARKET_SELL) {
      setValue('orderType', OrderType.MARKET_SELL);
    }
  }, [isMutualFund, setValue]);

  const isLimitBuy = orderType === OrderType.LIMIT_BUY;
  const isRepeatInvestment = orderType === OrderType.REPEAT_INVESTMENT;
  const isStopLoss = orderType === OrderType.STOP_LOSS;

  const parsedAmount = parseFloat(amount);
  const parsedStopLoss = parseFloat(stopLoss);

  const isStopLossDisabled =
    !parsedStopLoss ||
    parsedStopLoss > asset?.market?.last ||
    parsedAmount > rawThousandFormatNumbro(tradableUnits * parsedStopLoss);

  const isAmountUnderMin = parsedAmount < getMinimumTradeAmount(side);
  const isAmountOverMax = parsedAmount > tradableInCash;
  const isAmountInvalid = !amount || isAmountUnderMin || isAmountOverMax;
  const isLimitPriceInvalid = isLimitBuy && !limitPrice;
  const isStopLossInvalid = isStopLoss && isStopLossDisabled;
  const isRepeatInvestmentInvalid = isRepeatInvestment && (!interval || !account);

  const isContinueDisabled =
    isAmountInvalid ||
    isLimitPriceInvalid ||
    isStopLossInvalid ||
    isRepeatInvestmentInvalid ||
    !isAccountValid;

  const securityID = asset?.id;
  const { data: mfSecurityData } = useGetMfSecurityBuyScreenInformationQuery({
    variables: { securityID },
    skip: !securityID,
  });
  const mfDetails = mfSecurityData?.securityAssets.edges?.find(
    (edge) => edge?.node?.id === asset?.id,
  )?.node?.details;

  const { featureFlags: ff } = useFeatureFlags();

  const [friendlyReminderProps, setFriendlyReminderProps] = useState<{
    leftoverCash: number;
    securitySymbol: string;
  }>({ leftoverCash: 0, securitySymbol: '' });
  const [showFriendlyReminder, setShowFriendlyReminder] = useState(false);
  const [showMutualFundSellCaution, setShowMutualFundSellCaution] = useState(false);
  const [leftoverCashConfirmed, setLeftoverCashConfirmed] = useState(false);

  useEffect(() => {
    if (leftoverCashConfirmed) {
      onContinue();
    }
  }, [leftoverCashConfirmed]);

  const handleOnContinue = () => {
    if (orderType === OrderType.MARKET_SELL) {
      if (isMutualFund) {
        const sellPercentage = (parseFloat(amount) / tradableInCash) * 100;
        if (sellPercentage >= 90 && sellPercentage < 100) {
          setShowMutualFundSellCaution(true);
          return;
        }
      } else {
        const leftoverCash = tradableInCash - parseFloat(amount);
        if (leftoverCash < MIN_SELL && leftoverCash !== 0 && !leftoverCashConfirmed) {
          setFriendlyReminderProps({ securitySymbol: asset.symbol, leftoverCash });
          setShowFriendlyReminder(true);
          return;
        }
      }
    } else if (orderType === OrderType.STOP_LOSS) {
      const maximumThreshold = rawThousandFormatNumbro(tradableUnits * parseFloat(stopLoss));
      const leftoverCash = maximumThreshold - parseFloat(amount);

      if (leftoverCash < MIN_SELL && leftoverCash !== 0 && !leftoverCashConfirmed) {
        setFriendlyReminderProps({ securitySymbol: asset.symbol, leftoverCash });
        setShowFriendlyReminder(true);
        return;
      }
    }

    onContinue();
  };

  return (
    <SheetContent>
      {side === TradeSide.SELL && (
        <>
          <FriendlyReminderDialog
            {...friendlyReminderProps}
            open={showFriendlyReminder}
            onOpenChange={setShowFriendlyReminder}
            onKeepPressed={() => {
              setLeftoverCashConfirmed(true);
            }}
            onSellAllPressed={() => {
              if (orderType === OrderType.MARKET_SELL) {
                setValue('amount', tradableInCash.toString());
              } else if (orderType === OrderType.STOP_LOSS) {
                setValue(
                  'amount',
                  rawThousandFormatNumbro(tradableUnits * parseFloat(stopLoss)).toString(),
                );
              }
              onContinue();
            }}
          />
          {isMutualFund && (
            <MutualFundSellCautionDialog
              tradableInCash={tradableInCash}
              open={showMutualFundSellCaution}
              onOpenChange={setShowMutualFundSellCaution}
              onSellAllPressed={() => {
                setValue('amount', tradableInCash.toString());
                onContinue();
              }}
            />
          )}
        </>
      )}
      <div className="mt-4 flex flex-col gap-7">
        <div>
          <AssetOverview
            basePrice={asset?.market?.previousDayClose}
            price={asset?.market?.last}
            logoURI={asset?.logoURI}
            symbol={asset?.symbol}
            name={asset?.name}
            loading={loading}
          />
        </div>
        <div>
          <SheetTitle className="sr-only">{displayStrings.tradeMarketForm}</SheetTitle>
          {isMutualFund ? (
            <WebBodyText20.Bold className="bg-grey6 text-grey72 inline-block rounded-lg px-[10px] py-[5px]">
              {side === TradeSide.BUY ? displayStrings.buy : displayStrings.sell}
            </WebBodyText20.Bold>
          ) : (
            <Controller
              control={control}
              name="orderType"
              render={({ field: { value, onChange } }) => (
                <Select value={value} defaultValue={value} onValueChange={onChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {side === TradeSide.BUY ? (
                        <>
                          <SelectItem value={OrderType.MARKET_BUY}>
                            {displayStrings.marketBuy}
                          </SelectItem>
                          <SelectItem value={OrderType.LIMIT_BUY}>
                            {displayStrings.limitBuy}
                          </SelectItem>
                          {!ff.shouldHideTradeRepeatInvestment && (
                            <SelectItem value={OrderType.REPEAT_INVESTMENT}>
                              {displayStrings.repeatInvestment}
                            </SelectItem>
                          )}
                        </>
                      ) : (
                        <>
                          <SelectItem value={OrderType.MARKET_SELL}>
                            {displayStrings.marketSell}
                          </SelectItem>
                          <SelectItem value={OrderType.STOP_LOSS}>
                            {displayStrings.stopLoss}
                          </SelectItem>
                        </>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
            />
          )}
          {!isMutualFund && (
            <div className="mt-4">
              <OrderTypeDialog side={side} />
            </div>
          )}
        </div>

        <TradeForm
          asset={asset}
          orderType={orderType}
          tradableInCash={tradableInCash}
          tradableUnits={tradableUnits}
          setIsAccountValid={setIsAccountValid}
        />

        {isMutualFund && side === TradeSide.SELL && (
          <div className="mb-4 flex flex-col gap-2">
            <div className="flex items-start gap-2">
              <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
              <WebCaption12.Regular className="text-grey41">
                {displayStrings.withdrawalThreshold(
                  rawThousandFormatNumbro(tradableInCash * 0.9).toString(),
                )}
              </WebCaption12.Regular>
            </div>
            <WebCaption12.Regular className="text-grey41 ml-6">
              {displayStrings.withdrawalPortfolioNote}
            </WebCaption12.Regular>
          </div>
        )}
      </div>
      <SheetFooter className="text-center">
        <div className="flex w-full flex-col">
          {isMutualFund && (
            <>
              {mfDetails && orderType === OrderType.MARKET_BUY && (
                <MutualFundDetailsWidget data={mfDetails as SecurityMutualFundDetails} />
              )}
              {orderType === OrderType.MARKET_BUY && (
                <div className="mb-7 mt-7 flex flex-col gap-1">
                  <WebCaption12.Regular className="text-grey55">
                    {t('miscScreens.securities.mutualFund.disclosure.line1')}
                    <a
                      href={t('miscScreens.securities.mutualFund.disclosure.searchMutualFundsLink')}
                      className="text-blue underline"
                      target="_blank"
                    >
                      &nbsp;
                      {t('miscScreens.securities.mutualFund.disclosure.searchMutualFundsButton')}
                    </a>
                  </WebCaption12.Regular>
                  <WebCaption12.Regular className="text-grey55">
                    {displayStrings.mutualFundsExecution}
                  </WebCaption12.Regular>
                </div>
              )}
            </>
          )}
          <Button disabled={isContinueDisabled} onClick={handleOnContinue}>
            {displayStrings.continue}
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
