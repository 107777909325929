import React, { forwardRef, useImperativeHandle, useCallback, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { SheetHeader, SheetTitle } from '@/components/common/Sheet';
import {
  WebBodyText14,
  WebBodyText16,
  WebHeading24,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import SingleSelectQuestion, {
  type SingleSelectQuestionType,
} from '@/components/common/SingleSelectQuestion/SingleSelectQuestion';

import { useAppStore } from '@/stores/app-store';
import useProfileService from '@/hooks/api/useProfileService';
import { FOR_PERSONAL_USE } from '@fintronners/react-api/src/utils/constants';
import questionsJson from '../questions/onboard.json';
import type { InitialAnswersData } from '../utils';

import type {
  SupplementalQuestionStepProps,
  SupplementalQuestionSubmitHandler,
} from '../SupplementalQuestionsSidePanel';

import type { MutableRefObject } from 'react';
import { HelpInfoDialog } from '@/components/common/HelpInfoDialog/HelpInfoDialog';

type SecuritiesProfessionalStepProps = SupplementalQuestionStepProps & {
  initialAnswersRef: MutableRefObject<InitialAnswersData<'onboarding', OptionValue>>;
};

export type OptionValue = 'true' | 'false';
type FormValues = Record<string, OptionValue>;

type DialogConfigState = {
  isOpen: boolean;
  title: string;
  content: string;
};

const displayStrings: Record<string, string> = {
  title: 'Welcome to your retirement account.',
  subtitle: 'Before you get started, we need you to answer the following question.',
  callout: 'Select the answer that applies.',
};

const questions = questionsJson as SingleSelectQuestionType[];

const dialogContent = {
  securitiesProfessional: {
    title: 'Securities Professionals',
    content:
      `A securities professional would be one of the following:\n\n` +
      `Is registered or qualified with: the Securities and Exchange Commission, the Commodities Futures Trading Commission, any state securities agency, any exchange association, or any commodities of Futures market or association.\n\n` +
      `Is engaged as an "investment advisor" as that term is defined in section 201(11) of the Investment Advisor's Act of 1940 (whether or not registered or qualified under that Act).\n\n` +
      `Is employed by a bank or another organization that is exempt from registration under Federal and/or state securities laws to perform functions or qualified if he or she were to perform such functions for an organization not so exempt.`,
  },
};

const SecuritiesProfessionalStep = forwardRef<
  SupplementalQuestionSubmitHandler,
  SecuritiesProfessionalStepProps
>(({ setBlockNextStep, setIsSaving, initialAnswersRef }, ref) => {
  const [dialogConfig, setDialogConfig] = useState<DialogConfigState>({
    isOpen: false,
    title: '',
    content: '',
  });

  const userApiService = useProfileService();

  const handleOpenDialog = (type: keyof typeof dialogContent) => {
    setDialogConfig({
      isOpen: true,
      title: dialogContent[type].title,
      content: dialogContent[type].content,
    });
  };

  const { control } = useForm<FormValues>({
    defaultValues: initialAnswersRef.current,
  });

  const responses = useWatch({ control });
  const userDetails = useAppStore((state) => state.userDetails);

  const submitHandler = useCallback<SupplementalQuestionSubmitHandler>(async () => {
    if (responses.isSecuritiesProfessional === 'true') return true;

    try {
      setIsSaving(true);

      await userApiService.userServiceUpdateProfile({
        profileParameters: { marketDataQuestionsAnswers: FOR_PERSONAL_USE },
      });

      return true;
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }

    return false;
  }, [userDetails, responses]);

  useEffect(() => {
    // update responses to parent (local persitence)
    initialAnswersRef.current = responses as InitialAnswersData<'onboarding', OptionValue>;

    const allAnswered = questions.every((question) => responses[question.name] !== undefined);
    if (allAnswered) setBlockNextStep(false);
  }, [responses]);

  // connect handler with parent
  useImperativeHandle(ref, () => submitHandler, [submitHandler]);

  return (
    <div className="flex flex-col space-y-4">
      {/* Step Header */}
      <div>
        <SheetHeader>
          <SheetTitle>
            <WebHeading24.Bold className="text-grey84">{displayStrings.title}</WebHeading24.Bold>
          </SheetTitle>
        </SheetHeader>

        <WebBodyText16.Regular className="text-grey84">
          {displayStrings.subtitle}
        </WebBodyText16.Regular>
      </div>

      <div>
        <WebBodyText16.Regular className="text-grey84">
          {displayStrings.callout}
        </WebBodyText16.Regular>
      </div>

      {/* Questions */}
      <form id="securities-professional-form" className="flex flex-col space-y-1">
        {questions?.map((question) => {
          return (
            <Controller
              name={question.name}
              key={question.name}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SingleSelectQuestion
                  onClickInfo={() => handleOpenDialog('securitiesProfessional')}
                  customHelpText={
                    <WebBodyText14.Regular className="text-grey72 text-left">
                      {question.helpText}
                    </WebBodyText14.Regular>
                  }
                  {...question}
                  {...field}
                />
              )}
            />
          );
        })}
      </form>

      <HelpInfoDialog
        open={dialogConfig.isOpen}
        setModalOpen={(isOpen) => setDialogConfig((prev) => ({ ...prev, isOpen }))}
        title={dialogConfig.title}
        content={dialogConfig.content}
      />
    </div>
  );
});

SecuritiesProfessionalStep.displayName = 'SecuritiesProfessionalStep';
export default SecuritiesProfessionalStep;
