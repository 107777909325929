import { DimensionValue } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';
import fLogger from './fLogger';
import { HexColorCode } from '@fintronners/react-utils/types/colors';
import { NonNullableFields } from '../types/types';

/**
 * Returns whether the given color is a hex code. Useful
 * for adding opacity to a hex code.
 *
 * @param color string to verify
 */
export const isHexRGB = (color?: string) => {
  if (color === undefined) return false;
  return /^#[0-9A-F]{6}$/i.test(color);
};

function getAlpha(num: number): string {
  if (isNaN(num) || num < 0 || num > 100) {
    fLogger.error('Invalid alpha. Returning nothing.');
    return '';
  }
  if (num === 100) return '';
  return num.toString().padStart(2, '0');
}

export const getHexAlpha = (color: HexColorCode, opacity: number): HexColorCode => {
  if (!isHexRGB(color)) {
    fLogger.error('Invalid hex color. Returning color as is.');
    return color;
  }
  return `${color}${getAlpha(opacity)}`;
};

/**
 * Returns a string, converts a number to string with percent sign
 *
 * @param percentValue number to be converted to a string with percent sign
 */
export const convertToPercentString = (percentValue: number): DimensionValue => `${percentValue}%`;

export function generateRandomLetters(length = 10, prefix = '', postfix = '') {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return `${prefix}${randomString}${postfix}`;
}

export function generateRandomNumber(length = 10, exclude: number[] = []) {
  let number = '';
  for (let i = 0; i < length; i++) {
    let randomDigit = Math.floor(Math.random() * 10);
    while (exclude.includes(randomDigit)) {
      randomDigit = Math.floor(Math.random() * 10);
    }
    number += randomDigit;
  }
  return number;
}

export const getSimpleEnumKeysAsArray = <T extends Record<string, string>>(
  inputObject: T,
  translations?: Record<keyof T, string>,
  reverseOrder: boolean = false,
) => {
  const result = Object.entries(inputObject)
    .map(([key, value]) => {
      const displayString =
        translations?.[key] ||
        translations?.[key.toLowerCase()] ||
        key.replace(/([A-Z])/g, ' $1').trim();
      return {
        displayString,
        keyValue: value,
      };
    })
    .filter((obj) => obj.displayString !== 'Unspecified');

  return reverseOrder ? result.reverse() : result;
};

/*
 * Helper function to do check on whether something is empty, null, undefined, false etc.
 */
export function _shouldRender(value: unknown): boolean {
  if (value === false || value === null || value === undefined || value === '0') {
    return false;
  }

  if (typeof value === 'string') {
    return value.trim().length !== 0;
  }

  if (Array.isArray(value)) {
    return value.length !== 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length !== 0;
  }

  return true;
}

export function shouldRender(...values: unknown[]): boolean {
  return values.every(_shouldRender);
}

/**
 * Generates a Google Maps URL based on the provided address details.
 * @param {string} streetAddress - The street address.
 * @param {string} city - The city name.
 * @param {string} state - The state or region name.
 * @param {string} country - The country name.
 * @param {string} zipcode - The postal code or ZIP code.
 * @returns {string} - The Google Maps URL for the provided address.
 */
export function generateMapURL(
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  zipcode: string,
) {
  const fullAddress = generateFullAddress(streetAddress, city, state, country, zipcode);

  const encodedAddress = encodeURIComponent(fullAddress);

  const mapURL = `https://www.google.com/maps?q=${encodedAddress}`;

  return mapURL;
}

export function generateFullAddress(
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  zipcode: string,
) {
  return `${streetAddress}, ${city}, ${state}, ${country} ${zipcode}`;
}

export const getRandomHexColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
};

/**
 * Formats the provided Social Security Number (SSN) string.
 *
 * @param val - The SSN string to be formatted.
 * @returns The formatted SSN string with dashes.
 */
export const getFormattedSSN = (val?: string | null) => {
  if (!val) return null;
  val = val.replace(/\D/g, '');

  if (val.length !== 9) return null;

  val = val.replace(/^(\d{3})/, '$1-');
  val = val.replace(/-(\d{2})/, '-$1-');
  val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val;
};

/**
 * Formats the provided US phone number to the format (XXX) XXX-XXXX.
 *
 * @param phoneNumber - The phone number to be formatted.
 * @returns The formatted phone number string, or null if the input is empty or invalid.
 */
export const getFormattedPhoneNumber = (phoneNumber?: string | null) => {
  if (!phoneNumber) return null;
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\+?1)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return null;
};

/**
 * Strips the country code from the provided phone number, otherwise
 * just returns the phone number as is.
 */
export const stripCountryCode = (phoneNumber?: string) => {
  if (!phoneNumber) return phoneNumber;
  if (phoneNumber.length < 10 || phoneNumber.length > 12) {
    fLogger.error(`Invalid phoneNumber=${phoneNumber}`);
    return phoneNumber;
  }
  if (phoneNumber.startsWith('+')) {
    return phoneNumber.slice(2);
  }
  return phoneNumber;
};

/**
 * Takes the input object and returns a new object with only the defined fields.
 *
 * FIXME: This doesn't not handle nested objects reliably. Please update with unit
 * tests if a clean way is found to handle nested objects.
 */
export const toOnlyDefinedFields = <T extends object>(anyObject: T): NonNullableFields<T> => {
  const isObject = (value: any): value is object => value !== null && typeof value === 'object';
  return Object.fromEntries(
    Object.entries(anyObject)
      .map(([key, value]) => {
        if (isObject(value)) {
          return [key, toOnlyDefinedFields(value)];
        }
        return [key, value];
      })
      .filter(([_, value]) => {
        return value !== undefined && value !== null;
      }),
  );
};
