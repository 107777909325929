import { IconHouseSvg, IconNavSettingsSet1Svg } from '@fintronners/react-ui/src/Images';
import { colors } from '@fintronners/react-ui/src/Themes/colors';
import {
  IconBankSvg,
  IconRoboSvg,
  IconSecuritySvg,
  IconSettingsSvg,
  IconStocksSvg,
  IconSupportSvg,
  LogOutSvg,
} from '@fintronners/react-ui/src/Images';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';
import i18next from '@fintronners/react-language/src/Lang/lang';

/**
 * Navigation routes that should be referenced by anything
 * that needs it. We keep them here so that it's easier to
 * trace in code as well as maintain.
 */
export enum NavRoutes {
  MyOrAllAccounts = 'MyOrAllAccounts',
  CongratulationV2 = 'CongratulationV2',
  Description = 'Description',
  Documents = 'Documents',
  Logout = 'Logout',
  MyAccount = 'MyAccount',
  TradeFlow = 'TradeFlow',
  PDF = 'PDF',
  RepeatInvestment = 'RepeatInvestment',
  Search = 'Search',
  Security = 'SecurityV2',
  Setting = 'Setting',
  SettingsTabView = 'SettingsTabView',
  SupportCategory = 'SupportCategory',
  SupportContact = 'SupportContact',
  SupportList = 'SupportList',
  TransactionHistory = 'TransactionHistory',
  Transfer = 'Transfer',
  TrustedContact = 'TrustedContact',
  UpdateBank = 'UpdateBank',
  ExistingGoal = 'ExistingGoal',
  GeneralInvesting = 'GeneralInvesting',
  GeneralInvestingEditAllocations = 'GeneralInvestingEditAllocations',
  ProjectionScreen = 'ProjectionScreen',
  RoboAllocation = 'RoboAllocation',
  RoboActivityHistory = 'RoboActivityHistory',
  TransferScreenV2 = 'TransferScreenV2',
  SettingsSecurity = 'SettingsSecurity',
  StockAccount = 'StockAccount',
  InvestmentQuestions = 'InvestmentQuestions',
  RiskTolerance = 'RiskTolerance',
  InvestorProfile = 'InvestorProfile',
  InvestingQuestions = 'InvestingQuestions',
  PersonalQuestions = 'PersonalQuestions',
  EmploymentInformation = 'EmploymentInformation',
  SecuritiesProfessional = 'SecuritiesProfessional',
  ControlPerson = 'ControlPerson',
  PoliticallyExposed = 'PoliticallyExposed',
  FinraStockExchangeMember = 'FinraStockExchangeMember',
  RoboAccount = 'RoboAccount',
  CloseAccount = 'CloseAccount',
  DeactivateGoal = 'DeactivateGoal',

  GoalSetting = 'GoalSetting',
  GoalPriorityLevel = 'GoalPriorityLevel',
  GeneralInvestmentingSetting = 'GeneralInvestmentingSetting',

  ProfileSettings = 'ProfileSettings',
  ProfileInformation = 'ProfileInformation',
  ResidentialAddress = 'ResidentialAddress',

  OpenStockAccount = 'OpenStockAccount',
  CreateGoal = 'CreateGoal',
  Verification = 'Verification',

  ApexClearingPrograms = 'ApexClearingPrograms',
  RoboInvestorProfile = 'RoboInvestorProfile',
  RoboInvestmentQuestions = 'RoboInvestmentQuestions',
  OtherSourcesOfWealth = 'OtherSourcesOfWealth',
}

/**
 * Navigation routes for stacks
 */
export enum NavStacks {
  MainDrawer = 'MainDrawer',
  BottomTab = 'BottomTab',
}

/**
 * Navigation routes for authentication.
 */

export enum NavRoutesAuth {
  Home = 'Home',
  Login = 'Login',
}

export enum NavRoutesCommon {
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
  Congratulation = 'Congratulation',
  EditValues = 'EditValues',
  RoboGeneralInvestmentDetailsEditScreen = 'RoboGeneralInvestmentDetailsEditScreen',
  RoboFundSleeve = 'RoboFundSleeve',
  RoboStockSleeve = 'RoboStockSleeve',
  RoboStockSleeveHoldings = 'RoboStockSleeveHoldings',
  RemoveBankAccount = 'RemoveBankAccount',
  PlaidAuth = 'PlaidAuth',
  Onboarding = 'Onboarding',
  RtqSurvey = 'RtqSurvey',
}

export enum DefaultNavRoute {
  'DefaultParams' = 'DefaultParams',
}

export type AllNavRoutes = NavRoutes | NavRoutesAuth | NavRoutesCommon | DefaultNavRoute;
export type AllAuthNavRoutes = NavRoutesAuth | NavRoutesCommon | DefaultNavRoute;
export type AllAppNavRoutes = NavRoutes | NavRoutesCommon | DefaultNavRoute;

/**
 * NavRoutes for screens in the auth stack.
 */
export const AuthRoutes: AllAuthNavRoutes[] = [
  NavRoutesAuth.Home,
  NavRoutesAuth.Login,
  NavRoutesCommon.Onboarding,
];

/**
 * NavRoutes for screens at the top of the stack for the bottom tab.
 */
export const MenuRoutes: AllAppNavRoutes[] = [NavRoutes.MyOrAllAccounts, NavRoutes.SettingsTabView];

/**
 * Routes that have multiple screens within the screen.
 *
 * Adding a route here will show an X in the header instead of <-.
 * See https://www.figma.com/file/9tpfms1R2yMiHfEMmisjfa/Future-Navigation-FinTron?node-id=3%3A1025&t=EZOfaD8qYUXmXwi0-0 for more details
 */
export const IndependentRoutes = [
  NavRoutes.TradeFlow,
  NavRoutes.TransferScreenV2,
  NavRoutes.CongratulationV2,
];

//TODO: add translations
export const NAV_ITEMS = {
  [NavRoutes.MyOrAllAccounts]: {
    key: NavRoutes.MyOrAllAccounts,
    text: 'Home',
    route: NavRoutes.MyOrAllAccounts,
    SvgIcon: IconHouseSvg,
  },
  [NavRoutes.SettingsTabView]: {
    key: NavRoutes.SettingsTabView,
    text: 'Settings',
    route: NavRoutes.SettingsTabView,
    SvgIcon: IconNavSettingsSet1Svg,
  },
  [NavRoutes.StockAccount]: {
    key: NavRoutes.StockAccount,
    text: FinTronConfig.selfDirected.name,
    route: NavRoutes.StockAccount,
    SvgIcon: IconStocksSvg,
  },
  [NavRoutes.OpenStockAccount]: {
    key: NavRoutes.OpenStockAccount,
    text: i18next.t('accounts.open', { account: FinTronConfig.selfDirected.name }),
    SvgIcon: IconStocksSvg,
    route: NavRoutes.OpenStockAccount,
  },
  PendingStocksAccount: {
    key: NavRoutes.OpenStockAccount,
    text: i18next.t('accounts.pending', { account: FinTronConfig.selfDirected.name }),
    SvgIcon: IconStocksSvg,
    route: '',
    disabled: true,
  },
  [NavRoutes.RoboAccount]: {
    key: NavRoutes.RoboAccount,
    text: FinTronConfig.robo.name,
    SvgIcon: IconRoboSvg,
    route: NavRoutes.RoboAccount,
  },
  PendingRoboAccount: {
    key: NavRoutes.OpenStockAccount,
    text: i18next.t('accounts.pending', { account: FinTronConfig.robo.name }),
    SvgIcon: IconRoboSvg,
    route: '',
    disabled: true,
  },
  [NavRoutes.CreateGoal]: {
    key: NavRoutes.CreateGoal,
    text: 'Create a goal',
    SvgIcon: IconRoboSvg,
    route: NavRoutes.CreateGoal,
  },
  [NavRoutes.ProfileSettings]: {
    key: NavRoutes.ProfileSettings,
    text: i18next.t('accounts.settings.profileSettings'),
    route: NavRoutes.ProfileSettings,
    SvgIcon: IconSettingsSvg,
  },
  [NavRoutes.SupportCategory]: {
    key: NavRoutes.SupportCategory,
    text: i18next.t('accounts.settings.support'),
    route: NavRoutes.SupportCategory,
    SvgIcon: IconSupportSvg,
  },
  [NavRoutes.SettingsSecurity]: {
    key: NavRoutes.SettingsSecurity,
    text: i18next.t('accounts.settings.security'),
    route: NavRoutes.SettingsSecurity,
    SvgIcon: IconSecuritySvg,
  },
  [NavRoutes.UpdateBank]: {
    key: NavRoutes.UpdateBank,
    text: i18next.t('accounts.settings.bankLink'),
    route: NavRoutes.UpdateBank,
    SvgIcon: IconBankSvg,
  },
  [NavRoutes.Logout]: {
    key: NavRoutes.Logout,
    text: i18next.t('accounts.settings.logout'),
    route: 'Logout',
    SvgIcon: LogOutSvg,
  },
  [NavRoutes.TransactionHistory]: {
    key: NavRoutes.TransactionHistory,
    text: i18next.t('accounts.active.accountHistory'),
  },
  [NavRoutes.Documents]: {
    key: NavRoutes.Documents,
    text: i18next.t('accounts.active.documents'),
  },
  [NavRoutes.InvestmentQuestions]: {
    key: NavRoutes.InvestmentQuestions,
    text: i18next.t('accounts.active.investmentQuestions'),
  },
  [NavRoutes.CloseAccount]: {
    key: NavRoutes.CloseAccount,
    text: i18next.t('accounts.active.closeAccount'),
    color: colors.negativeText,
  },
  [NavRoutes.InvestorProfile]: {
    key: NavRoutes.InvestorProfile,
    text: i18next.t('robo.settings.investingQuestions.investorProfileInformation'),
  },
  [NavRoutes.RiskTolerance]: {
    key: NavRoutes.RiskTolerance,
    text: i18next.t('robo.settings.investingQuestions.riskProfile'),
  },
  [NavRoutes.ControlPerson]: {
    key: NavRoutes.ControlPerson,
    text: i18next.t('robo.settings.investingQuestions.controlPerson'),
  },
  [NavRoutes.PoliticallyExposed]: {
    key: NavRoutes.PoliticallyExposed,
    text: i18next.t('robo.settings.investingQuestions.politicallyExposed'),
  },
  [NavRoutes.FinraStockExchangeMember]: {
    key: NavRoutes.FinraStockExchangeMember,
    text: i18next.t('robo.settings.investingQuestions.finraStockExchangeMember'),
  },
  [NavRoutes.SecuritiesProfessional]: {
    key: NavRoutes.SecuritiesProfessional,
    text: i18next.t('robo.settings.investingQuestions.securitiesProfessional'),
  },
  [NavRoutes.ProfileInformation]: {
    key: NavRoutes.ProfileInformation,
    text: i18next.t('accounts.settings.profileInformation'),
  },
  [NavRoutes.RoboActivityHistory]: {
    key: NavRoutes.RoboActivityHistory,
    text: 'Activity history',
  },
  [NavRoutes.GoalPriorityLevel]: {
    key: NavRoutes.GoalPriorityLevel,
    text: 'Edit goal priority level',
  },
  [NavRoutesCommon.EditValues]: {
    key: NavRoutesCommon.EditValues,
    text: 'Edit goal values',
  },
  [NavRoutes.DeactivateGoal]: {
    key: NavRoutes.DeactivateGoal,
    text: 'Deactivate goal',
    color: colors.negativeText,
  },
  [NavRoutes.TrustedContact]: {
    key: NavRoutes.TrustedContact,
    text: i18next.t('accounts.active.trustedContact'),
  },
  [NavRoutes.ApexClearingPrograms]: {
    key: NavRoutes.ApexClearingPrograms,
    text: i18next.t('accounts.active.apexClearingPrograms'),
  },
  [NavRoutes.RoboInvestorProfile]: {
    key: NavRoutes.RoboInvestorProfile,
    text: 'Investor Profile',
  },
  [NavRoutes.RoboInvestmentQuestions]: {
    key: NavRoutes.RoboInvestmentQuestions,
    text: 'Investment Questions',
  },
  [NavRoutes.GeneralInvestmentingSetting]: {
    key: NavRoutes.GeneralInvestmentingSetting,
    text: FinTronConfig.robo.generalInvesting.name,
  },
  [NavRoutesCommon.RtqSurvey]: {
    key: NavRoutesCommon.RtqSurvey,
    text: 'Edit risk tolerance questionnaire',
  },
  [NavRoutesCommon.RoboGeneralInvestmentDetailsEditScreen]: {
    key: NavRoutesCommon.RoboGeneralInvestmentDetailsEditScreen,
    text: 'Edit automated investment values',
  },
};
