import React, { useState, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Image from 'next/image';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';
import InputPhone from '@/components/common/InputPhone/InputPhone';
import LabelText from '@/components/shims/LabelText';
import { clsx } from 'clsx';
import { stateAbbreviationsOptionsArray } from '@fintronners/react-ui/src/Components/FormFields/GenericPickerFormField/data';
import GooglePlacesAutocomplete, {
  type PlaceChangeHandler,
} from '@/components/common/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import { WebBodyText14, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/common/Select';

import {
  BeneficiaryFormData,
  beneficiaryFormSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/beneficiariesSchema';

import SingleSelectQuestion, {
  SingleSelectQuestionType,
} from '@/components/common/SingleSelectQuestion/SingleSelectQuestion';

import questionsJson from './beneficiaryTypeQuestion.json';
import { InfoIcon } from '@/assets/icons';
import { DatepickerTrigger } from '@/components/common/Datepicker';
import { GenericDatepicker } from '@/components/common/Datepicker/GenericDatepicker';
import { convertISODateStringToISO8601String } from '@fintronners/react-utils/src/dateUtils';
import { HelpInfoDialog } from '@/components/common/HelpInfoDialog/HelpInfoDialog';
import { sub } from 'date-fns';
import { beneficiaryRelationshipOptions } from '@/components/features/beneficiaries/utils';
import { NonDiscretionaryAccountKycUserMaritalStatus } from '@fintronners/react-api/src';
import isEmpty from 'lodash/isEmpty';

export type BeneficiaryType = 'primary' | 'contingent';
type DialogConfig = {
  isOpen: boolean;
  title: string;
  content: string;
};

type AddBeneficiarySidePanelProps = {
  beneficiaryIndex: number | undefined;
  beneficiary: BeneficiaryFormData | undefined;
  beneficiaryType: BeneficiaryType;
  existingSSNs: string[];
  userEmail: string;
  userMaritalStatus?: NonDiscretionaryAccountKycUserMaritalStatus | null;
  onAddBeneficiary: (beneficiary: BeneficiaryFormData) => void;
  onEditBeneficiary: (beneficiary: BeneficiaryFormData) => void;
  hasPrimaryBeneficiaries: boolean | undefined;
};

type AddBeneficiaryTypeQuestion = {
  title: string;
  name: string;
};

type AddBeneficiaryTypeQuestions = [
  SingleSelectQuestionType & AddBeneficiaryTypeQuestion,
  AddBeneficiaryTypeQuestion,
];

const questions = questionsJson as AddBeneficiaryTypeQuestions;

const minumunAge = sub(new Date(), { years: 18 });

const displayStrings = {
  title: 'Add a beneficiary',
  editTitle: 'Edit beneficiary',
  add: 'Add',
  edit: 'Save changes',
  cancel: 'Cancel',
  infoLabel: 'Beneficiary Type',
  infoDescription: 'This beneficiary will be set as Primary.',
  dialogTitle: 'Beneficiary Type',
  dialogContent:
    "A primary beneficiary is the person or entity designated to receive the account assets after the account owner's death. The primary beneficiary can be one or more individuals, such as a spouse, child, or other family member, or it can be a trust or charitable organization.\n\n" +
    "A contingent beneficiary, on the other hand, is a secondary beneficiary who would receive the account assets if the primary beneficiary predeceases the account owner or is otherwise unable to inherit the assets. For example, if the primary beneficiary is the account owner's spouse, the contingent beneficiary might be their children or another family member.\n\n" +
    "Having both primary and contingent beneficiaries is important because it ensures that the account assets are distributed according to the account owner's wishes, even if something unexpected happens to the primary beneficiary. It's important to review and update beneficiary designations periodically, especially after major life events, to ensure that the right people or entities are designated as beneficiaries.\n\n",
};

const AddBeneficiarySidePanel: React.FC<AddBeneficiarySidePanelProps> = ({
  beneficiaryIndex,
  beneficiary,
  beneficiaryType,
  existingSSNs,
  userEmail,
  userMaritalStatus,
  hasPrimaryBeneficiaries,
  onAddBeneficiary,
  onEditBeneficiary,
}) => {
  const [hasError, setHasError] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
    isOpen: false,
    title: '',
    content: '',
  });

  const { closeSidePanel } = useSidePanel();

  const isEditing = beneficiary !== undefined;

  const {
    control,
    formState: { isDirty, errors },
    setValue,
    handleSubmit,
    trigger,
  } = useForm<BeneficiaryFormData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(beneficiaryFormSchema),
    context: {
      ssnContext: {
        isSSNRequired: isEditing ? Boolean(beneficiary?.ssn) : false,
        editingSSN: beneficiary?.ssn,
        existingSSNs,
        isEditing,
      },
      emailContext: { userEmail },
      maritalStatus: userMaritalStatus,
    },
    defaultValues: {
      ...beneficiary,
      country: beneficiary ? beneficiary.country : 'US',
      percentage: beneficiary ? beneficiary.percentage : hasPrimaryBeneficiaries ? 0 : 100,
      beneficiaryType: beneficiary
        ? beneficiary.beneficiaryType
        : hasPrimaryBeneficiaries
          ? 'contingent'
          : 'primary',
    },
  });

  const onSubmit = async (data: BeneficiaryFormData) => {
    if (beneficiary && beneficiaryIndex !== undefined) {
      onEditBeneficiary(data);
    } else {
      onAddBeneficiary(data);
    }
    closeSidePanel();
  };

  const placeChangeHandler = useCallback<PlaceChangeHandler>((place) => {
    setValue('additionalStreetAddress', '', { shouldValidate: true });
    place?.city && setValue('city', place.city, { shouldDirty: true });
    place?.state && setValue('state', place.state, { shouldDirty: true });
    place?.zipCode && setValue('zipCode', place.zipCode, { shouldDirty: true });
  }, []);

  const handleOpenDialog = () => {
    setDialogConfig({
      isOpen: true,
      title: displayStrings.dialogTitle,
      content: displayStrings.dialogContent,
    });
  };

  useEffect(() => {
    // SSN must be filled by the user even if it's coming from BE, this isn't an issue, don't fix.
    if (isEditing) {
      setValue('ssn', beneficiary?.ssn, { shouldValidate: Boolean(beneficiary?.ssn) });
      trigger();
    }

    if (beneficiaryType) {
      setValue('beneficiaryType', beneficiaryType);
    }
  }, [isEditing, beneficiary?.ssn, beneficiaryType]);

  return hasError ? (
    <ErrorSidePanel onBack={() => setHasError(false)} />
  ) : (
    <SheetContent>
      <HelpInfoDialog
        open={dialogConfig.isOpen}
        setModalOpen={(isOpen) => setDialogConfig((prev) => ({ ...prev, isOpen }))}
        title={dialogConfig.title}
        content={dialogConfig.content}
      />
      <div className="flex flex-col justify-start">
        <SheetHeader>
          <SheetTitle>{beneficiary ? displayStrings.editTitle : displayStrings.title}</SheetTitle>
        </SheetHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormTextField
                  {...field}
                  fieldError={fieldState.error}
                  label="First name"
                  placeholder="First name"
                />
              )}
            />
            <Controller
              control={control}
              name="middleName"
              render={({ field, fieldState }) => (
                <FormTextField
                  {...field}
                  value={field.value ?? ''}
                  fieldError={fieldState.error}
                  label="Middle name"
                  placeholder="Middle name"
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormTextField
                  {...field}
                  fieldError={fieldState.error}
                  label="Last name"
                  placeholder="Last name"
                />
              )}
            />
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field, fieldState }) => (
                <GenericDatepicker
                  value={field.value ? new Date(`${field.value}T00:00:00`) : minumunAge}
                  showMonthAndYear
                  onChange={(date) => {
                    if (date) {
                      field.onChange(convertISODateStringToISO8601String(date.toISOString()));
                    }
                  }}
                  disableDates={[{ after: minumunAge }]}
                  trigger={
                    <DatepickerTrigger>
                      <FormTextField
                        {...field}
                        className="text-left"
                        value={field.value}
                        fieldError={fieldState.error}
                        label={`Date of birth`}
                        placeholder="Date of birth"
                        readOnly
                      />
                    </DatepickerTrigger>
                  }
                />
              )}
            />

            <Controller
              control={control}
              name="phoneNumber"
              render={({ field, fieldState }) => {
                return (
                  <div>
                    <LabelText
                      htmlFor={field.name}
                      className="mb-2 block font-medium text-sm text-grey72"
                    >
                      Phone number
                    </LabelText>

                    <div className="flex items-center gap-5">
                      <div className="w-full">
                        <InputPhone
                          {...field}
                          value={field.value ?? undefined}
                          allowedCountries={['us']}
                          defaultCountry="us"
                          inputProps={{
                            className: clsx({
                              '!bg-rose-100': fieldState.error,
                            }),
                          }}
                        />
                        {fieldState.error && (
                          <div className="ml-1 mt-1">
                            <WebCaption12.Regular className="text-red">
                              {fieldState.error.message}
                            </WebCaption12.Regular>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            <div>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label="Email"
                    placeholder="Email"
                    type="email"
                  />
                )}
              />
            </div>
          </div>
          <div className="my-4">
            <Controller
              control={control}
              name="streetAddress"
              render={({ field, fieldState }) => (
                <>
                  <LabelText htmlFor={field.name} className="mb-2 block text-sm font-semibold">
                    Address 1
                  </LabelText>
                  <GooglePlacesAutocomplete
                    {...field}
                    placeholder="Address 1"
                    onPlaceChange={placeChangeHandler}
                  />
                  {fieldState.error && (
                    <WebCaption12.Regular className="text-red">
                      {fieldState.error.message}
                    </WebCaption12.Regular>
                  )}
                </>
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              control={control}
              name="additionalStreetAddress"
              render={({ field, fieldState }) => (
                <FormTextField
                  {...field}
                  fieldError={fieldState.error}
                  label="Apartment, suite, unit, building, floor, etc."
                  placeholder="Address 2"
                />
              )}
            />
          </div>
          <div className="mb-4 flex flex-row flex-nowrap gap-4">
            <div className="flex-grow-1 flex-shrink-1 w-full">
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label="City"
                    placeholder="City"
                  />
                )}
              />
            </div>
            <div className="w-[80px] flex-shrink-0 flex-grow-0">
              <WebBodyText14.Regular className="mb-1 font-medium text-grey72">
                State
              </WebBodyText14.Regular>
              <Controller
                control={control}
                name="state"
                render={({ field }) => {
                  return (
                    <Select onValueChange={field.onChange} {...field}>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="State" />
                      </SelectTrigger>

                      <SelectContent className="text-grey55 ">
                        {stateAbbreviationsOptionsArray.map((option, index) => {
                          return (
                            <SelectItem key={index} value={option.value}>
                              {option.label}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  );
                }}
              />
            </div>
            <div className="w-[80px] flex-shrink-0 flex-grow-0">
              <Controller
                control={control}
                name="zipCode"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    label="Zip code"
                    placeholder="Zip"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <Controller
              control={control}
              name="ssn"
              render={({ field, fieldState }) => (
                <FormTextField
                  {...field}
                  maxLength={9}
                  fieldError={fieldState.error}
                  label="Social security number"
                  extraLabel={'(optional)'}
                  placeholder="SSN"
                />
              )}
            />
            <Controller
              control={control}
              name="percentage"
              render={({ field, fieldState }) => (
                <FormTextField
                  {...field}
                  value={field.value ?? 0}
                  maxLength={9}
                  fieldError={fieldState.error}
                  type="number"
                  min={1}
                  max={100}
                  label="Percentage"
                  placeholder="0%"
                />
              )}
            />
            <Controller
              control={control}
              name="relationship"
              render={({ field, fieldState }) => (
                <div>
                  <WebBodyText14.Medium className="mb-1">Relationship to you</WebBodyText14.Medium>

                  <Select
                    onValueChange={(val) => {
                      field.onChange(val);
                      trigger('relationship');
                    }}
                    {...field}
                    value={field.value ?? undefined}
                  >
                    <SelectTrigger className="w-full text-grey55">
                      <SelectValue placeholder="Relationship to you" />
                    </SelectTrigger>

                    <SelectContent className="text">
                      {beneficiaryRelationshipOptions.map((option, index) => (
                        <SelectItem key={index} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {fieldState.error && (
                    <div className="mt-1 ml-1">
                      <WebCaption12.Regular className="text-red">
                        {fieldState.error.message}
                      </WebCaption12.Regular>
                    </div>
                  )}
                </div>
              )}
            />
            <Controller
              name="beneficiaryType"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col">
                  <div
                    className="flex cursor-pointer gap-2"
                    onClick={() => handleOpenDialog()}
                    role="button"
                  >
                    <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
                    <WebBodyText14.Bold className="text-darkestBlue">
                      {displayStrings.infoLabel}
                    </WebBodyText14.Bold>
                  </div>
                  {!hasPrimaryBeneficiaries ? (
                    <WebBodyText14.Regular className="mt-2 text-grey72">
                      {displayStrings.infoDescription}
                    </WebBodyText14.Regular>
                  ) : (
                    <SingleSelectQuestion
                      key={field.name}
                      fullWidth
                      {...questions[0]}
                      {...field}
                      disabled={false}
                    />
                  )}
                </div>
              )}
            />
          </div>
          <SheetFooter>
            <div className="mt-8 flex w-full flex-col">
              <Button
                variant="primary"
                type="submit"
                className="mb-4"
                disabled={!isDirty || !isEmpty(errors)}
              >
                {beneficiary ? displayStrings.edit : displayStrings.add}
              </Button>
              <Button variant="secondary" type="button" onClick={closeSidePanel}>
                {displayStrings.cancel}
              </Button>
            </div>
          </SheetFooter>
        </form>
      </div>
    </SheetContent>
  );
};

export default AddBeneficiarySidePanel;
