import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React from 'react';
import { InfoPrimaryIcon } from '~assets/icons';
import { Button } from '@/components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { useTranslation } from 'react-i18next';

interface MutualFundSellCautionDialogProps {
  tradableInCash: number;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onSellAllPressed: () => void;
}

export const MutualFundSellCautionDialog = ({
  tradableInCash,
  open,
  onOpenChange,
  onSellAllPressed,
}: MutualFundSellCautionDialogProps) => {
  const { t } = useTranslation();
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  // Sell limit should be 90% of the total amount
  const formattedAmount = thousandFormatNumbroCurrency(tradableInCash * 0.9);

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={onOpenChange}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>
            {t('miscScreens.tradeDisplay.tradeSellMFCaution')}
          </ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {`${t('miscScreens.tradeDisplay.tradeSellMFCautionMessageStart')}`}{' '}
          <strong>{formattedAmount}</strong>,{' '}
          {`${t('miscScreens.tradeDisplay.tradeSellMFCautionMessageEnd')}`}
        </WebBodyText18.Regular>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {t('miscScreens.tradeDisplay.tradeSellMFCautionSellAll')}
        </WebBodyText18.Regular>

        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <div className="flex flex-col gap-4 w-full">
              <Button className="w-full" variant="primary" onClick={onSellAllPressed}>
                {t('miscScreens.tradeDisplay.tradeSellMFCautionSellAllYes')}
              </Button>
              <Button variant={isBreakpoint('xs') ? 'ghost' : 'secondary'}>
                {t('common.cancel')}
              </Button>
            </div>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
