import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { UserProfileUpdateRequestUpdateRequestStatus } from '@fintronners/react-api';

type UpdateRequests = NonNullable<
  NonNullable<ReturnType<typeof useUserDetails>['userDetails']>['profile']
>['updateRequests'];

export const getInvestorProfilePendingStates = (updateRequests: UpdateRequests | undefined) => {
  let isControlPersonPending = false;
  let isPoliticallyExposedPending = false;
  let isSecuritiesProfessionalPending = false;
  let isFINRAStockPending = false;

  if (!updateRequests || !updateRequests.edges?.length) {
    return {
      isControlPersonPending,
      isPoliticallyExposedPending,
      isSecuritiesProfessionalPending,
      isFINRAStockPending,
    };
  }

  for (const u of updateRequests.edges) {
    const isPendingRequest =
      u?.node?.status === UserProfileUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending;

    if (u?.node?.requestParameters.controlPersonDetails !== null && isPendingRequest) {
      isControlPersonPending = true;
    }

    if (u?.node?.requestParameters.politicallyExposedDetails !== null && isPendingRequest) {
      isPoliticallyExposedPending = true;
    }

    if (u?.node?.requestParameters.marketDataAnswers !== null && isPendingRequest) {
      isSecuritiesProfessionalPending = true;
    }

    if (u?.node?.requestParameters.affiliatedExchangeOrFinraDetails && isPendingRequest) {
      isFINRAStockPending = true;
    }

    if (
      isControlPersonPending &&
      isPoliticallyExposedPending &&
      isSecuritiesProfessionalPending &&
      isFINRAStockPending
    ) {
      break;
    }
  }

  return {
    isControlPersonPending,
    isPoliticallyExposedPending,
    isSecuritiesProfessionalPending,
    isFINRAStockPending,
  };
};

export const mapFormTypeToPendingState = (form: string) => {
  if (form === 'Control person') return 'isControlPersonPending';
  if (form === 'Politically exposed') return 'isPoliticallyExposedPending';
  if (form === 'Securities professional') return 'isSecuritiesProfessionalPending';
  if (form === 'FINRA/Stock exchange member') return 'isFINRAStockPending';

  return '';
};
