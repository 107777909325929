import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';
import { WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { Controller, useForm } from 'react-hook-form';
import { CodeFormData, codeSchema, SendCodeMethods } from '../schema';
import { Input } from '@/components/common/Input/Input';
import useAuthService from '@/hooks/api/useAuthService';
import { useState } from 'react';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { showToast } from '@/components/common/Toaster/Toaster';

const displayStrings = {
  title: 'Two-factor authentication code',
  subtitle: (method: SendCodeMethods) =>
    `We just sent you a one-time two-factor authentication code. Enter the code below to confirm your ${method === SendCodeMethods.SMS ? 'mobile phone number' : 'email'}.`,
  resendCodeQuestion: 'Didn’t receive the code?',
  failedToResendCode: 'Failed to resend code',
  failedToActivate: 'Failed to activate 2FA',
  successfullActivation: (isActivion: boolean) =>
    `Success! 2FA has been ${isActivion ? 'activated' : 'deactivated'}.`,
  tapToResend: 'Tap to resend',
  submit: 'Submit',
  cancel: 'Cancel',
};

export const MFASidePanelCode = () => {
  const [isResending, setIsResending] = useState(false);
  const form = useForm<CodeFormData>({
    resolver: yupResolver(codeSchema),
    defaultValues: {
      code: '',
    },
  });

  const { userDetails } = useUserDetails();
  const authService = useAuthService();

  const { sidePanelProps, closeSidePanel, goTo } = useSidePanel();

  const handleResendCode = async () => {
    setIsResending(true);
    try {
      if (sidePanelProps.method === SendCodeMethods.SMS) {
        await authService.authServiceSendVerifyPhone({
          phoneNumber: userDetails?.profile?.phoneNumber,
        });

        return;
      }

      await authService.authServiceSendVerifyEmail({
        email: userDetails?.profile?.email,
      });

      return;
    } catch (error) {
      showToast({
        variant: 'error',
        message: displayStrings.failedToResendCode,
        showCloseButton: true,
      });
    } finally {
      setIsResending(false);
    }
  };

  const verifyMFACode = async (data: CodeFormData) => {
    try {
      if (sidePanelProps.method === SendCodeMethods.SMS) {
        await authService.authServiceVerifyPhone({
          code: data.code,
        });
      } else {
        await authService.authServiceVerifyEmail({
          code: data.code,
        });
      }

      await authService.authServiceToggleMFAPreference({
        isOn: !userDetails?.isLoginMfaEnabled,
      });

      showToast({
        variant: 'success',
        message: displayStrings.successfullActivation(!userDetails?.isLoginMfaEnabled),
        showCloseButton: true,
      });
      goTo(0);
    } catch (error) {
      showToast({
        variant: 'error',
        message: displayStrings.failedToActivate,
        showCloseButton: true,
      });
    }
  };

  return (
    <SheetContent>
      <div>
        <SheetHeader className="mb-6">
          <SheetTitle>{displayStrings.title}</SheetTitle>
          <WebBodyText16.Regular className="text-grey72">
            {displayStrings.subtitle(SendCodeMethods[sidePanelProps.method as SendCodeMethods])}
          </WebBodyText16.Regular>
        </SheetHeader>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="mt-6 flex items-center">
            <Controller
              name="code"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter code"
                  className="w-full text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  inputMode="numeric"
                />
              )}
            />
          </div>
          <WebBodyText16.Regular className="text-grey72 mt-6">
            {displayStrings.resendCodeQuestion}
          </WebBodyText16.Regular>
          <Button variant="link" className="p-0" onClick={handleResendCode} disabled={isResending}>
            {displayStrings.tapToResend}
          </Button>
        </div>
      </div>
      <SheetFooter>
        <div className="flex w-full flex-col gap-4">
          <Button
            className="w-full"
            variant="primary"
            onClick={form.handleSubmit(verifyMFACode)}
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          >
            {displayStrings.submit}
          </Button>
          <Button className="w-full" variant="secondary" onClick={closeSidePanel}>
            {displayStrings.cancel}
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
