import { useFormContext, useWatch, Controller } from 'react-hook-form';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';
import { WebButton16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';

import { Radio } from '@/components/common/Radio/Radio';
import { TransferFormData } from '../../schema';

const { preselectedRecurringInvestments } = FinTronConfig.selfDirected.transfers;

type OneTimeDepositPreselectedOptionsProps = {
  disabled: boolean;
};

export const OneTimeDepositPreselectedOptions = ({
  disabled: _disabled = false,
}: OneTimeDepositPreselectedOptionsProps) => {
  const { control } = useFormContext<TransferFormData>();
  const amount = useWatch({ control, name: 'amount' });

  return (
    <ul className="flex gap-4">
      {preselectedRecurringInvestments.map((preSelectedValue) => (
        <li key={preSelectedValue} className="w-full">
          <Controller
            control={control}
            name="amount"
            disabled={_disabled}
            render={({ field: { value, onChange, disabled } }) => (
              <>
                <Radio.Input
                  id={preSelectedValue.toString()}
                  value={value}
                  onChange={() => onChange(Number(preSelectedValue))}
                  disabled={disabled}
                  checked={
                    preSelectedValue === Number(value) || preSelectedValue === Number(amount)
                  }
                />
                <Radio.Label
                  variant="secondary"
                  htmlFor={preSelectedValue.toString()}
                  disabled={disabled}
                >
                  <WebButton16.Bold>
                    {thousandFormatNumbroCurrency(preSelectedValue, {
                      trimMantissa: true,
                    })}
                  </WebButton16.Bold>
                </Radio.Label>
              </>
            )}
          />
        </li>
      ))}
    </ul>
  );
};
