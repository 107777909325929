import { AuthServiceApi } from '@fintronners/react-api/src';
import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';

const useAuthService = () => {
  const { authApiBaseUrl, accessToken } = useGrpcApiV1Config();

  const authApiService = RuntimeEnvGRPCAPI.getAuthService(
    AuthServiceApi,
    authApiBaseUrl!,
    accessToken,
  );

  return authApiService;
};

export default useAuthService;
