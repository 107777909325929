import { gql } from '@apollo/client';

export const COMMON_USER_PROFILE_REQUEST_PARAMETERS_FRAGMENT = gql`
  fragment CommonUserProfileRequestParameters on UserProfileParameters {
    firstName
    lastName
    email
    dateOfBirth
    phoneNumber
    phoneNumberType
    ssn
    streetAddress
    additionalStreetAddress
    city
    state
    zipCode
    country
    taxFilingInfo {
      filingStatus
      adjustGrossIncome
    }
    controlPersonDetails {
      companySymbols
      isControlPerson
      __typename
    }
    politicallyExposedDetails {
      organizationName
      familyMembers
      isPoliticallyExposed
      __typename
    }
    affiliatedExchangeOrFinraDetails {
      firmName
      isAffiliated
      __typename
    }
    marketDataAnswers {
      personalUse
      gettingDataFromExternalSource
      registeredWithSecOrCftc
      requiresSecOrCftcRegistration
      investForOther
      investForTheBenefitOfOther
      advisorOrConsultant
      sharesProfit
      registeredWithEntity
      __typename
    }
  }
`;

export const COMMON_USER_PROFILE_FRAGMENT = gql`
  fragment CommonUserProfileDetails on UserProfile {
    firstName
    lastName
    ssn
    dateOfBirth
    phoneNumber
    email
    streetAddress
    additionalStreetAddress
    city
    id
    state
    zipCode
    country
    permanentResident
    visaType
    visaExpirationDate
    birthCountry {
      name
      alpha3Code
    }
    citizenshipCountry {
      name
      alpha3Code
    }
    updateRequests(orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          createTime
          status
          requestParameters {
            ...CommonUserProfileRequestParameters
          }
        }
      }
    }
  }
  ${COMMON_USER_PROFILE_REQUEST_PARAMETERS_FRAGMENT}
`;

export const COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT = gql`
  fragment CommonUserInvestorInformationNonDiscretionaryRequestParametersDetails on BrokerageAccountParameters {
    annualIncome
    investmentExperience
    investmentObjective
    maritalStatus
    liquidityNeeds
    riskTolerance
    timeHorizon
    netWorth
    employmentStatus
    employmentDetails {
      EmployerName
      Position
    }
  }
`;

export const COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT = gql`
  fragment CommonUserInvestorInformationDiscretionaryRequestParametersDetails on BrokerageDiscretionaryAccountParameters {
    annualIncome
    investmentObjective
    riskTolerance
    netWorth
    employmentStatus
    employmentDetails {
      EmployerName
      Position
    }
  }
`;

export const COMMON_TRUSTED_CONTACT_REQUEST_PARAMETERS_FRAGMENT = gql`
  fragment CommonTrustedContactRequestParametersDetails on TrustedContactParameters {
    firstName
    lastName
    dateOfBirth
    email
    phoneNumber
  }
`;

export const COMMON_TRUSTED_CONTACT_UPDATE_REQUESTS_FRAGMENT = gql`
  fragment CommonTrustedContactUpdateRequestsDetails on TrustedContactUpdateRequest {
    createTime
    status
    requestParameters {
      ...CommonTrustedContactRequestParametersDetails
    }
  }
  ${COMMON_TRUSTED_CONTACT_REQUEST_PARAMETERS_FRAGMENT}
`;

export const COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_FRAGMENT = gql`
  fragment CommonUserInvestorInformationNonDiscretionaryDetails on NonDiscretionaryAccountKyc {
    account {
      id
      externalID
    }
    annualIncome
    investmentExperience
    investmentObjective
    maritalStatus
    liquidityNeeds
    riskTolerance
    timeHorizon
    netWorth
    employmentStatus
    employmentDetails {
      EmployerName
      Position
    }
    updateRequests {
      createTime
      status
      requestParameters {
        ...CommonUserInvestorInformationNonDiscretionaryRequestParametersDetails
      }
    }
  }
  ${COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT}
`;

export const COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_FRAGMENT = gql`
  fragment CommonUserInvestorInformationDiscretionaryDetails on DiscretionaryAccountKyc {
    annualIncome
    investmentObjective
    riskTolerance
    netWorth
    employmentStatus
    account {
      externalID
      id
    }
    employmentDetails {
      EmployerName
      Position
    }
    updateRequests {
      createTime
      status
      requestParameters {
        ...CommonUserInvestorInformationDiscretionaryRequestParametersDetails
      }
    }
  }
  ${COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT}
`;

export const COMMON_USERACCOUNT_DETAILS_FRAGMENT = gql`
  fragment CommonUserAccountDetailsFragment on UserAccount {
    id
    user {
      id
      profile {
        firstName
        lastName
        ssn
        email
        phoneNumber
        country
        state
        city
        streetAddress
        additionalStreetAddress
        zipCode
      }
    }
    portfolios {
      edges {
        node {
          id
          isDefault
          assetBalances {
            edges {
              node {
                asset {
                  symbol
                }
                balances {
                  value
                }
              }
            }
          }
        }
      }
    }
    adminActions {
      adminFirstName
      adminLastName
    }
    type
    externalID
    createTime
    status
  }
`;
