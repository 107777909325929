import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React from 'react';
import Image from 'next/image';
import { InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';
import { useAppStore } from '@/stores/app-store';

interface ErrorSidePanelProps {
  onBack?: () => void;
}

const displayStrings = {
  title: 'Something is wrong',
  heading: 'Sorry',
  message: 'It looks like something is wrong. Please try again. If the problem continues, contact ',
  back: 'Back',
  close: 'Close',
};

export const ErrorSidePanel = ({ onBack }: ErrorSidePanelProps) => {
  const { closeSidePanel } = useSidePanel();
  const supportEmail = useAppStore((state) => state.tenantSupportEmail);

  return (
    <SheetContent>
      <SheetTitle className="sr-only">{displayStrings.title}</SheetTitle>
      <div className="flex flex-col items-center gap-7 text-center mt-40">
        <Image alt="Success icon" width={50} height={50} src={InfoPrimaryIcon.src} />
        <WebHeading28.Bold className="text-darkestBlue">{displayStrings.heading}</WebHeading28.Bold>
        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.message}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        </WebBodyText18.Regular>
      </div>
      <SheetFooter className="text-center mb-5">
        {onBack ? (
          <Button className="w-full" onClick={onBack}>
            {displayStrings.back}
          </Button>
        ) : (
          <Button className="w-full" onClick={closeSidePanel}>
            {displayStrings.close}
          </Button>
        )}
      </SheetFooter>
    </SheetContent>
  );
};
