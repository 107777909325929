import React, { forwardRef, useImperativeHandle, useCallback, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { SheetHeader, SheetTitle } from '@/components/common/Sheet';
import {
  WebBodyText14,
  WebBodyText16,
  WebHeading24,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { HelpInfoDialog } from '@/components/common/HelpInfoDialog/HelpInfoDialog';

import SingleSelectQuestion, {
  type SingleSelectQuestionType,
} from '@/components/common/SingleSelectQuestion/SingleSelectQuestion';

import { useAppStore } from '@/stores/app-store';
import useProfileService from '@/hooks/api/useProfileService';
import { parseFormAnswersData } from '../utils';
import questionsJson from '../questions/market.json';
import type { InitialAnswersData } from '../utils';

import type {
  SupplementalQuestionStepProps,
  SupplementalQuestionSubmitHandler,
} from '../SupplementalQuestionsSidePanel';

import type { MutableRefObject } from 'react';

export type OptionValue = true | false;
type FormValues = Record<string, OptionValue>;

type MarketDataQuestionsStepProps = SupplementalQuestionStepProps & {
  initialAnswersRef: MutableRefObject<InitialAnswersData<'marketDataAnswers', OptionValue>>;
};

type DialogConfigState = {
  isOpen: boolean;
  title: string;
  content: string;
};

const questions = questionsJson as SingleSelectQuestionType[];

const displayStrings: Record<string, string> = {
  title: 'Market Data Questions.',
  subtitle: "Select all that apply to you. If you're not sure, it likely doesn't apply.",
};

const dialogContent = {
  personalUse: {
    title: 'Personal Use',
    content: (tenant: string | null) =>
      `The question is asking if you plan on solely using the stock market data provided on this application for investing and research on the ${tenant} platform only.`,
  },
  requiresSecOrCftcRegistration: {
    title: 'Regulatory body registration',
    content:
      'Are you involved with any securities (stocks) related activities? If not, select no for this question.',
  },
  advisorOrConsultant: {
    title: 'Investment advisor, asset manager, or financial consultant',
    content: 'If you trade for your own benefit, select no for this question.',
  },
};

const MarketDataQuestionsStep = forwardRef<
  SupplementalQuestionSubmitHandler,
  MarketDataQuestionsStepProps
>(({ setBlockNextStep, setIsSaving, initialAnswersRef }, ref) => {
  const [dialogConfig, setDialogConfig] = useState<DialogConfigState>({
    isOpen: false,
    title: '',
    content: '',
  });

  const tenant = useAppStore((state) => state.tenant);
  const userApiService = useProfileService();

  const { control } = useForm<FormValues>({
    defaultValues: initialAnswersRef.current,
  });

  const responses = useWatch({ control });
  const userDetails = useAppStore((state) => state.userDetails);

  const submitHandler = useCallback<SupplementalQuestionSubmitHandler>(async () => {
    if (!userDetails?.id) return false;

    const payload = {
      profileParameters: {
        marketDataQuestionsAnswers: parseFormAnswersData({
          isForPersonalUse: responses.personalUse,
          receivesDataFromExternalSource: responses.gettingDataFromExternalSource,
          isRegisteredWithSecOrCftc: responses.registeredWithSecOrCftc,
          requiresSecOrCftcRegistration: responses.requiresSecOrCftcRegistration,
          investForOther: responses.investForOther,
          investForTheBenefitOfOther: responses.investForTheBenefitOfOther,
          isAdvisorOrConsultant: responses.advisorOrConsultant,
          sharesProfit: responses.sharesProfit,
          isRegisteredWithEntity: responses.registeredWithEntity,
        }),
      },
    };

    try {
      setIsSaving(true);
      await userApiService.userServiceUpdateProfile(payload);
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }

    return false;
  }, [userDetails, responses]);

  const handleOpenDialog = (type: keyof typeof dialogContent) => {
    setDialogConfig({
      isOpen: true,
      title: dialogContent[type].title,
      content:
        type === 'personalUse' ? dialogContent[type].content(tenant) : dialogContent[type].content,
    });
  };

  // connect handler with parent
  useImperativeHandle(ref, () => submitHandler, [submitHandler]);

  useEffect(() => {
    // update responses to parent (local persitence)
    initialAnswersRef.current = responses as InitialAnswersData<'marketDataAnswers', OptionValue>;

    const allAnswered = questions.every((question) => responses[question.name] !== undefined);
    const hasAtLeastOneYES = Object.values(responses).some((r) => String(r) === 'true');

    if (allAnswered && hasAtLeastOneYES) setBlockNextStep(false);
    else setBlockNextStep(true);
  }, [responses]);

  return (
    <>
      <form id="market-data-questions" className="flex flex-col space-y-4">
        {/* Step Header */}
        <div>
          <SheetHeader>
            <SheetTitle>
              <WebHeading24.Bold className="text-grey84">{displayStrings.title}</WebHeading24.Bold>
            </SheetTitle>
          </SheetHeader>

          <WebBodyText16.Regular className="text-grey84">
            {displayStrings.subtitle}
          </WebBodyText16.Regular>
        </div>

        {/* Questions */}
        <div className="flex flex-col space-y-1">
          {questions?.map((question) => {
            return (
              <Controller
                name={question.name}
                key={question.name}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <SingleSelectQuestion
                    className="pt-5"
                    onClickInfo={
                      question.helpText
                        ? () => {
                            if (question.name === 'personalUse') {
                              handleOpenDialog('personalUse');
                            } else if (question.name === 'requiresSecOrCftcRegistration') {
                              handleOpenDialog('requiresSecOrCftcRegistration');
                            } else if (question.name === 'advisorOrConsultant') {
                              handleOpenDialog('advisorOrConsultant');
                            }
                          }
                        : undefined
                    }
                    customHelpText={
                      question.helpText ? (
                        <WebBodyText14.Regular className="text-left text-grey72">
                          {question.helpText}
                        </WebBodyText14.Regular>
                      ) : undefined
                    }
                    {...question}
                    {...field}
                  />
                )}
              />
            );
          })}
        </div>
      </form>

      <HelpInfoDialog
        open={dialogConfig.isOpen}
        setModalOpen={(isOpen) => setDialogConfig((prev) => ({ ...prev, isOpen }))}
        title={dialogConfig.title}
        content={dialogConfig.content}
      />
    </>
  );
});

MarketDataQuestionsStep.displayName = 'MarketDataQuestionsStep';
export default MarketDataQuestionsStep;
