import { Button } from '@/components/common/Button';
import {
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';
import {
  getInvestorInformationNonDiscretionaryFormFieldPending,
  getInvestorInformationNonDiscretionaryFormFieldValue,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/investorInformationNonDiscretionary';
import {
  WebBodyText14,
  WebBodyText20,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useNonDiscretionaryUpdateRequests from '@fintronners/react-widgets/src/hooks/useNonDiscretionaryUpdateRequests';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { InfoBox } from './parts/InfoBox';
import { Infograph } from './parts/Infograph';
import { RadioGroup } from './parts/RadioGroup';
import { riskProfileSchema, RiskProfileSchemaType } from './riskProfileSidePanel.utils';
import PendingChangesNotice from '~components/notices/PendingChangesNotice';
import useProfileService from '@/hooks/api/useProfileService';
import { useSidepanelError } from '@/hooks/utils/useSidepanelError';

const displayStrings = {
  title: 'Choose your risk tolerance.',
  description:
    'Choosing an accurate risk tolerance allows us to understand your personal investing objective.',
  pending:
    "* New values have been submitted but aren't yet confirmed, you may still continue to submit additional changes.",
  save: 'Save',
  close: 'Close',
};

export const RiskProfileSidePanelForm = () => {
  const { nonDiscretionaryAccountKycs, loading: isFetching } = useUserDetails();
  const { nonDiscretionaryUpdateRequests } = useNonDiscretionaryUpdateRequests();
  const { goTo, setShowBackLink, setShowCloseButton } = useSidePanel();

  const { hasError, handleError, onErrorSidePanelBack } = useSidepanelError(
    setShowBackLink,
    setShowCloseButton,
  );

  const isPending = getInvestorInformationNonDiscretionaryFormFieldPending(
    nonDiscretionaryUpdateRequests,
    'riskTolerance',
  );

  const pendingValue = getInvestorInformationNonDiscretionaryFormFieldValue(
    nonDiscretionaryUpdateRequests,
    'riskTolerance',
    nonDiscretionaryAccountKycs?.riskTolerance,
  );

  const riskValueWithPending = isPending
    ? pendingValue
    : nonDiscretionaryAccountKycs?.riskTolerance;

  const form = useForm<RiskProfileSchemaType>({
    resolver: yupResolver(riskProfileSchema),
    defaultValues: {
      riskTolerance: nonDiscretionaryAccountKycs?.riskTolerance ?? undefined,
    },
  });

  useEffect(() => {
    form.resetField('riskTolerance', { defaultValue: riskValueWithPending, keepDirty: false });
  }, [riskValueWithPending]);

  const userApiService = useProfileService();

  const [loading, setLoading] = useState(false);

  const onSave = async (data: RiskProfileSchemaType) => {
    if (!data.riskTolerance) return;
    setLoading(true);

    try {
      await userApiService.userServiceUpdateKYC({
        brokerage: {
          riskTolerance: data.riskTolerance,
        },
      });

      goTo(1);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  if (hasError) return <ErrorSidePanel onBack={onErrorSidePanelBack} />;

  return (
    <FormProvider {...form}>
      <SheetContent className="overflow-hidden">
        <form onSubmit={form.handleSubmit(onSave)} className="flex flex-col h-full">
          <div className="h-full flex flex-col gap-5 overflow-auto">
            <SheetHeader>
              <SheetTitle>
                <WebBodyText20.Bold className="text-darkestBlue">
                  {displayStrings.title}
                </WebBodyText20.Bold>
              </SheetTitle>

              <SheetDescription asChild>
                <div className="flex flex-col gap-2">
                  <WebBodyText14.Regular className="text-grey72">
                    {displayStrings.description}
                  </WebBodyText14.Regular>

                  {isPending && <PendingChangesNotice />}
                </div>
              </SheetDescription>
            </SheetHeader>

            <RadioGroup loading={isFetching} pending={isPending} />
            <InfoBox />
            <Infograph />
          </div>
          <SheetFooter>
            <div className="flex flex-col w-full gap-4 mt-6">
              <Button disabled={loading || !form.formState.isDirty} type="submit">
                {displayStrings.save}
              </Button>
              <SheetClose asChild>
                <Button variant="secondary">{displayStrings.close}</Button>
              </SheetClose>
            </div>
          </SheetFooter>
        </form>
      </SheetContent>
    </FormProvider>
  );
};
