import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { useFormContext } from 'react-hook-form';
import { Button } from '@/components/common/Button';
import { TradeFormData, TradeSide } from '../schema';
import { TradeCurrency } from './TradeCurrency';

const displayStrings = {
  holdings: (amount?: string) => `Current holdings: ${amount ?? '--'}`,
  sellAll: 'Sell All',
};

interface TradeMarketSellFormProps {
  tradableInCash: number;
}

export const TradeMarketSellForm = ({ tradableInCash }: TradeMarketSellFormProps) => {
  const { setValue } = useFormContext<TradeFormData>();

  return (
    <div>
      <TradeCurrency side={TradeSide.SELL} />

      <WebBodyText14.Regular className="text-primary">
        {displayStrings.holdings(thousandFormatNumbroCurrency(tradableInCash))}
      </WebBodyText14.Regular>
      <Button
        variant="secondary"
        className="mt-2"
        onClick={() => setValue('amount', tradableInCash.toString())}
      >
        {displayStrings.sellAll}
      </Button>
    </div>
  );
};
