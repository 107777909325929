import Image, { ImageProps } from 'next/image';
import React, { useEffect } from 'react';

// FinTron
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import useRecurringRules from '@fintronners/react-widgets/src/hooks/useRecurringRules';
import { AssetAssetType } from '@fintronners/react-api/src';
import fLogger from '@fintronners/react-utils/src/fLogger';
import {
  WebBodyText16,
  WebBodyText20,
  WebCaption12,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { useSidePanel } from '@/context/SidePanelContext';
import { Button } from '@/components/common/Button';
import {
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/common/Sheet';
import { RecurringDepositBlue, RepeatBlue } from '@/assets/icons';
import useTradeApiService from '@/hooks/api/useTradeService';
import usePlaidApiService from '@/hooks/plaid/usePlaidApiService';
import SkeletonBankLinkUnlink from '@/components/common/SkeletonBankLinkUnlink/SkeletonBankLinkUnlink';
import { RemoveACHAccountSidePanelSuccess } from './RemoveACHAccountSidePanelSuccess';
import { useSidepanelError } from '@/hooks/utils/useSidepanelError';

const displayStrings = {
  unlinkAccount: 'Unlink Account',
  linkedActions: `Linked actions`,
  description:
    'You have the following automated functions linked to this bank account. In order to remove this bank account, you must either (1) update these automated functions from within the app to pull funds from a different bank account or (2) you must cancel the functions altogether. Please note, we will cancel any pending transfers associated to this bank account on your behalf.',
  cancel: 'Cancel',
  cancelAllAndContinue: 'Cancel All & Continue',
  linkAccount: 'Link Account',
  recurringInvestment: 'Recurring investment',
  recurringDeposit: 'Recurring deposit',
  accountUnlinkConfirmation: 'Are you sure you want to unlink this account?',
};

const assetTypeData: { [key in AssetAssetType]: { title: string; image: ImageProps['src'] } } = {
  [AssetAssetType.AssetTypeSecurity]: {
    title: displayStrings.recurringInvestment,
    image: RepeatBlue.src,
  },
  [AssetAssetType.AssetTypeFiat]: {
    title: displayStrings.recurringDeposit,
    image: RecurringDepositBlue.src,
  },
  [AssetAssetType.AssetTypeCrypto]: {
    title: displayStrings.recurringDeposit,
    image: RecurringDepositBlue.src,
  },
};

export const RemoveACHAccountSidePanel = () => {
  const {
    closeSidePanel,
    goTo,
    currentFlowStep,
    sidePanelProps,
    setShowBackLink,
    setShowCloseButton,
  } = useSidePanel();

  const { hasError, handleError, onErrorSidePanelBack } = useSidepanelError(
    setShowBackLink,
    setShowCloseButton,
  );

  const {
    recurringRules,
    refetch: refetchRecurringRules,
    loading: isRecurringRulesLoading,
  } = useRecurringRules();
  const tradeService = useTradeApiService();
  const plaidService = usePlaidApiService();

  const hasRecurringRules = recurringRules.length > 0;

  useEffect(() => {
    refetchRecurringRules();
  }, [sidePanelProps]);

  const onDeleteAccount = async () => {
    try {
      await plaidService.plaidServiceRemoveAccount(sidePanelProps.account.id);
    } catch (error) {
      fLogger.error('Failed to delete bank account.', error);
      handleError();
    }
  };

  const onCancelBulkRepeatRules = async () => {
    const recurringRuleIds = recurringRules.map((rule) => rule?.node?.id) ?? [];

    try {
      const deleteRepeatTradePromises = recurringRuleIds.map((ruleUid) => {
        return tradeService.tradeServiceDeleteRepeatTrade(ruleUid ?? '');
      });

      await Promise.all([...deleteRepeatTradePromises]);
    } catch (error) {
      fLogger.log('Error canceling bulk repeat trades: ', error);
      handleError();
    }
  };

  const onContinue = async () => {
    // Cancel all recurring rules if recurring rules exist
    if (hasRecurringRules) {
      // Cancel all recurring rules
      await onCancelBulkRepeatRules();
      await onDeleteAccount();
    } else {
      await onDeleteAccount();
    }

    sidePanelProps.refetchExternalAccounts();
    goTo(1);
  };

  if (isRecurringRulesLoading) {
    return (
      <SheetContent>
        <SheetHeader>
          <SheetTitle>
            <SkeletonBankLinkUnlink />
          </SheetTitle>
        </SheetHeader>
      </SheetContent>
    );
  }

  if (hasError) {
    return <ErrorSidePanel onBack={onErrorSidePanelBack} />;
  }

  return (
    <>
      {currentFlowStep === 0 && (
        <SheetContent>
          <div className="h-full space-y-4">
            <SheetHeader>
              <SheetTitle>
                <WebBodyText20.Bold>
                  {hasRecurringRules ? displayStrings.linkedActions : displayStrings.unlinkAccount}
                </WebBodyText20.Bold>
              </SheetTitle>
              <SheetDescription>
                <WebBodyText16.Regular className="text-grey72">
                  {hasRecurringRules
                    ? displayStrings.description
                    : displayStrings.accountUnlinkConfirmation}
                </WebBodyText16.Regular>
              </SheetDescription>
            </SheetHeader>
            {hasRecurringRules && (
              <ul className="list-none space-y-4">
                {recurringRules.map((item, index) => {
                  const assetType: AssetAssetType =
                    item?.node?.asset.type ?? AssetAssetType.AssetTypeFiat;
                  const assetSymbol = item?.node?.asset.symbol;
                  const SvgIcon = assetTypeData[assetType].image;
                  const title =
                    assetType === AssetAssetType.AssetTypeSecurity
                      ? `${assetTypeData[assetType].title} - ${assetSymbol}`
                      : assetTypeData[assetType].title;

                  return (
                    <li
                      key={`remove-linked-action=${index}-${item?.node?.id}`}
                      className="flex items-center gap-4 shadow p-3 rounded-lg"
                    >
                      <Image src={SvgIcon} alt="icon" width={20} height={20} />
                      <WebCaption12.Regular className="text-grey72">{title}</WebCaption12.Regular>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <SheetFooter>
            <div className="w-full flex flex-col mt-8 gap-2">
              <Button variant="primary" type="button" onClick={onContinue}>
                {hasRecurringRules
                  ? displayStrings.cancelAllAndContinue
                  : displayStrings.unlinkAccount}
              </Button>
              <Button variant="secondary" type="button" onClick={closeSidePanel}>
                {displayStrings.cancel}
              </Button>
            </div>
          </SheetFooter>
        </SheetContent>
      )}

      {currentFlowStep === 1 && <RemoveACHAccountSidePanelSuccess />}
    </>
  );
};
