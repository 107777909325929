/**
 * This file defines all of our typography via the styled-components library.
 *
 * https://www.figma.com/file/ZdilTjDaXGxIX0u3GPAFlf/Fintron-App-Library?node-id=796%3A109&t=2LOz4jizGY0DQt1p-0
 */
import styled from 'styled-components/native';
import { StyleProp, TextStyle } from 'react-native';
import { colors } from '@fintronners/react-ui/src/Themes/colors';
import { scale } from '@fintronners/react-utils/src/dimensions';
import { DefaultTextStyle, TypographyTextSet } from './types';
import { mainTheme } from '@fintronners/react-ui/src/Themes/themes';

/**
 * Base class that defines all of our Typography specs.
 */
export class Typography {
  static Heading60: TextStyle = { fontSize: scale(60), letterSpacing: scale(1.5) };
  static Heading50: TextStyle = { fontSize: scale(50) };
  static Heading45: TextStyle = { fontSize: scale(45) };
  static Heading75: TextStyle = { fontSize: scale(75) };
  static Heading100: TextStyle = { fontSize: scale(100) };
  static HeaderNav35: TextStyle = { fontSize: scale(35) };

  static BodyText75: TextStyle = { fontSize: scale(75), letterSpacing: scale(3) };
  static BodyText50: TextStyle = { fontSize: scale(50) };
  static BodyText45: TextStyle = {
    fontSize: scale(45),
    lineHeight: scale(53),
  };
  static BodyText40: TextStyle = { fontSize: scale(40) };
  static BodyText35: TextStyle = { fontSize: scale(35), lineHeight: scale(41) };
  static BodyText25: TextStyle = { fontSize: scale(25), lineHeight: scale(29) };

  static ButtonText45: TextStyle = { fontSize: scale(45), letterSpacing: scale(0.9) };
  static ButtonText40: TextStyle = { fontSize: scale(40), letterSpacing: scale(0.8) };
  static ButtonText35: TextStyle = { fontSize: scale(35), letterSpacing: scale(0.7) };
  static ButtonText30: TextStyle = { fontSize: scale(30), letterSpacing: scale(0.6) };
  static ButtonText25: TextStyle = { fontSize: scale(25), letterSpacing: scale(0.5) };

  static Legal35: TextStyle = { fontSize: scale(35), color: colors.brandGray };
  static Legal30: TextStyle = { fontSize: scale(30), color: colors.brandGray };
  static Legal25: TextStyle = { fontSize: scale(25), color: colors.brandGray };

  static InputFieldLabel30: TextStyle = { fontSize: scale(30) };
  static InputFieldLabel35: TextStyle = { fontSize: scale(35), lineHeight: scale(41) };
  static InputFieldText45: TextStyle = { fontSize: scale(45), lineHeight: scale(53) };

  static InputAmount180: TextStyle = { fontSize: scale(180) };
  static InputDollarSymbol120: TextStyle = { fontSize: scale(120), letterSpacing: scale(2.4) };

  static PopUpBodyText45: TextStyle = { fontSize: scale(45), fontWeight: '300' };

  static StockPanelHeading38: TextStyle = { fontSize: scale(38) };
  static StockPanelValue30: TextStyle = { fontSize: scale(30), color: colors.grayText };
  static StockPanelLabel24: TextStyle = { fontSize: scale(24), color: colors.grayText55 };
  static Tag: TextStyle = {
    fontSize: scale(25),
    lineHeight: 29,
    textTransform: 'uppercase',
    letterSpacing: 2.5,
  };

  static Hyperlink30: TextStyle = {
    fontSize: scale(30),
    lineHeight: scale(35),
    textDecorationLine: 'underline',
    color: colors.textHyperlink,
  };
  static Hyperlink35: TextStyle = {
    fontSize: scale(35),
    lineHeight: scale(40),
    textDecorationLine: 'underline',
    color: colors.textHyperlink,
  };
}

/**
 * Helper function to generate the styled-component from the given
 * TextStyle.
 */
const generateText = (textStyle: TextStyle) => {
  return styled.Text((props: { style?: StyleProp<TextStyle> }) => {
    const styles = Array.isArray(props.style)
      ? Object.assign({}, ...props.style.filter((s) => typeof s === 'object').flat())
      : props.style ?? undefined;

    return {
      fontFamily: textStyle.fontFamily,
      fontSize: textStyle.fontSize,
      fontWeight: textStyle.fontWeight,
      color: textStyle.color ?? colors.brandBlack,
      textDecorationLine: textStyle.textDecorationLine,
      includeFontPadding: false,
      allowFontScaling: false,
      ...styles,
    };
  });
};

/**
 * Helper function to generate the different variations of a font.
 *
 * Given a font it will generate
 * <Heading60.Regular>
 * <Heading60.Bold>
 * etc...
 *
 * @param textStyle TextStyle object to generate the extra fonts with
 * @param defaultTextStyles Default text styles if not set
 */
const generateStyles = (textStyle: TextStyle, defaultTextStyles: DefaultTextStyle) => {
  return {
    Light: generateText({
      ...defaultTextStyles,
      ...textStyle,
      fontWeight: '300',
    }),
    Regular: generateText({
      ...defaultTextStyles,
      ...textStyle,
      fontWeight: '400',
    }),
    Bold: generateText({
      ...defaultTextStyles,
      ...textStyle,
      fontWeight: '600',
    }),
  };
};

let Heading60: TypographyTextSet;
let Heading50: TypographyTextSet;
let Heading45: TypographyTextSet;
let Heading75: TypographyTextSet;
let Heading100: TypographyTextSet;
let HeaderNav35: TypographyTextSet;
let BodyText75: TypographyTextSet;
let BodyText50: TypographyTextSet;
let BodyText45: TypographyTextSet;
let BodyText40: TypographyTextSet;
let BodyText35: TypographyTextSet;
let BodyText25: TypographyTextSet;
let ButtonText45: TypographyTextSet;
let ButtonText40: TypographyTextSet;
let ButtonText35: TypographyTextSet;
let ButtonText30: TypographyTextSet;
let Legal35: TypographyTextSet;
let Legal30: TypographyTextSet;
let Legal25: TypographyTextSet;
let InputFieldLabel30: TypographyTextSet;
let InputFieldLabel35: TypographyTextSet;
let InputFieldText45: TypographyTextSet;
let InputAmount180: TypographyTextSet;
let InputDollarSymbol120: TypographyTextSet;
let PopUpBodyText45: TypographyTextSet;
let StockPanelHeading38: TypographyTextSet;
let StockPanelLabel24: TypographyTextSet;
let StockPanelValue30: TypographyTextSet;
let Tag: TypographyTextSet;
let Hyperlink30: TypographyTextSet;
let Hyperlink35: TypographyTextSet;

export const regenerateAllStyles = (defaultTextStyles: DefaultTextStyle) => {
  Heading60 = generateStyles(Typography.Heading60, defaultTextStyles);
  Heading50 = generateStyles(Typography.Heading50, defaultTextStyles);
  Heading45 = generateStyles(Typography.Heading45, defaultTextStyles);
  Heading75 = generateStyles(Typography.Heading75, defaultTextStyles);
  Heading100 = generateStyles(Typography.Heading100, defaultTextStyles);
  HeaderNav35 = generateStyles(Typography.HeaderNav35, defaultTextStyles);
  BodyText75 = generateStyles(Typography.BodyText75, defaultTextStyles);
  BodyText50 = generateStyles(Typography.BodyText50, defaultTextStyles);
  BodyText45 = generateStyles(Typography.BodyText45, defaultTextStyles);
  BodyText40 = generateStyles(Typography.BodyText40, defaultTextStyles);
  BodyText35 = generateStyles(Typography.BodyText35, defaultTextStyles);
  BodyText25 = generateStyles(Typography.BodyText25, defaultTextStyles);
  ButtonText45 = generateStyles(Typography.ButtonText45, defaultTextStyles);
  ButtonText40 = generateStyles(Typography.ButtonText40, defaultTextStyles);
  ButtonText35 = generateStyles(Typography.ButtonText35, defaultTextStyles);
  ButtonText30 = generateStyles(Typography.ButtonText30, defaultTextStyles);
  Legal35 = generateStyles(Typography.Legal35, defaultTextStyles);
  Legal30 = generateStyles(Typography.Legal30, defaultTextStyles);
  Legal25 = generateStyles(Typography.Legal25, defaultTextStyles);
  InputFieldLabel30 = generateStyles(Typography.InputFieldLabel30, defaultTextStyles);
  InputFieldLabel35 = generateStyles(Typography.InputFieldLabel35, defaultTextStyles);
  InputFieldText45 = generateStyles(Typography.InputFieldText45, defaultTextStyles);
  InputAmount180 = generateStyles(Typography.InputAmount180, defaultTextStyles);
  InputDollarSymbol120 = generateStyles(Typography.InputDollarSymbol120, defaultTextStyles);
  PopUpBodyText45 = generateStyles(Typography.PopUpBodyText45, defaultTextStyles);
  StockPanelHeading38 = generateStyles(Typography.StockPanelHeading38, defaultTextStyles);
  StockPanelLabel24 = generateStyles(Typography.StockPanelLabel24, defaultTextStyles);
  StockPanelValue30 = generateStyles(Typography.StockPanelValue30, defaultTextStyles);
  Tag = generateStyles(Typography.Tag, defaultTextStyles);
  Hyperlink30 = generateStyles(Typography.Hyperlink30, defaultTextStyles);
  Hyperlink35 = generateStyles(Typography.Hyperlink35, defaultTextStyles);
};

// Initial generation of styles
regenerateAllStyles(mainTheme.text);

export {
  Heading60,
  Heading50,
  Heading45,
  Heading75,
  Heading100,
  HeaderNav35,
  BodyText75,
  BodyText50,
  BodyText45,
  BodyText40,
  BodyText35,
  BodyText25,
  ButtonText45,
  ButtonText40,
  ButtonText35,
  ButtonText30,
  Legal35,
  Legal30,
  Legal25,
  InputFieldLabel30,
  InputFieldLabel35,
  InputFieldText45,
  InputAmount180,
  InputDollarSymbol120,
  PopUpBodyText45,
  StockPanelHeading38,
  StockPanelLabel24,
  StockPanelValue30,
  Tag,
  Hyperlink30,
  Hyperlink35,
};
