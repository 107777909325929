import { useState } from 'react';

/**
 * Custom hook that handles the error state and actions for the investment questions form.
 * @param setHasError - A function to set the error state.
 * @param setShowBackLink - A function to set the visibility of the back link.
 * @param setShowCloseButton - A function to set the visibility of the close button.
 * @returns An object containing the error handling functions.
 */
export const useSidepanelError = (
  setShowBackLink: (showBackLink: boolean) => void,
  setShowCloseButton: (showCloseButton: boolean) => void,
) => {
  const [hasError, setHasError] = useState(false);

  /**
   * Sets the error state and updates the visibility of the back link and close button.
   */
  const handleError = () => {
    setShowBackLink(false);
    setShowCloseButton(true);
    setHasError(true);
  };

  /**
   * Resets the error state and updates the visibility of the back link and close button.
   */
  const onErrorSidePanelBack = () => {
    setShowBackLink(true);
    setShowCloseButton(false);
    setHasError(false);
  };

  return {
    hasError,
    handleError,
    onErrorSidePanelBack,
  };
};
