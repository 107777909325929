import React, { useMemo } from 'react';
import {
  FrequencyDisplayStringMap,
  SecurityAsset,
  SecurityAssetSecurityType,
  V1TradeExpirationType,
} from '@fintronners/react-api/src';
import {
  WebBodyText14,
  WebBodyText20,
  WebCaption10,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import {
  apexSharesShortFormat,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { Button } from '@/components/common/Button';
import { AssetOverview } from '@/components/common/AssetOverview';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';
import { NationalBestBidOfferDialog } from '@/components/features/trade/NationalBestBidOfferDialog/NationalBestBidOfferDialog';
import { OrderType, TradeFormData } from './schema';
import { useFormContext } from 'react-hook-form';
import { useTenantBasedStrings } from '@/hooks/config/useTenantBasedStrings';
import { getStopLossTradeableShares } from './utils';

interface TradeSidePanelConfirmationProps {
  asset: SecurityAsset;
  tradableInCash: number;
  tradableUnits: number;
  isSubmitting: boolean;
  onBack: () => void;
  onContinue: () => void;
}

const displayStrings = {
  summary: 'Order summary',
  orderType: 'Order type:',
  frequency: 'Frequency',
  dollarAmount: 'Dollar amount:',
  estimatedShares: 'Estimated # of shares:',
  orderDuration: 'Order duration:',
  limitPrice: 'Limit price:',
  stopPrice: 'Stop price:',
  estimatedDollarValue: 'Estimated dollar value',
  durationLabels: {
    [V1TradeExpirationType.Day]: '1 Day',
    [V1TradeExpirationType.GoodTilCancel]: 'Good Till Cancel',
    [V1TradeExpirationType.Unspecified]: 'Unspecified',
  },
  orderTypeLabels: {
    marketBuy: 'Market buy',
    marketSell: 'Market sell',
    stopLoss: 'Stop loss',
    limitBuy: 'Limit buy',
    repeatInvestment: 'Repeat Investment',
  },
  buy: 'Buy',
  sell: 'Sell',
  oneDay: '1 day',
  submit: 'Submit',
  back: 'Back',
  securityText: (brokerDealerName: string, clearingFirmName: string) =>
    `Securities are offered via ${brokerDealerName} in a fractional capacity and custodied by ${clearingFirmName}. Investors should be aware that fractional shares represent partial ownership of a security. Investments may lose value and there is a risk of losing money. Brokerage services are offered through ${brokerDealerName}, a registered broker dealer (member FINRA & SIPC).`,
  repeatInvestmentSecurityText: (brokerDealerName: string, clearingFirmName: string) =>
    `You are submitting a repeat investment, which means you are instructing ${brokerDealerName} to transfer the requested dollar amount from your connected bank account at your requested frequency. Repeat investments will be submitted to ${clearingFirmName} for execution as a Market Buy order with a One Day time in force instruction at market open on the third banking day following the successful processing of the transfer of funds.`,
  repeatInvestmentSecurityTextCont: (brokerDealerName: string, clearingFirmName: string) =>
    `Securities are offered via ${brokerDealerName} in a fractional capacity and custodied by ${clearingFirmName}. Investors should be aware that fractional shares represent partial ownership of a security. Investments may lose value and there is a risk of losing money. Brokerage services are offered through ${brokerDealerName}, a registered broker dealer (member FINRA & SIPC).`,
  mutualFundText: `Mutual Fund orders entered before 3:45 EST will execute after market  hours at the Mutual Fund's NAV. Orders entered after 3:45 EST will  execute the next trading day, after market hours at the Mutual Fund's  NAV.`,
};

export const TradeSidePanelConfirmation = ({
  asset,
  tradableInCash,
  tradableUnits,
  isSubmitting,
  onContinue,
  onBack,
}: TradeSidePanelConfirmationProps) => {
  const { getValues } = useFormContext<TradeFormData>();

  const { clearingFirm, brokerDealer } = useTenantBasedStrings();

  const { amount, orderType, limitPrice, interval, stopLoss, duration } = getValues();

  const isMutualFund = asset.securityType === SecurityAssetSecurityType.MutualFund;

  const orderTypeLabel = useMemo(() => {
    if (isMutualFund) return displayStrings[orderType === OrderType.MARKET_BUY ? 'buy' : 'sell'];

    return displayStrings.orderTypeLabels[orderType] || '';
  }, [orderType]);

  const getSharesAmount = useMemo(() => {
    if (orderType === OrderType.STOP_LOSS) {
      return getStopLossTradeableShares(amount, tradableUnits, stopLoss);
    }

    if (parseFloat(amount) === tradableInCash) {
      return apexSharesShortFormat(tradableUnits);
    } else {
      const currentPrice = asset.market?.last || 0;
      return apexSharesShortFormat(currentPrice && parseFloat(amount) / currentPrice);
    }
  }, [orderType, asset, amount]);

  return (
    <SheetContent>
      <div className="flex flex-col gap-7 mt-4">
        <div>
          {asset && asset.market && (
            <AssetOverview
              basePrice={asset.market.previousDayClose}
              price={asset.market.last}
              logoURI={asset.logoURI}
              symbol={asset.symbol}
              name={asset.name}
            />
          )}
          {!isMutualFund && <NationalBestBidOfferDialog securityId={asset.id} isBuy={true} />}
        </div>
        <div>
          <SheetTitle>
            <WebBodyText20.Bold className="text-darkestBlue">
              {displayStrings.summary}
            </WebBodyText20.Bold>
          </SheetTitle>
          <div className="flex flex-col gap-4 mt-5">
            <div className="flex justify-between">
              <WebBodyText14.Regular className="text-grey72">
                {displayStrings.orderType}
              </WebBodyText14.Regular>
              <WebBodyText14.Bold>{orderTypeLabel}</WebBodyText14.Bold>
            </div>
            {orderType === OrderType.REPEAT_INVESTMENT ? (
              <>
                {interval && (
                  <div className="flex justify-between">
                    <WebBodyText14.Regular className="text-grey72">
                      {displayStrings.frequency}
                    </WebBodyText14.Regular>
                    <WebBodyText14.Bold>{FrequencyDisplayStringMap[interval]}</WebBodyText14.Bold>
                  </div>
                )}
                <div className="flex justify-between">
                  <WebBodyText14.Regular className="text-grey72">
                    {displayStrings.dollarAmount}
                  </WebBodyText14.Regular>
                  <WebBodyText14.Bold>{thousandFormatNumbroCurrency(amount)}</WebBodyText14.Bold>
                </div>
              </>
            ) : (
              <>
                {!isMutualFund && (
                  <div className="flex justify-between">
                    <WebBodyText14.Regular className="text-grey72">
                      {displayStrings.estimatedShares}
                    </WebBodyText14.Regular>
                    <WebBodyText14.Bold>{getSharesAmount}</WebBodyText14.Bold>
                  </div>
                )}
                <div className="flex justify-between">
                  <WebBodyText14.Regular className="text-grey72">
                    {displayStrings.orderDuration}
                  </WebBodyText14.Regular>
                  <WebBodyText14.Bold>
                    {isMutualFund ? displayStrings.oneDay : displayStrings.durationLabels[duration]}
                  </WebBodyText14.Bold>
                </div>
                {orderType === OrderType.LIMIT_BUY && (
                  <div className="flex justify-between">
                    <WebBodyText14.Regular className="text-grey72">
                      {displayStrings.limitPrice}
                    </WebBodyText14.Regular>
                    <WebBodyText14.Bold>
                      {thousandFormatNumbroCurrency(limitPrice)}
                    </WebBodyText14.Bold>
                  </div>
                )}
                {orderType === OrderType.STOP_LOSS && (
                  <div className="flex justify-between">
                    <WebBodyText14.Regular className="text-grey72">
                      {displayStrings.stopPrice}
                    </WebBodyText14.Regular>
                    <WebBodyText14.Bold>
                      {thousandFormatNumbroCurrency(stopLoss)}
                    </WebBodyText14.Bold>
                  </div>
                )}
                <hr />
                <div className="flex justify-between">
                  <WebBodyText14.Bold>{displayStrings.estimatedDollarValue}</WebBodyText14.Bold>
                  <WebBodyText14.Bold>{thousandFormatNumbroCurrency(amount)}</WebBodyText14.Bold>
                </div>
              </>
            )}
          </div>
          <div className="flex mt-8 text-center">
            {orderType === OrderType.REPEAT_INVESTMENT ? (
              <WebCaption10.Regular className="text-grey55">
                {displayStrings.repeatInvestmentSecurityText(brokerDealer.name, clearingFirm.name)}
                <hr className="border-none" />{' '}
                {displayStrings.repeatInvestmentSecurityTextCont(
                  brokerDealer.name,
                  clearingFirm.name,
                )}
              </WebCaption10.Regular>
            ) : (
              <WebCaption10.Regular className="text-grey55">
                {displayStrings.securityText(brokerDealer.name, clearingFirm.name)}
                {isMutualFund && (
                  <>
                    <hr className="border-none mt-3" />
                    <WebCaption10.Regular className="text-grey55">
                      {displayStrings.mutualFundText}
                    </WebCaption10.Regular>
                  </>
                )}
              </WebCaption10.Regular>
            )}
          </div>
        </div>
      </div>
      <SheetFooter className="my-5 text-center">
        <div className="w-full flex flex-col gap-2">
          <Button onClick={onContinue} disabled={isSubmitting}>
            {displayStrings.submit}
          </Button>
          <Button variant="secondary" onClick={onBack}>
            {displayStrings.back}
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
