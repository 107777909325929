import React, { useState, useRef, useCallback, useEffect, forwardRef } from 'react';
import { LoadScript, type Libraries } from '@react-google-maps/api';
import Skeleton from '@/components/common/Skeleton/Skeleton';
import { Command as CommandPrimitive } from 'cmdk';
import {
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/common/Command/Command';

import { getPlaceInfo, type PlaceInfo } from './utils';
import { clsx, type ClassValue } from 'clsx';

export type PlaceChangeHandler = (place: Place | null) => void;
export type Place = PlaceInfo & {
  description: string | null;
};

type AutocompleteService = google.maps.places.AutocompleteService;
type PlacesService = google.maps.places.PlacesService;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest;
type PredictionState = Pick<
  AutocompletePrediction,
  'description' | 'place_id' | 'structured_formatting'
>;

type GooglePlacesAutocompleteProps = {
  mapRef?: React.RefObject<HTMLDivElement>;
  onPlaceChange?: PlaceChangeHandler;
  initialValue?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  name?: string;
  inputClassName?: ClassValue;
  className?: ClassValue;
  dropdownClassName?: ClassValue;
  noResultsPlaceholder?: string;
  disabled?: boolean;
};

const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string;
const LIBRARIES: Libraries = ['places'];
const FIELDS: PlaceDetailsRequest['fields'] = ['geometry', 'address_components'];

const GooglePlacesAutocomplete = forwardRef<HTMLInputElement, GooglePlacesAutocompleteProps>(
  (
    {
      initialValue = '',
      value: controlledValue,
      onChange,
      onBlur,
      placeholder = 'Search for a place...',
      noResultsPlaceholder = 'No results found.',
      name,
      className,
      inputClassName,
      dropdownClassName,
      onPlaceChange,
      disabled,
      mapRef,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>(initialValue);
    const [predictions, setPredictions] = useState<PredictionState[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const [ready, setReady] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const autocomplete = useRef<AutocompleteService | null>(null);
    const places = useRef<PlacesService | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const commandRef = useRef<HTMLDivElement>(null);

    const onLoad = useCallback(async () => {
      const Places = window.google.maps.places;
      autocomplete.current = new Places.AutocompleteService();
      places.current = new Places.PlacesService(mapRef?.current ?? document.createElement('div'));

      setReady(true);

      if (initialValue) {
        const initialPredictions = await getPlacePredictions(initialValue);
        setPredictions(initialPredictions);
      }
    }, []);

    const handleInputChange = useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setOpen(true);
        setSelectedKey(null);
        onPlaceChange?.(null);

        if (typeof onChange === 'function') {
          onChange(e);
        } else {
          setInputValue(value);
        }

        if (!value) {
          setPredictions([]);
          return;
        }
      },
      [onPlaceChange, onChange],
    );

    const handlePredictionClick = useCallback(
      ({ structured_formatting: { main_text }, place_id: placeId }: PredictionState) => {
        setSelectedKey(placeId);

        if (typeof onChange === 'function') {
          const syntheticEvent = {
            target: { value: main_text },
          } as React.ChangeEvent<HTMLInputElement>;
          onChange(syntheticEvent);
        } else {
          setInputValue(main_text);
        }

        const request: PlaceDetailsRequest = {
          fields: FIELDS,
          placeId,
        };

        places.current?.getDetails(request, (place) => {
          onPlaceChange?.({ description: main_text, ...getPlaceInfo(place) });
        });

        setOpen(false);
      },
      [onPlaceChange, onChange],
    );

    const handleInputBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (e) => {
        if (
          commandRef.current &&
          (commandRef.current as HTMLDivElement).contains(e.relatedTarget)
        ) {
          e.preventDefault();
          return;
        }

        onBlur?.(e);
        setOpen(false);
      },
      [onBlur],
    );

    const getPlacePredictions = useCallback(
      async (input: string): Promise<AutocompletePrediction[]> => {
        if (!autocomplete.current || !input) return [];
        const result = await autocomplete.current.getPlacePredictions({ input });
        return result ? result.predictions : [];
      },
      [],
    );

    useEffect(() => {
      (async () => {
        const autocompletePredictions = await getPlacePredictions(controlledValue ?? inputValue);
        setPredictions(autocompletePredictions);
      })();
    }, [controlledValue ?? inputValue]);

    return (
      <LoadScript
        googleMapsApiKey={`${API_KEY}&loading=async`}
        onLoad={onLoad}
        libraries={LIBRARIES}
        onError={(e) => setError(e.message)}
      >
        <div className={clsx('mx-auto w-full max-w-md', className)}>
          {!ready && <Skeleton height={42} />}
          {error && <div>Error loading Google Maps</div>}

          {ready && !error && (
            <CommandPrimitive
              ref={commandRef}
              className="relative rounded-f0 border border-grey6"
              shouldFilter={false}
            >
              <div>
                <CommandInput
                  ref={ref ?? inputRef}
                  name={name}
                  value={controlledValue ?? inputValue}
                  onChangeCapture={handleInputChange}
                  placeholder={placeholder}
                  className={clsx('py-5', inputClassName)}
                  onBlur={handleInputBlur}
                  onFocus={() => setOpen(true)}
                  onClick={() => setOpen(true)}
                  disabled={disabled}
                  autoComplete="off"
                  data-1p-ignore
                />
              </div>

              <div className="relative">
                <div
                  className={clsx(
                    'animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-f0 bg-white outline-none',
                    open ? 'block' : 'hidden',
                  )}
                >
                  <CommandList
                    className={clsx(
                      'shadow-md',
                      { 'border border-grey6': !!predictions.length },
                      dropdownClassName,
                    )}
                  >
                    {(controlledValue ?? inputValue) && !predictions.length && (
                      <CommandEmpty>{noResultsPlaceholder}</CommandEmpty>
                    )}

                    {predictions?.map((prediction) => (
                      <CommandItem
                        key={prediction.place_id}
                        value={prediction.description}
                        onSelect={() => handlePredictionClick(prediction)}
                        className={clsx(
                          'px-4 py-2 text-sm',
                          prediction.place_id === selectedKey
                            ? 'bg-blue20'
                            : 'hover:bg-grey7 data-[selected=true]:bg-grey6',
                        )}
                      >
                        {prediction.description}
                      </CommandItem>
                    ))}
                  </CommandList>
                </div>
              </div>
            </CommandPrimitive>
          )}
        </div>
      </LoadScript>
    );
  },
);

GooglePlacesAutocomplete.displayName = 'GooglePlacesAutocomplete';

export default GooglePlacesAutocomplete;
