import {
  getConfirmPasswordSchema,
  getPasswordSchema,
  getSixDigitsCodeSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';
import { REG_LETTER_NUMBER } from '@fintronners/react-ui/src/Constants/RegexConstant';
import * as yup from 'yup';

export const usernameSchema = yup.object({
  username: yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .min(3, FORM_ERROR_MESSAGES.minLength(3))
    .matches(REG_LETTER_NUMBER, {
      message: FORM_ERROR_MESSAGES.invalidName('User name'),
      excludeEmptyString: true,
    }),
});

export const newPasswordSchema = yup.object({
  code: getSixDigitsCodeSchema(),
  password: getPasswordSchema(),
  confirmPassword: getConfirmPasswordSchema(),
});

export type UsernameData = yup.InferType<typeof usernameSchema>;
export type NewPasswordData = yup.InferType<typeof newPasswordSchema>;
