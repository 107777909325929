import { useSidePanel } from '@/context/SidePanelContext';
import { SheetContent } from '../../Sheet';
import { MFASidePanelOptions } from './parts/MFASidePanelOptions';
import { MFASidePanelSendCodeOptions } from './parts/MFASidePanelSendCodeOptions';
import { MFASidePanelCode } from './parts/MFASidePanelCode';

export const MFASidePanel = () => {
  const { currentFlowStep } = useSidePanel();

  return (
    <SheetContent>
      {currentFlowStep === 0 && <MFASidePanelOptions />}
      {currentFlowStep === 1 && <MFASidePanelSendCodeOptions />}
      {currentFlowStep === 2 && <MFASidePanelCode />}
    </SheetContent>
  );
};
