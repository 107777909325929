import { WebBodyText14, WebButton16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Radio } from '@/components/common/Radio/Radio';
import cn from '@/utils/tailwind';
import { InvestmentQuestionsDialog } from './InvestmentQuestionsDialog';

interface InvestmentQuestionsRadioProps {
  title: string;
  dialogTitle?: string;
  dialogContent?: string;
  isPending?: boolean;
  disabled?: boolean;
  control: any;
  name: string;
  items: {
    keyValue: string;
    displayString: string;
  }[];
}

export const InvestmentQuestionsRadio = ({
  title,
  name,
  dialogTitle,
  dialogContent,
  isPending,
  disabled,
  control,
  items,
}: InvestmentQuestionsRadioProps) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        {dialogTitle && dialogContent && (
          <InvestmentQuestionsDialog
            title={dialogTitle}
            content={dialogContent}
            textCenter={dialogTitle === 'Market Data'}
          />
        )}
        <WebBodyText14.Regular className="text-grey72">
          {`${title}${isPending ? '*' : ''}`}
        </WebBodyText14.Regular>
      </div>
      <ul className="flex flex-wrap gap-3 mt-2">
        {items.map((item) => (
          <li key={`${item.keyValue}-${name}`}>
            <Controller
              control={control}
              name={name}
              render={({ field: { value, onChange } }) => (
                <>
                  <Radio.Input
                    id={`${item.keyValue}-${name}`}
                    value={value}
                    onChange={() => onChange(item.keyValue)}
                    checked={item.keyValue === value}
                    disabled={disabled}
                  />
                  <Radio.Label
                    variant="secondary"
                    className={cn({
                      '!text-primary !bg-primary/20': isPending && item.keyValue === value,
                    })}
                    disabled={disabled}
                    htmlFor={`${item.keyValue}-${name}`}
                  >
                    <WebButton16.Bold className="px-3">{item.displayString}</WebButton16.Bold>
                  </Radio.Label>
                </>
              )}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
