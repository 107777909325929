import Image from 'next/image';
import React, { useState } from 'react';
import { InfoIcon, InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

interface InvestmentQuestionsDialogProps {
  title: string;
  content: string;
  textCenter?: boolean;
}

const displayStrings = {
  close: 'Close',
};

export const InvestmentQuestionsDialog = ({
  title,
  content,
  textCenter,
}: InvestmentQuestionsDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const [showModal, setShowModal] = useState(false);

  return (
    <ResponsiveDialog.Root open={showModal} onOpenChange={(isOpen) => setShowModal(isOpen)}>
      <ResponsiveDialog.Trigger asChild>
        <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
      </ResponsiveDialog.Trigger>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        <div className={textCenter ? 'whitespace-pre-line text-center' : 'whitespace-pre-line'}>
          {content}
        </div>
        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
