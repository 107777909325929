import { OrderType } from '../schema';
import { SecurityAsset } from '@fintronners/react-api/src';
import { TradeMarketBuyForm } from './TradeMarketBuyForm';
import { TradeMarketSellForm } from './TradeMarketSellForm';
import { TradeRepeatInvestmentForm } from './TradeRepeatInvestmentForm';
import { TradeLimitBuyForm } from './TradeLimitBuyForm';
import { TradeStopLossForm } from './TradeStopLossForm';

interface TradeFormProps {
  orderType: OrderType;
  tradableInCash: number;
  tradableUnits: number;
  asset: SecurityAsset;
  setIsAccountValid: (isValid: boolean) => void;
}

export const TradeForm = ({
  orderType,
  tradableInCash,
  tradableUnits,
  asset,
  setIsAccountValid,
}: TradeFormProps) => {
  switch (orderType) {
    case OrderType.MARKET_BUY:
      return <TradeMarketBuyForm tradableInCash={tradableInCash} />;
    case OrderType.MARKET_SELL:
      return <TradeMarketSellForm tradableInCash={tradableInCash} />;
    case OrderType.REPEAT_INVESTMENT:
      return <TradeRepeatInvestmentForm setIsAccountValid={setIsAccountValid} />;
    case OrderType.LIMIT_BUY:
      return <TradeLimitBuyForm tradableInCash={tradableInCash} />;
    case OrderType.STOP_LOSS:
      return (
        <TradeStopLossForm
          tradableInCash={tradableInCash}
          tradableUnits={tradableUnits}
          securitySymbol={asset?.symbol}
          currentPrice={asset?.market?.last}
        />
      );
    default:
      return null;
  }
};
