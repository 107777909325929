import React from 'react';
import Image from 'next/image';
import { IconButton } from '@mui/material';
import { ConfigType, ThemeType } from '@/config/utils';
import SvgHamburgerMenu from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/HamburgerMenu';
import cn from '@/utils/tailwind';

type MobileMenuProps = {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<boolean>;
  config: ConfigType;
  theme: ThemeType;
};

const MobileMenu: React.FC<MobileMenuProps> = ({
  config,
  theme,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) => {
  return (
    <div
      className="header sticky top-0 z-20 w-full border-b border-gray-100 bg-white px-4 py-8 text-center lg:hidden"
      data-mobile-menu
    >
      <div className="flex items-center justify-between">
        <div className={cn('relative flex-none', { hidden: isMobileMenuOpen })}>
          <IconButton
            className="rounded-none bg-transparent p-0 hover:bg-transparent"
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
          >
            <SvgHamburgerMenu width={20} height={20} color={theme.colors.primary} />
          </IconButton>
        </div>

        <div className="m-auto inline-block">
          {config.images.darkBrandLogoS.uri ? (
            config.images.mobileLogo && config.images.mobileLogo.uri ? (
              <Image
                src={config.images.mobileLogo.uri}
                alt="Logo"
                width={40 * (config.images.mobileLogo.logoRatio || 1)}
                height={40}
                className="inline"
              />
            ) : (
              <Image
                src={config.images.darkBrandLogoS.uri}
                alt="Logo"
                width={40 * (config.images.darkBrandLogoS.logoRatio || 1)}
                height={40}
                className="inline"
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
