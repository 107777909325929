import { Button } from '@/components/common/Button';
import { useAppStore } from '@/stores/app-store';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Link from 'next/link';

const displayStrings = {
  supportEmail: 'Support e-mail',
  contactUsPage: 'Contact us page',
  supportPhone: 'Support phone',
  back: 'Back',
};

const removeUrlProtocol = (url: string) => url.replace(/^https:\/\//, '');

type ContactsProps = {
  onBackClick: () => void;
};

export const Contacts = ({ onBackClick }: ContactsProps) => {
  const supportEmail = useAppStore((state) => state.tenantSupportEmail);
  const supportPage = useAppStore((state) => state.tenantSupportPage);
  const supportPhone = useAppStore((state) => state.tenantSupportPhone);

  return (
    <div className="h-full flex flex-col justify-between">
      <ul className="list-none space-y-4">
        <li className="flex justify-between">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.supportEmail}
          </WebBodyText14.Regular>
          <Link href={`mailto:${supportEmail}`}>
            <WebBodyText14.Regular className="underline text-primaryDarkBlue">
              {supportEmail}
            </WebBodyText14.Regular>
          </Link>
        </li>
        <li className="flex justify-between">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.contactUsPage}
          </WebBodyText14.Regular>
          {supportPage && (
            <Link href={supportPage} target="_blank" rel="noopener noreferrer">
              <WebBodyText14.Regular className="underline text-primaryDarkBlue">
                {removeUrlProtocol(supportPage)}
              </WebBodyText14.Regular>
            </Link>
          )}
        </li>
        <li className="flex justify-between">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.supportPhone}
          </WebBodyText14.Regular>
          <WebBodyText14.Bold className="text-grey84">{supportPhone}</WebBodyText14.Bold>
        </li>
      </ul>
      <Button onClick={onBackClick} className="w-full" variant="secondary" type="button">
        {displayStrings.back}
      </Button>
    </div>
  );
};
