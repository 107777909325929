import { V1UserRiskTolerance } from '@fintronners/react-api/src/tsoai';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

import robo from '@fintronners/react-language/src/Lang/pt.robo';
import errors from '@fintronners/react-language/src/Lang/pt.errors';
import settings from '@fintronners/react-language/src/Lang/pt.settings';
import widgets from './pt.widgets';
import gi from './pt.gi';

export default {
  common: {
    back: 'Voltar',
    continue: 'Continuar',
    loading: 'Carregando',
    getStarted: 'COMEÇAR',
    submit: 'ENVIAR',
    submitAndContinue: 'ENVIAR E CONTINUAR',
    home: 'INÍCIO',
    login: 'ENTRAR',
    signUp: 'Criar Conta',
    edit: 'Editar',
    delete: 'Excluir',
    privacyPolicyText: `Para mais informações, veja a nossa [Política de Privacidade.](${FinTronConfig.privacyPolicyUrl})`,
    exit: 'Sair da configuração',
    rememberMe: 'Lembrar usuário',
    forgotPassword: 'Esqueceu Usuário Ou Senha?',
    done: 'Concluído',
    exitTitle: 'Tem certeza de que deseja sair?',
    exitDescription:
      'Você está quase lá! Você sempre pode voltar e terminar sua inscrição mais tarde.',
    pendingChangesDesc:
      '*Novos valores foram submetidos, mas ainda não foram confirmados. Você ainda pode continuar a enviar alterações adicionais.',
    yes: 'Sim',
    no: 'Não',
    amount: 'Valor',
    totalAmount: 'Valor total',
    updating: 'Atualizando...',
    saveChanges: 'Salvar Alterações',
    cancel: 'Cancelar',
    warning: 'Aviso',
    yesCancel: 'Sim, cancelar',
    save: 'Salvar',
    apply: 'Aplicar',
    processing: 'Processando',
    deleteAll: 'Excluir todos',
    deleteSure: 'Tem certeza de que deseja excluir isso?',
    noSuggestionsFound: 'Nenhuma sugestão encontrada',
    other: 'Outro',
    dummyScreen: 'TELA DE TESTE',
    congratulations: 'Parabéns',
    document: 'Documento',
    date: 'Data',
    deposit: 'Depósito',
    instantDeposit: 'Depósito instantâneo',
    withdrawal: 'Resgate',
    trade: 'Trade',
    depositAmount: 'Valor do depósito instantâneo',
    withdrawAmount: 'Valor do resgate',
    submitBtnText: 'Enviar',
    enterAmount: 'Insira um valor (maior ou igual a ${{amount}})',
    ask: 'Venda: ',
    bid: 'Compra: ',
    notAvailable: 'N/D',
    by: 'Fonte',
    myHolding: 'Meu ativo',
    info: 'Informação',
    data: 'Dados',
    news: 'Notícias',
    performance: 'Desempenho',
    distributions: 'Distribuições',
    pastDistributions: 'Histórico de Distribuição',
    expensesAndFees: 'Taxas & Despesas',
    minimums: 'Mínimos',
    top10Holdings: 'Top 10 Ativos',
    settings: 'Configurações',
    documents: 'Documentos',
    accountStatement: 'Extrato da conta',
    accountNumber: 'Número da Conta',
    taxForm: 'Formulário Fiscal',
    tradeConfirm: 'Confirmação de Trade',
    statements: 'Extratos',
    tradeConfirms: 'Confirmações de Trade',
    profileInformation: 'Informações de Perfil',
    personalInformation: 'Informações Pessoais',
    profileSettings: 'Configurações de Perfil',
    success: 'Sucesso!',
    support: 'Suporte',
    search: 'Buscar',
    to: 'até',
    symbolOfCompany: 'Símbolo da empresa',
    selectDate: 'Selecione uma data',
    open: 'Aberto',
    cancelled: 'Cancelado',
    cancelling: 'Cancelando',
    pending: 'Pendente',
    completed: 'Concluído',
    returned: 'Retornado',
    received: 'Recebido',
    marketBuy: 'Compra a mercado',
    marketSell: 'Venda a mercado',
    buy: 'Compra',
    sell: 'Venda',
    transactionFee: 'Taxa de operação',
    shares: 'Ações',
    costBasis: 'Custo base',
    perShare: 'por ação',
    dividend: 'Dividendo',
    rollover: 'Roll-over',
    security: 'Ativo',
    automatedTypeUnknown: '{{type}} Agendado',
    automatedInvestment: 'Investimento Agendado',
    recurring: 'Transferência agendada',
    searchSecurity: 'Buscar ativo',
    status: 'Status',
    type: 'Tipo',
    symbol: 'Símbolo',
    custom: 'Outro',
    gainLoss: 'Ganho/Perda:  ',
    asOf: 'Atualizado às {{time}}',
    expand: 'Expandir',
    showMore: 'Ver mais',
    showLess: 'Ver menos',
    frequency: {
      weekly: 'Semanal',
      biWeekly: 'Quinzenal',
      monthly: 'Mensal',
    },
    duration: {
      day: '1D',
      tradingDay: '1D',
      extendedTradingDay: '1D',
      week: '1S',
      month: '1M',
      threeMonths: '3M',
      year: '1A',
      fiveYears: '5A',
      all: 'TUDO',
      oneYearLabel: '1 ano',
      threeYearLabel: '3 anos',
      fiveYearLabel: '5 anos',
      tenYearLabel: '10 anos',
    },
    mutualFunds: 'Mutual Funds',
    takePhoto: 'Tirar foto',
    chooseFromGallery: 'Escolher da galeria',
    chooseFromDocuments: 'Escolher de documentos',
    unknown: 'Desconhecido',
    clearAll: 'Limpar todos',
    filter: 'Filtrar',
    filterStocks: 'Filtrar Ações',
    filterEtfs: 'Filtrar ETFs',
    filterMutualFunds: 'Filtrar Fundos Mútuos',
    securityType: 'Tipo de ativo',
    securityCategory: {
      stocks: 'Ações',
      etfs: 'ETFs',
      mutualFunds: 'Fundos Mútuos',
    },
  },
  selfDirected: {
    single: {
      depositAmount: 'Valor de depósito',
      enterAmount: 'Insira o valor (entre ${{minimum}} e {{maximum}})',
    },
  },
  onboardingWelcome: {
    items: [
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image1.png'),
        title: 'Lorem Ipsum',
        description:
          'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.',
      },
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image2.png'),
        title: 'Lorem Ipsum',
        description:
          'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.',
      },
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image3.png'),
        title: 'Lorem Ipsum',
        description:
          'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.',
      },
    ],
    termsAndPrivacyPolicy: `Ao clicar em "Começar", você iniciará o processo de criação de um perfil com ${FinTronConfig.parentName} e concordará com nossos [Termos de Uso](${FinTronConfig.termsAndConditionsUrl}) e [Política de Privacidade.](${FinTronConfig.privacyPolicyUrl})`,
    extraFooterElement: '',
    accessibilityStatementTitle: '',
    accessibilityStatement: '',
  },
  onboardingAccountVerification: {
    title: 'Confirme o seu e-mail e o seu número de celular.',
    subtitle:
      'Confirme seu e-mail e número de telefone para agilizar o processo de abertura da sua conta.',
    profileNotFound: 'Perfil não encontrado',
    profileNotFoundDescription: `As informações fornecidas não estão associadas a uma conta do ${FinTronConfig.parentName}.`,
    profileFound: 'Perfil encontrado',
    profileFoundDescription: `Ótima notícia! As informações fornecidas estão associadas a uma conta do ${FinTronConfig.parentName}. Você está a poucos minutos de abrir sua conta de investimento.`,
  },
  onboardingPrefill: {
    title: 'Respostas pré-preenchidas',
    description: `*Eu reconheço que as informações pré-preenchidas abaixo foram fornecidas por mim durante o processo de abertura de conta no Bradesco Bank, e entendo que essas informações serão utilizadas para abrir minha conta na Bradesco Investments Inc. \n\nAo continuar o processo de abertura de conta na Bradesco Investments Inc., certifico que as informações abaixo são precisas e corretas. Se as informações abaixo não forem mais precisas, não continuarei com o processo de integração e atualizarei minhas informações enviando um e-mail para ${FinTronConfig.selfDirected.supportEmail}`,
  },
  onboardingUsernamePassword: {
    title: 'Vamos manter sua conta segura.',
    subtitle: 'Por razões de segurança, não reutilize senhas em vários sites ou perfis.',
    username: 'Usuário',
    password: 'Senha',
    confirmPassword: 'Redigite sua senha',
  },
  onboardingBasicInfo: {
    title: 'Vamos começar com algumas informações básicas.',
    subtitle: `A ${FinTronConfig.selfDirected.name} precisa dessas informações para abrir a sua conta.`,
    firstName: 'Nome',
    firstNameHelpText: '',
    lastName: 'Sobrenome Completo',
    middleName: 'Nome do meio',
    email: 'E-mail',
    dateOfBirth: 'Data de nascimento',
    phoneNumber: 'Número de celular',
    checkbox: '',
  },
  onboardingVerification: {
    title: 'Confirme o seu número de celular e e-mail.',
    subtitle: 'Por motivos de segurança, verifique seu número de telefone e e-mail.',
    email: 'E-mail',
    phoneNumber: 'Número de celular',
    verify: 'Verificar',
  },
  onboardingAddress: {
    title: 'Qual é o seu endereço residencial?',
    subtitle: `A ${FinTronConfig.selfDirected.name} precisa dessas informações para abrir a sua conta.`,
    streetAddress: 'Endereço 1',
    additionalStreetAddress: 'Apartamento, suíte, unidade, prédio, andar, etc.',
    city: 'Cidade',
    state: 'Estado',
    postalCode: 'CEP',
    country: 'País',
    footer: '',
  },
  onboardingCitizenship: {
    title: 'Residência Fiscal.',
    subtitle:
      'Confirme que você é um **Não-Residente** e **não tem residência fiscal nos Estados Unidos** ou em territórios americanos.',
    notCitizenTitle: '',
    notCitizenSubtitle: '',
    usCitizen: '',
    greenCard: '',
    visa: '',
    noneApplicable: '',
    nonResident: 'Não Residente',
    yourStatus: 'Seu status{{pendingMark}}:',
  },
  onboardingPlaceOfBirth: {
    title: 'Local de nascimento.',
    subtitle: 'Selecione seu local de nascimento:',
    label: 'Local de nascimento',
  },
  onboardingVisa: {
    title: 'Tipo de visto.',
    subtitle: 'Selecione seu tipo de visto:',
    visaExpirationTitle: 'Data de expiração do visto.',
    visaExpirationSubtitle: 'Insira a data de expiração do seu visto:',
    placeholder: 'Mês / Dia / Ano',
  },
  onboardingSocial: {
    title: 'Verifique a sua identidade.',
    subtitle: 'Confirme seu CPF para abrir a sua conta.',
    checklist: [
      {
        title: 'Criptografia de 256 bits',
        name: 'Utiliza uma chave de 256 bits para criptografar e descriptografar dados ou arquivos. É utilizada na maioria dos algoritmos, protocolos e tecnologias de criptografia modernos.',
      },
    ],
    personalIdNumber: 'CPF',
  },
  onboardingLicense: {
    title: 'Verifique sua identidade com sua carteira de motorista ou passaporte.',
    subtitle: `${FinTronConfig.parentName} é obrigado a coletar essas informações para criar seu perfil.`,
    maxSizeTitle: 'Falha no upload!',
    maxSizeDescription:
      'O tamanho do seu arquivo é muito grande, por favor tente fazer o upload de um menor!',
    driverLicense: 'Carteira de Motorista',
    passport: 'Passaporte',
    uploadFront: 'Carregar Frente',
    uploadBack: 'Carregar Verso',
  },
  onboardingOtherSourceOfWealth: {
    title: 'Outra fonte de renda',
    subtitle:
      'Descreva sua outra fonte de renda aqui. Isso diverge da renda recebida através do seu trabalho.',
    label: 'Outra fonte de renda',
    hasOtherSourcesOfWealth: 'Eu não possuo outra fonte de renda.',
    options: {
      accumulatedSavings: 'Economias Acumuladas',
      alimony: 'Pensão Alimentícia',
      earningsIncome: 'Renda de Ganhos',
      inheritance: 'Herança',
      insuranceProceeds: 'Proventos de Seguro',
      investmentIncome: 'Proventos de Investimento',
      legalAgreement: 'Acordo Legal',
      lotteryWinnings: 'Loteria',
      pensionRetirement: 'Pensão/Aposentadoria',
      savingsAccounts: 'Economias (Contas de Investimentos)',
      rentalIncome: 'Renda de Aluguel',
      businessSale: 'Venda de Negócio',
      realEstateSale: 'Venda de Imóveis',
      familySupport: 'Cônjuge/Pai/Mãe',
      otherSource: 'Outra Fonte de Renda',
    },
  },
  onboardingCompliance: {
    title: 'Selecione todos que se aplicam a você.',
    questions: {
      isControlPerson:
        'Eu sou um acionista controlador ou tenho controle sobre uma empresa de capital aberto.',
      isControlPersonDescriptionTitle: 'O que é um Controlador?',
      isControlPersonDescription:
        'Um controlador pode ser definido como um diretor, executivo ou outro afiliado de uma empresa de capital aberto, que tem controle sobre a gestão ou políticas. Também pode ser um acionista que possui 10% ou mais de qualquer classe de valores mobiliários com direito a voto de uma corporação.',
      isPoliticallyExposed: 'Eu sou uma pessoa politicamente exposta.',
      isPoliticallyExposedDescriptionTitle: 'Exposição Política',
      isPoliticallyExposedDescription:
        'Uma pessoa exposta politicamente (PEP) é um indivíduo com um papel político de alto perfil ou que tenha sido encarregado de uma função pública proeminente. As PEPs representam um risco maior para instituições financeiras e Atividades e Profissões Não-Financeiras Designadas (APNFDs).',
      isAffiliatedExchangeOrFINRA:
        'Eu sou membro ou associado à alguém que seja afiliado ou funcionário de uma Bolsa de Valores ou FINRA.',
      isAffiliatedExchangeOrFINRADescriptionTitle: 'Trabalhador da FINRA ou Bolsa de Valores',
      isAffiliatedExchangeOrFINRADescription:
        'Você ou algum membro de sua família imediata é empregado ou afiliado a uma bolsa de valores (NASDAQ, Bolsa de Valores de Nova York, etc.) ou à Autoridade Reguladora do Setor Financeiro (FINRA)?',
      isSecuritiesProfessional: 'Eu sou um profissional de valores mobiliários.',
      isSecuritiesProfessionalDescriptionTitle: 'Profissional de Valores Mobiliários',
      isSecuritiesProfessionalDescription:
        'Um profissional de valores mobiliários seria um dos seguintes:\n\nEstá registrado ou qualificado junto à: Comissão de Valores Mobiliários, Comissão de Negociação de Futuros de Commodities, qualquer agência estadual de valores mobiliários, qualquer associação de bolsas, ou qualquer mercado ou associação de futuros de commodities. \n\nAtua como "consultor de investimentos" conforme definido na seção 201 (11) da Lei de Consultores de Investimentos de 1940 (esteja ou não registrado ou qualificado sob essa Lei) nos Estados Unidos. \n\nEstá empregado por um banco ou outra organização isenta de registro sob as leis federais e/ou estaduais dos Estados Unidos, relacionadas a valores mobiliários, para exercer funções ou qualificar-se para exercê-las, caso estivesse atuando em uma organização não isenta.',
    },
  },
  onboardingIsControlPerson: {
    title: 'Informação do controlador.',
    symbolOfCompany: 'Código da empresa',
    description: 'Insira o(s) código/ticker(s) da(s) empresa(s) das quais você é um controlador.',
    addACompany: '+ Adicione uma empresa',
  },
  onboardingIsPoliticallyExposed: {
    title: 'Informação de pessoa politicamente exposta.',
    description: 'Insira a organização política à qual você está afiliado.',
    politicalOrganizationNameTitle:
      'Por favor, insira a organização política com a qual você é afiliado.',
    settingsTitle: 'Você é uma pessoa politicamente exposta?',
    isPoliticallyExposed: 'Eu sou politicamente exposta.',
    namePoliticalOrganization: 'Nome da organização política',
    familyMemberName: 'Nome completo de seus familiares',
    familyMembersFullNameTitle: 'Por favor, insira os nomes de seus familiares imediatos.',
    addFamilyMember: '+ Adc Membro Familiar',
    politicalOrganizationName: 'Nome da organização política',
    familyMembersFullName: 'Nome completo de seus familiares',
  },
  onboardingIsFinra: {
    title: 'Informações da empresa empregadora.',
    form: {
      title: 'Nome do seu empregador.',
      label: 'Nome',
      letterTitle: 'Insira o seu 3210 Letter.',
      letterButtonText: 'Upload da 3210 Letter',
      letterDescription: `Faça o upload de uma imagem da sua 3210 letter do seu empregador afiliado à FINRA, autorizando você a criar uma conta de corretagem com a ${FinTronConfig.selfDirected.name}.`,
      maxSizeTitle: 'Falha no upload!',
      maxSizeDescription:
        'O tamanho do seu arquivo é muito grande, por favor tente fazer o upload de um menor!',
      companyEmployeerTitle: `Eu trabalho na ${FinTronConfig.parentName}.`,
    },
  },
  onboardingSecuritiesProfessional: {
    title: 'Perguntas sobre dados de mercado.',
    subtitle: 'Selecione todos que se aplicam a você.',
    questions: {
      firstQuestionTitle: 'Pretendo utilizar os dados de mercado para além do uso pessoal?',
      firstQuestionInfoTitle: 'Uso Pessoal',
      firstQuestionInfoDescription:
        'Questiona se você pretende utilizar os dados do mercado de ações fornecidos nesta aplicação exclusivamente para investimento e pesquisa na plataforma Bradesco Investments.',
      secondQuestionTitle:
        'Recebo dados do mercado de ações de outro provedor no meu local de trabalho ou em outro lugar?',
      thirdQuestionTitle: 'Estou registrado ou qualificado junto à SEC ou à CFTC?',
      fourthQuestionTitle:
        'Desempenho funções que exigiriam meu registro junto à SEC e/ou à CFTC ou órgãos reguladores similares?',
      fourthQuestionInfoTitle: 'Registro de órgão regulador',
      fourthQuestionInfoDescription:
        'Você está envolvido em alguma atividade relacionada a valores mobiliários (ações)? Se não, selecione não para esta questão.',
      fifthQuestionTitle: 'Eu invisto com o dinheiro de outra pessoa?',
      sixthQuestionTitle: 'Invisto em benefício de outra pessoa/entidade?',
      seventhQuestionTitle:
        'Sou consultor de investimentos, gestor de ativos ou consultor financeiro?',
      seventhQuestionInfoTitle:
        'Consultor de investimentos, gestor de ativos ou consultor financeiro',
      seventhQuestionInfoDescription:
        'Se você negocia em benefício próprio, selecione não para esta questão.',
      eighthQuestionTitle:
        'Entrei em um acordo para compartilhar os lucros das minhas atividades de investimento e/ou receber compensação por negociações?',
      ninthQuestionTitle:
        'Atualmente estou registrado junto a uma agência de valores mobiliários, bolsa de valores ou entidade de commodities/futuros?',
    },
  },
  onboardingSelectAccountType: {
    title: 'Confirme o tipo de conta que gostaria de abrir.',
    stockAccountTypeTitle: 'Construa o seu próprio portfólio.',
    stockAccountTypeDescription: `Abra uma conta de investimentos com a ${FinTronConfig.selfDirected.name} e escolha ativos que se adequem ao seu estilo de vida.`,
    stockAccountTypeCardTitle: 'Investimentos',
    stockAccountTypeCardDescription: 'Selecione ativos que se adequem ao seu estilo de vida.',
    generalInvestingAccountTypeCardTitle: 'Investimento Geral',
    generalInvestingAccountTypeCardDescription: 'Não tem um objetivo em mente, comece aqui.',
    roboAccountTypeTitle: 'Abra uma conta Robo',
    roboAccountTypeDescription: `Quer você queira investir para o futuro ou criar um objetivo específico, deixe ${FinTronConfig.robo.name} ajudá-lo a chegar lá.`,
    roboAccountTypeCardTitle: 'Criar Um Objetivo',
    roboAccountTypeCardDescription: 'Invista para um objetivo específico.',
    retirementAccountTypeCardTitle: 'Aposentadoria',
    retirementAccountTypeCardDescription: 'Invista para a aposentadoria.',
  },
  onboardingStocksLanding: {
    title: 'Investimentos',
    subtitle: `Abra uma conta de investimentos com a ${FinTronConfig.selfDirected.name} e escolha ativos que se adequem ao seu estilo de vida.`,
    legal: `Ao clicar em “Continuar” você inicia o processo de abertura de Conta Individual de Corretora na ${FinTronConfig.selfDirected.name} e concorda com nossos [Termos de Uso](${FinTronConfig.termsAndConditionsUrl}) e [Política de Privacidade](${FinTronConfig.privacyPolicyUrl}).`,
    checklist: [
      { name: 'Abertura de conta grátis', bold: false },
      { name: 'Investimentos a partir de US$100', bold: false },
      { name: '100% Online', bold: false },
    ],
    bodyText: '',
    footer: '',
  },
  onboardingStocks: {
    firstSurvey: {
      title: 'Vamos começar com o básico.',
      subtitle: 'Para abrir sua conta, responda só mais algumas perguntas regulatórias.',
      experienceLevelQuestion: 'Qual é o seu nível de experiência com investimentos?',
      experienceLevelAnswers: {
        none: 'Nenhum',
        limited: 'Limitado',
        good: 'Bom',
        extensive: 'Extenso',
      },
      investmentObjectiveQuestion: 'Qual é o seu objetivo de investimento?',
      investmentObjectiveAnswers: {
        capitalpreservation: 'Preservação de Capital',
        income: 'Renda',
        growth: 'Crescimento',
        speculation: 'Especulação',
        other: 'Outro',
      },
      investmentObjectiveInfoTitle: 'Objetivos de investimento',
      investmentObjectiveInfoDescription:
        '**Preservação de capital**: Tem como objetivo viabilizar o menor risco possível para o seu dinheiro.\n\n**Renda**: Tem como objetivo investir em títulos que geram dividendos acima da média e valorização do capital a longo prazo. (Dividendos são pagamentos trimestrais de lucros aos acionistas.)\n\n**Crescimento**: Tem como objetivo investir em títulos que tem valorização de capital a curto prazo (o preço das ações sobe em um curto período de tempo). Este objetivo de investimento é geralmente mais arriscado do que os objetivos de preservação e renda.\n\n**Especulação**: Esta objetivo se refere ao ato de comprar um ativo que tem um risco substancial de perder valor, mas também carrega a expectativa de aumentar significativamente de valor. Este é, de longe, o objetivo mais arriscado.',
      investmentHorizonQuestion: 'Qual é o seu horizonte de investimento?',
      investmentHorizonAnswers: {
        short: 'Curto',
        average: 'Médio',
        longest: 'Longo',
      },
      investmentHorizonInfoTitle: 'Horizonte de investimento',
      investmentHorizonInfoDescription:
        '**Curto**: menos de 2 anos.\n\n**Médio**: entre 2 e 8 anos.\n\n**Longo**: mais de 8 anos.',
      liquidityNeedsQuestion: 'Quão importante é ter dinheiro em mãos?',
      liquidityNeedsAnswers: {
        notimportant: 'Não é importante',
        veryimportant: 'Muito Importante',
        somewhatimportant: 'Relativamente importante',
      },
      liquidityNeedsInfoTitle: 'Necessidade de Liquidez',
      liquidityNeedsInfoDescription:
        'A capacidade de converter rápida e facilmente toda ou parte dos ativos da conta para dinheiro sem sofrer perdas significativas de valor.',
    },
    secondSurvey: {
      title: 'Questões Pessoais',
      maritalStatusQuestion: 'Você é casado?',
      maritalStatusAnswers: {
        married: 'Casado',
        single: 'Solteiro',
        divorced: 'Divorciado',
        widowed: 'Viúvo',
      },
      employmentStatusQuestion: 'Qual é o seu status de emprego atual?',
      employmentStatusAnswers: {
        employed: 'Empregado',
        unemployed: 'Desempregado',
        retired: 'Aposentado',
        student: 'Estudante',
        selfemployed: 'Autônomo',
      },
      employerNameTitle: 'Qual é o nome do seu empregador?',
      employerName: 'Nome do empregador',
      positionTitle: 'Qual é a sua posição?',
      position: 'Sua posição?',
      annualIncomeRangeQuestion: 'Qual é o seu salário anual?*',
      annualIncomeRangeFirstAnswer: 'Menor ou igual a US$25,000',
      annualIncomeRangeSecondAnswer: 'US$25,001-US$50,000',
      annualIncomeRangeThirdAnswer: 'US$50,001-US$100,000',
      annualIncomeRangeFourthAnswer: 'US$100,001-US$200,000',
      annualIncomeRangeFifthAnswer: 'US$200,001-US$300,000',
      annualIncomeRangeSixthAnswer: 'US$300,001-US$500,000',
      annualIncomeRangeSeventhAnswer: 'US$500,001-US$1,200,000',
      annualIncomeRangeEighthAnswer: 'US$1,200,001-US$9,999,999',
      annualIncomeRangeInfoTitle: 'O que é renda anual',
      netWorthRangeQuestion: 'Qual é o seu patrimônio líquido?',
      netWorthRangeFirstAnswer: 'Menos De US$50,000',
      netWorthRangeSecondAnswer: 'US$50,001-US$100,000',
      netWorthRangeThirdAnswer: 'US$100,001-US$200,000',
      netWorthRangeFourthAnswer: 'US$200,001-US$500,000',
      netWorthRangeFifthAnswer: 'US$500,001-US$1,000,000',
      netWorthRangeSixthAnswer: 'US$1,000,001-US$5,000,000',
      netWorthRangeSeventhAnswer: 'US$5,000,001-US$9,999,999',
      netWorthRangeEighthAnswer: 'Menor ou igual a US$25,000',
      netWorthRangeNinthAnswer: 'US$25,001-US$50,000',
      netWorthRangeTenthAnswer: 'US$200,001-US$300,000',
      netWorthRangeEleventhAnswer: 'US$300,001-US$500,000',
      netWorthRangeInfoDescription:
        'Patrimônio líquido é a quantidade de dinheiro que você tem prontamente disponível para gastar ou investir, excluindo ativos que não podem ser rapidamente convertidos em dinheiro sem perder valor. Inclui itens como dinheiro, contas poupança, ações e títulos - ativos que podem ser facilmente vendidos ou acessados.\n\nImportante, possuir uma casa não é considerado parte do seu patrimônio líquido. Isso porque uma casa não pode ser vendida rapidamente sem esforço significativo. Mesmo que sua casa possa ter valor, esse valor não está imediatamente disponível para emergências ou fins de investimento.',
    },
  },
  commonRiskTolerance: {
    title: 'Escolha sua tolerância ao risco.',
    change: 'Escolha sua tolerância ao risco.',
    riskTolerance: 'Tolerância ao risco',
    subtitleStocks:
      'Essa etapa é necessária para entendermos seu objetivo de investimento e definir adequadamente seu perfil de investidor.',
    subtitleGoals:
      'Os três possíveis perfis de risco determinam a quantidade máxima de exposição a ações em sua carteira a qualquer momento. Alterar esta configuração mudará a quantidade máxima de ações permitida, conforme descrito abaixo. Geralmente, escolher um perfil agressivo resultará em uma alocação de ativos com maior exposição a ações para atender à maior probabilidade de atingir seu objetivo. Isso pode potencialmente criar níveis mais altos de risco e recompensa.',
    stocksData: {
      [V1UserRiskTolerance.Low]:
        'Investidores com tolerância ao risco conservadora podem priorizar a segurança do principal do investimento sobre o potencial de retornos mais altos.',
      [V1UserRiskTolerance.Medium]:
        'Investidores moderados se sentem confortáveis com flutuações moderadas do mercado e preferem investimentos com nível de risco moderado.',
      [V1UserRiskTolerance.High]:
        'Investidores arrojados priorizam o potencial de retornos mais altos sobre a segurança do capital investido.',
    },
    roboData: {
      [V1UserRiskTolerance.Low]: `Um perfil de risco conservador permite exposição a ações de até ${
        (FinTronConfig.robo.goals?.lowRiskPercentage ?? 0) * 100
      }%.`,
      [V1UserRiskTolerance.Medium]: `Um perfil de risco moderado permite exposição a ações de até ${
        (FinTronConfig.robo.goals?.mediumRiskPercentage ?? 0) * 100
      }%.`,
      [V1UserRiskTolerance.High]: `Um perfil de risco arrojado permite exposição a ações de até ${
        (FinTronConfig.robo?.goals?.highRiskPercentage ?? 0) * 100
      }%.`,
    },
    blurb: {
      title: 'Perfil de Risco',
      [V1UserRiskTolerance.Low]:
        'Um **investidor conservador** valoriza mais a estabilidade do que retornos mais altos e prefere menos risco e potencial de queda.',
      [V1UserRiskTolerance.Medium]:
        'Um **investidor moderado** valoriza uma abordagem equilibrada para o crescimento do capital com preservação do capital e aceita algum risco.',
      [V1UserRiskTolerance.High]:
        'Um **investidor agressivo** valoriza mais retornos mais altos do que estabilidade, e está disposto a aceitar maior risco e maior potencial de queda.',
      footer:
        'Você receberá recomendações de investimento que se alinham com seu perfil de risco escolhido para progredir em direção ao valor total de seu objetivo dentro do prazo desejado.',
    },
    surveyTitle: 'Qual é o seu perfil de risco?',
  },
  onboardingProfileConfirmation: {
    title: 'Seu perfil está salvo! Agora, vamos começar a sua jornada de investimentos.',
    subtitle:
      'A seguir, você selecionará o tipo de conta que deseja criar. A abertura de uma conta leva menos de 10 minutos.',
    confirmButton: 'Continuar',
  },
  onboardingAgreement: {
    title: 'Pronto! Agora só falta um passo para a abertura da sua conta.',
    stockSubtitle:
      'Leia os documentos abaixo antes de enviar a sua solicitação de abertura de conta.',
    subtitle: 'Leia os documentos abaixo antes de enviar a sua solicitação de abertura de conta.',
    stocksFirstButton: 'Ver Customer Agreement',
    agreeBsmButton: 'Eu Li e Concordo',
    agreeBsmFooter: 'Você deve rolar até o final do contrato para concordar.',
    stocksSecondButton: 'Ver Customer Relationship Summary',
    stocksCheckboxes: [
      {
        questionKey: 'apex_paid_security_lending',
        question: `Inscreva-se no programa de Fully Paid Securities Lending da Apex (opcional). Veja o [Apex Master Securities Lending Agreement](https://apexfintechsolutions.com/wp-content/uploads/2023/10/FPSL-Master-Securities-Lending-Agreement_June-2023.pdf) e [Securities Lending Disclosures]((https://apexfintechsolutions.com/wp-content/uploads/2023/10/FPSL-Risk-Disclosure_April-2023.pdf)).`,
      },
      {
        questionKey: 'apex_cash_sweep_service',
        question:
          'Inscreva-se no programa de Cash Sweep da Apex (opcional). Veja os termos para mais informações [Apex Cash Sweep Program Terms](https://apexfintechsolutions.com/wp-content/uploads/2022/05/Apex-FDIC-Sweep-Program-Terms-and-Conditions-1.pdf).',
      },
    ],
    roboCheckboxes: [
      {
        questionKey: '',
        question: '',
      },
    ],
  },
  onboardingYouAreAllSet: {
    title: `Sua solicitação foi enviada!`,
    roboSubtitle: `Parabéns, você enviou sua inscrição para a conta Robo ${FinTronConfig.robo.name}.\n\nAssim que sua conta for aprovada, seu investimento inicial será transferido automaticamente para seu objetivo e faremos os investimentos.`,
    stockSubtitle: `Você ja pode explorar o app enquanto processamos a sua solicitação.\n\nEnviamos um email de verificação. Complete essa etapa e faça login no nosso app.`,
    checkListTitle: 'Agora que você completou sua inscrição, terá acesso ao seguinte!',
    goalCreatedTitle: 'Objetivo Criado',
    goalCreatedDescription:
      'Parabéns! Você criou com sucesso um novo objetivo. Veja os detalhes e o histórico do seu objetivo nesta página.',
  },
  forgotPassword: {
    title: 'Esqueceu a Senha',
    changePassword: 'Atualizar senha',
    description:
      'Insira o nome de usuário associado à sua conta e enviaremos um código de redefinição de senha por e-mail.',
    label: 'Usuário',
  },
  onboardingApexExtendedQuestions: {
    title: 'Informações Pessoais',
    footer: '',
  },
  resetPassword: {
    title: 'Nova Senha',
    description: 'Por favor, redefina sua senha abaixo.',
    codeLabel: 'Código de Senha',
    newPasswordLabel: 'Digite a Nova Senha',
    confirmPasswordLabel: 'Confirme a Nova Senha',
    helpText:
      'A senha deve conter 8 caracteres. Recomendamos fortemente criar uma senha com uma combinação de letras, números e caracteres especiais (! @ # $...).',
    codeHelpText:
      'Enviamos um código para você por e-mail. Por favor, insira-o acima para continuar com a redefinição da sua senha.',
    passwordSuccessSummary: 'Sua senha foi redefinida.',
  },

  settingsVerification: {
    title: 'Verificação de Perfil',
    subtitle: 'Por favor, verifique seu e-mail e número de telefone.',
  },
  riskTolerance: {
    [V1UserRiskTolerance.High]: 'Arrojado',
    [V1UserRiskTolerance.Low]: 'Conservador',
    [V1UserRiskTolerance.Medium]: 'Moderado',
  },
  errors,
  settingsApexClearingPrograms: {
    title: 'Programas de Compensação Apex',
    fpslProgram: 'Programa de Empréstimo de Títulos Totalmente Pagos (FPSL)',
    fpslDesc: `Você deseja se inscrever no Programa de Empréstimo de Títulos Totalmente Pagos da Apex? Veja o [Acordo Mestre de Empréstimo de Títulos da Apex](${FinTronConfig.apexUrls.apexMasterSecuritiesLendingAgreement}) e [Divulgações de Empréstimo de Títulos](${FinTronConfig.apexUrls.securitiesLendingDisclosure}).`,
    cashSweepProgram: 'Programa de Varredura de Caixa',
    cashSweepDesc: `Você deseja se inscrever no programa de Varredura de Caixa da Apex? Veja os [Termos do Programa de Varredura de Caixa da Apex](${FinTronConfig.apexUrls.apexCashSweep}) para mais informações.`,
    contactSupport: 'Contatar Suporte',
    contactSupportDesc: `Para optar pelo programa FPSL, por favor, contate [${FinTronConfig.selfDirected.supportEmail}](${FinTronConfig.selfDirected.supportEmail}) declarando isso.`,
    pendingChanges: 'Alterações pendentes',
    pendingChangesDesc:
      '*Novos valores foram enviados, mas ainda não confirmados, você ainda pode continuar a enviar alterações adicionais.',
    optOut: 'Cancelar participação',
    optOutDesc: `Você está cancelando a participação no programa FPSL. Será necessário entrar em contato com o suporte para optar por participar novamente.\n\nTem certeza de que deseja cancelar a participação?`,
  },
  support: {
    account: 'Minha Conta Investimentos',
  },
  accounts: {
    active: {
      accountHistory: 'Histórico da Conta',
      trustedContact: 'Contato de Confiança',
      documents: 'Documentos',
      investmentQuestions: 'Questionário do Perfil do Investidor',
      apexClearingPrograms: 'APEX Clearing Programs',
      closeAccount: 'Encerrar Conta',
    },
    settings: {
      title: 'Configurações da Conta',
      profileSettings: 'Configurações de Perfil',
      profileInformation: 'Informação de Perfil',
      support: 'Suporte',
      security: 'Configurações de Segurança',
      bankLink: 'Conta Vinculada',
      logout: 'Sair',
    },
    open: 'Abrir uma conta {{ account }}',
    pending: 'Conta {{ account }} pendente',
    accountPendingTitle: 'Status da conta em revisão',
    accountPendingDescription: `Estamos comprometidos em manter os mais altos padrões de segurança e conformidade. Enquanto monitoramos o status da sua conta, você pode ter acesso limitado a alguns recursos.\n\nPor favor, entre em contato com o suporte em [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}) para mais informações.`,
    accountCanOpenTitle: 'Abrir Uma Conta',
    accountCanOpenDescription: `Vemos que você criou um perfil. Você pode criar uma conta para acessar os recursos de corretagem do ${FinTronConfig.parentName}.\n\nSe tiver algum problema, entre em contato com [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
    accountCanOpenButton: 'Abrir Uma Conta',
    accountClosedTitle: 'Sua conta foi fechada',
    accountClosedDescription: `Sua conta foi fechada. Você não pode mais realizar ações associadas à sua conta, mas pode visualizar seus documentos, como extratos mensais e formulários fiscais.\n\nPara reabrir esta conta, entre em contato com [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
    stocks: {
      providedBy: `Por ${FinTronConfig.selfDirected.name}`,
      pendingTitle: `Aplicação de conta ${FinTronConfig.selfDirected.name} pendente`,
      pendingDescription: `Sua aplicação foi enviada e está sendo processada atualmente. Por favor, verifique seu e-mail para atualizações adicionais.\n\nEntre em contato com [${FinTronConfig.selfDirected.supportEmail}](mailto:${FinTronConfig.selfDirected.supportEmail}) para mais informações ou se tiver perguntas adicionais sobre sua aplicação.`,
    },
    robo: {
      providedBy: `Por ${FinTronConfig.robo.name}`,
      pendingTitle: `Aplicação ${FinTronConfig.robo.name} pendente`,
      pendingDescription: `Sua aplicação foi enviada e está sendo processada atualmente. Por favor, verifique seu e-mail para atualizações adicionais.\n\nEntre em contato com [${FinTronConfig.robo.supportEmail}](mailto:${FinTronConfig.robo.supportEmail}) para mais informações ou se tiver perguntas adicionais sobre sua aplicação.`,
      closeAccountTitle: 'Tem certeza que deseja encerrar a sua conta?',
      closeAccountDescription:
        'Para encerrar sua conta de investimentos ({{ accountNumber }}), você deve primeiro certificar-se de:\n\u00A0\u00A01. Cancelar quaisquer transferências automatizadas.\n\u00A0\u00A02. Caso você possua títulos, vender todos os títulos; então,\n\u00A0\u00A03. Retirar todos os fundos restantes da conta.\n\nQuando estas ações estiverem concluídas, você poderá encerrar esta conta.',
      closeAccountExtraDescription: '',
      confirm: 'Sim, Encerrar conta',
    },
  },
  trustedContact: {
    title: 'Contato de confiança.',
    subtitle:
      'Ao escolher fornecer informações sobre uma contato de confiançal, você nos autoriza a contatar o contato listado abaixo e divulgar informações sobre sua conta a essa pessoa nas seguintes circunstâncias: para abordar possível exploração financeira, para confirmar as especificidades de suas informações de contato atuais, estado de saúde ou a identidade de qualquer tutor legal, executor, administrador ou detentor de uma procuração, ou conforme permitido pela Regra 2165 da FINRA (Exploração Financeira de Adultos Especificados).',
    question: 'Você deseja adicionar um contato de confiança a sua conta?',
    learnMore: 'Saiba mais sobre contatos de confiança',
    learnMoreTitle: 'Contato de confiança',
    learnMoreDescription:
      'Um contato de confiança pode atuar como uma camada extra de segurança para sua conta. Essa pessoa será alguém com quem podemos entrar em contato em caso de preocupações sobre seu bem-estar ou segurança da conta. Embora seja totalmente opcional, ter um Contato de Confiança pode nos ajudar a agir rapidamente em situações de emergência e proteger seus interesses.',
    legal: '',
    confirmEmailDescription: 'Por favor, confirme o e-mail do seu contato de confiança.',
    updatedSuccess: 'Contato de confiança atualizado com sucesso.',
    removedSuccess: 'Contato de confiança removido com sucesso.',
    updatedError: 'Erro ao atualizar o contato de confiança. Por favor, tente novamente.',
    yes: 'Sim',
    no: 'Não',
  },
  settingsAddress: {
    title: 'Endereço Residencial',
    footer: '',
  },
  profileInformation: {
    updateInfo: {
      title: 'Atualizar informações',
      description: 'A atualização das informações será aplicada a todas as contas abertas.',
      confirm: 'Sim, atualizar',
    },
  },
  existingGoal: {
    allocation: 'Alocação',
    projection: 'Projeção',
    progress: 'Progresso',
    progressBsmTitle: 'Progresso do objetivo',
    progressBsmDescription:
      'O progresso do objetivo calcula o valor atual do seu objetivo como uma porcentagem do valor alvo. Não deve ser confundido com a probabilidade do objetivo, que estima a possibilidade de atingir o valor total do seu objetivo dentro do prazo desejado. Veja a probabilidade do objetivo para mais informações.',
    editValues: 'Editar valores',
    lessons: 'Lições',
    accountHistory: 'Histórico da conta',
    footer: `Serviços de consultoria são fornecidos por ${FinTronConfig.robo.name}, um consultor de investimentos registrado na SEC. Serviços de corretagem oferecidos a clientes autodirecionados por ${FinTronConfig.selfDirected.name}. Dinheiro e títulos são custodiados por ${FinTronConfig.clearingFirm}.`,
  },
  roboEtf: {
    stockDetail: {
      seeMore: 'Ver mais',
      seeLess: 'Ver menos',
      marketPrice: 'Preço de mercado:',
      day30SecYield: 'Rendimento de 30 dias SEC:',
      navPrice: 'Preço NAV:',
      expenseRatio: 'Taxa de despesa:',
      yourPosition: 'Sua posição',
      marketValue: 'Valor de mercado:',
      shares: 'Ações:',
      secBsmTitle: 'Rendimento SEC',
      secBsmMessage:
        'O rendimento SEC de um fundo não monetário é baseado em uma fórmula desenvolvida pela SEC. O método calcula a renda anualizada hipotética de um fundo como uma porcentagem de seus ativos.\n\nA renda de um título, para fins deste cálculo, é baseada no rendimento atual de mercado até o vencimento (para títulos) ou rendimento de dividendos projetado (para ações) das participações do fundo ao longo de um período de 30 dias. Esta renda hipotética diferirá (às vezes, significativamente) da experiência real do fundo. Como resultado, as distribuições de renda do fundo podem ser maiores ou menores do que o implícito pelo rendimento SEC.\n\nO rendimento SEC para um fundo do mercado monetário é calculado anualizando suas distribuições de renda diária para os 7 dias anteriores.',
      expenseBsmTitle: 'Índice de despesas',
      expenseBsmMessage: `Expresso como uma porcentagem, os índices de despesas são taxas cobradas dos investidores para cobrir os custos operacionais de um fundo ou ETF. Eles são deduzidos das distribuições de dividendos e ganhos de capital, não do principal.`,
      navBsmTitle: 'Valor líquido dos ativos "NAV"',
      navBsmMessage: `NAV é o valor de cada ação em um fundo de investimento, calculado com base nos ativos e passivos do fundo. Ajuda os investidores a entender o valor de seu investimento. No entanto, o valor de mercado das ações do fundo pode diferir do NAV devido a fatores como oferta e demanda.`,
    },
  },
  allocation: {
    subtitle: 'Investimento inicial',
    subtitle2: 'Valor da conta',
    initialAllocation: 'Alocação inicial',
    initialAllocationDesc:
      'Esta é sua alocação proposta do seu investimento inicial. Nosso mecanismo de aconselhamento usa as entradas que você fornece para projetar um portfólio para ajudá-lo a alcançar seu objetivo.',
    title: 'Alocação',
    allocationDesc: `A alocação de ativos recomendada é a mais apropriada com base nas informações fornecidas por você. Sua alocação de ativos será ajustada periodicamente, com base em movimentos do mercado, reavaliações anuais ou quando você fornecer informações atualizadas, para melhorar a probabilidade de atingir seu objetivo declarado.\n\nUma vez que você tenha atingido seu objetivo dentro do prazo especificado, seus ativos serão investidos na alocação de ativos mais conservadora até que você forneça direção adicional.`,
  },
  connectBank: {
    title: 'Confirme a sua conta.',
    subtitle: `Confirme os detalhes da sua conta bancária do ${FinTronConfig.parentName}.`,
    fundingSource: 'Minha conta',
    addBankAccount: 'Adicionar conta bancária',
    checkbox: '',
  },
  recurringRuleSlider: {
    recurringRules: 'REGRAS RECORRENTES',
    createRepeatInvestment: 'Criar um investimento repetido',
    automateInvestments: 'Automatize seus investimentos em milhares de ações e ETFs',
  },
  commonKycConfirmation: {
    title: 'Por favor, confirme as informações da conta.',
    subtitle: `Abaixo estão os detalhes que você forneceu para sua conta de investimento existente custodiada na ${FinTronConfig.clearingFirm}. Para facilitar para você, reutilizamos as informações que você forneceu para começar com sua aplicação de conta de investimento. Você sempre pode atualizar as informações da sua conta em Configurações. Saiba mais sobre como protegemos seus dados pessoais lendo a [Política de Privacidade do FinTron Invest](${FinTronConfig.selfDirected.privacyPolicyUrl}).`,
    investingTitle: 'Investindo',
    investmentObjectives: 'Objetivo de investimento',
    personalTitle: 'Pessoal',
    employmentStatus: 'Status de emprego',
    netWorth: 'Patrimônio líquido',
    annualIncome: 'Renda anual',
    exposureTitle: 'Exposição',
    isControlPerson: 'Pessoa de controle',
    isPoliticallyExposed: 'Politicamente exposto',
    isAffiliated: 'Membro da FINRA/Bolsa de valores',
    isSecuritiesProfessional: 'Profissional de valores mobiliários',
  },
  transfer: {
    reachedDailyLimitTitle: 'Não é possível enviar transferência',
    reachedDailyLimitDescription:
      'Sua transferência não foi enviada porque você atingiu seu limite de velocidade de 24 horas para ACH. Este limite existe para sua segurança. Seu limite restante é {{ remainingLimit }}. Se deseja aumentar (ou diminuir) seu limite, entre em contato com o suporte.',
    cancelUpcoming: {
      title: 'Atenção!',
      body: 'Está prestes a cancelar seu investimento recorrente. Tem certeza de que deseja interromper seu progresso?',
    },
    cancelAll: {
      title: 'Calma aí!',
      body: 'Está prestes a cancelar seu investimento recorrente. Tem certeza de que deseja interromper seu progresso?',
    },
    assetTypeSecurity: 'Investimento Recorrente',
    assetTypeFiat: 'Depósito Recorrente',
    error:
      'Sua solicitação para cancelar sua transferência recorrente não foi aprovada, por favor, tente novamente.',
    totalInvested: 'Total investido',
  },
  securities: {
    searchPlaceholder: 'Pesquisar por nome ou símbolo',
    totalSectors: 'Setores',
    top10Holdings: 'Top 10\nAtivos',
  },
  twoFactorAuth: {
    title: 'Autenticação de dois fatores',
    titleEmail: 'Confirmação de e-mail',
    titlePhone: 'Confirmar o celular',
    security: 'Segurança',
    password: 'Atualizar senha',
    method: 'Two-factor authentication method',
    sendText: 'Send text',
    sendEmail: 'Send email',
    descLogin:
      'How would you like to receive your two-factor authentication code for a secure login?',
    descToggle:
      'How would you like to receive your two-factor authentication code to toggle verification?',
    descCloseStockAccount:
      'How would you like to receive your two-factor authentication code to close your Stocks account?',
    descCloseRoboAccount:
      'How would you like to receive your two-factor authentication code to close your Robo account?',
    descVerification: {
      email: 'We will send you an email with the two-factor authentication code.',
      text: 'We will send you a text with the two-factor authentication code.',
    },
    enterCode: 'Insira o código',
    submit: 'Enviar',
    loginTitle: 'Login',
    variant: {
      login:
        'We just sent you a one-time two-factor authentication code to your mobile phone number. Enter the code below.',
      toggle: 'We just sent you a one-time two-factor authentication code. Enter the code below.',
      default:
        'Acabamos de enviar um código de autenticação. Digite o código abaixo para confirmar seu ',
      email: 'Verifique se recebeu um e-mail com seu código de verificação.',
      phoneNumber:
        'Acabamos de enviar um código de autenticação. Digite o código abaixo para confirmar seu número de telefone celular.',
    },
    helpText: `Se não encontrar, verifique sua pasta de spam/lixo eletrônico. Caso precise de assistência, entre em contato com nossa equipe: ${FinTronConfig.supportEmail}.`,
    didntReceiveCode: 'Não recebeu o código?',
    resendCode: 'Clique para reenviar',
    mfaSuccessDescription: 'Two-factor authentication setting has been changed successfully.',
    loginDescription:
      'Enviaremos um e-mail ou SMS com o código de autenticação de dois fatores para cada login.',
  },
  shortcutSlider: {
    header: 'Comece aqui',
    description: 'Buscar Ativos, Transferências e Mais',
    buttonText: 'Menu',
    shortcuts: {
      search: 'Buscar Ativos',
      withdraw: 'Resgate',
      history: 'Histórico de Atividades',
      deposit: 'Depósito',
      investments: 'Investimentos Automáticos',
      acat: 'ACAT',
    },
  },
  login: {
    loggedOut: 'Desconectado',
    loggedOutBody: 'Você foi desconectado devido à inatividade',
    accountDisabledTitle: 'Conta desativada!',
    accountDisabledBody: `Sua conta foi desativada. \nEntre em contato com [${FinTronConfig.supportEmail}](${FinTronConfig.contactUrl}) para obter assistência!`,
    updateAvailableTitle: 'Atualização disponível!',
    updateAvailableBody: {
      main:
        'Estamos muito felizes em anunciar que a última versão do aplicativo está disponível na ' +
        'App Store! Clique no botão abaixo para baixar o aplicativo e obter acesso às últimas ' +
        'atualizações e melhorias.',
      devOnly:
        'Nota para desenvolvedores: este diálogo não pode ser ignorado na versão de produção.',
    },
    noConnection: 'Sem conexão com a internet',
    noConnectionBody:
      'Parece que você está tendo problemas com sua conexão de internet! Verifique sua rede Wi-Fi ou serviço de dados móveis e tente novamente.',
    loseSomething: 'Perdeu algo?',
    loseSomethingBody: 'Estamos aqui para ajudar.',
  },
  forceUpdate: {
    updateAvailableTitle: 'Atualização disponível!',
    updateAvailableBody: {
      title:
        'Estamos muito felizes em anunciar que a última versão do aplicativo ({{remote}}) está disponível na App Store! Clique no botão abaixo para baixar o aplicativo e obter acesso às últimas atualizações e melhorias.',
      remote: 'Versão Atual:',
      devOnlyNote:
        'Nota para desenvolvedores: este diálogo não pode ser ignorado na versão de produção.',
    },
    updateButton: 'Atualizar',
    buttonDevOnly: 'APENAS DEV: PULAR',
  },
  retirementBeneficiary: {
    title: 'Deseja adicionar um beneficiário à sua conta?',
    learnMore: 'Saiba mais',
    addBeneficiaryButton: '+ Adicionar um beneficiário',
    beneficiariesHeaderTitle: 'Beneficiários primários',
    beneficiariesHeaderSubtile: 'É necessário pelo menos um beneficiário primário.',
    relationshipToYou: 'Relação com você',
  },
  allAccountsScreen: {
    welcomeString: 'Olá {{username}}',
    myAccountsTitle: 'Minhas contas',
    openStocksAccount: 'Abrir uma conta de ações',
    createAGoalTitle: 'Crie uma meta {{roboName}}',
    openARetirementAccountTitle: 'Abrir uma conta de aposentadoria',
    goalsTitle: 'Metas',
    cashCalendarTitle: 'Calendário de dinheiro',
  },
  initialInvestments: {
    initialInvestmentTitle: 'Investimento inicial',
    initialInvestmentDescription: 'Quanto você planeja investir hoje para alcançar sua meta?',
    targetAmountTitle: 'Valor-alvo',
    targetAmountDescription:
      'Defina um valor que você acredita ser alcançável. Definir um valor muito alto pode diminuir a probabilidade de atingir sua meta.',
    investmentAmountTitle: 'Investimentos adicionais',
    investmentAmountDescription:
      'Aumentar seus investimentos adicionais pode melhorar significativamente a probabilidade de atingir sua meta de poupança.',
    recurringInfusionTitle: 'Investimentos automáticos',
    recurringInfusionDescription:
      'Aumentar seus investimentos automáticos pode melhorar significativamente a probabilidade de atingir sua meta de poupança.',
  },
  miscScreens: {
    common: {
      companyName: 'Nome da empresa',
      ceo: 'CEO',
      sector: 'Setor',
    },
    contactUs: {
      title: 'Fale Conosco',
      submit: 'Enviar',
      successTitle: 'Formulário enviado',
      successTxt: 'Obrigado por enviar o formulário.',
    },
    goals: {
      edit: {
        title: 'Edite os detalhes da sua meta',
        investmentFrequencyTitle: 'Frequência do investimento\nautomático',
        investmentFrequencyDescription:
          'Configure investimentos automáticos e escolha a frequência do investimento.',
        pauseInvestmentTitle: 'Pausar investimentos recorrentes',
        pauseInvestmentDescription:
          'Tem certeza de que deseja pausar seus investimentos automáticos?\n\nPausar investimentos recorrentes por longos períodos pode impactar negativamente a probabilidade de atingir o valor total da sua meta no prazo desejado.',
        pauseInvestmentBottomText:
          'A realocação potencial desencadeada por essa mudança pode ter implicações fiscais.\n\nRetome seus investimentos a qualquer momento.',
        yesPause: 'Sim, Pausar Investimentos',
        yesResume: 'Sim, Retomar Investimentos',
        noCancel: 'Não, cancelar',
        resumeInvestmentTitle: 'Retomar investimentos recorrentes',
        resumeInvestmentDescription:
          'Tem certeza de que deseja retomar seus investimentos recorrentes?\n\nPausar investimentos recorrentes por longos períodos pode impactar negativamente a probabilidade de atingir o valor total da sua meta no prazo desejado.',
        resumeInvestmentBottomText:
          'A realocação potencial desencadeada por essa mudança pode ter implicações fiscais.\n\nAo retomar seus investimentos, o próximo investimento recorrente ocorrerá em {{date}}.',
        targetDate: 'Data-alvo',
      },
      close: {
        closeAccountTitle: 'Sua meta foi desativada',
        closeAccountDescription:
          'Essa meta não aparecerá mais na sua página Minhas Contas. Você sempre pode criar uma nova meta quando quiser.',
        home: 'Página Inicial',
      },
    },
    stocks: {
      accountHistory: 'Histórico da conta',
      documents: 'Documentos',
      investmentQuestions: 'Perguntas sobre investimentos',
      closeAccountTitle: 'Sua conta foi fechada',
      closeAccountDescription: `Se você quiser reabrir esta conta, entre em contato com ${FinTronConfig.selfDirected.supportEmail}.`,
      home: 'Página Inicial',
    },
    myAccounts: {
      title:
        'Holdings é apenas outra palavra para os ativos que você possui. Nesta seção, você verá:\n',
      checklist: [
        { name: 'Títulos que você possui' },
        { name: 'O dinheiro que você tem disponível para comprar títulos (Dinheiro disponível)' },
        {
          name: 'O dinheiro que está sendo processado da venda de um título ou depósitos pendentes (Dinheiro pendente)',
        },
      ],
      footer:
        'Após a venda de um título, levará 2 dias para que o dinheiro esteja disponível em sua conta para negociação ou saque.',
      viewData: 'Ver Dados',
      footerLegalText: `Os serviços de corretagem para ativos registrados são oferecidos a clientes self-directed pela ${FinTronConfig.selfDirected.name}. O dinheiro e os ativos são custodiados pela ${FinTronConfig.clearingFirm}.`,
      marketClosedTitle: `Mercado Fechado {{date}}`,
      marketClosedDescription: `O mercado de ações está fechado hoje. Se você realizar qualquer operação, ${FinTronConfig.parentName} irá processá-la no próximo dia útil. Os preços exibidos são baseados no último dia de funcionamento do mercado.`,
      repeatInvestments: 'Investimentos recorrentes',
    },
    plaid: {
      linkAccountButton: 'Selecionar uma conta bancária',
      createLinkErrorBody:
        'Lamentamos pelo transtorno, mas nosso parceiro seguro de vinculação bancária, Plaid, falhou ao iniciar. Saia da página e tente novamente. Em breve resolveremos isso para você!',
      createAccountErrorBody:
        'Nosso terceiro parceiro estava um pouco lento e o tempo expirou ao vincular sua conta bancária. \nPor favor, tente vincular sua conta bancária novamente. Pedimos desculpas pelo transtorno!',
    },
    bankLink: {
      removeBankTitle: 'Ações vinculadas',
      removeBankStep3Title: 'Conta desvinculada',
      removeBankCancelAllAndContinue: 'Cancelar tudo e continuar',
      removeBankSuccessTitle: 'Sucesso!',
      removeBankSuccessMessage: 'Sua conta bancária foi desvinculada com sucesso.',
      removeBodyMessage:
        'Você possui as seguintes funções automatizadas vinculadas a esta conta bancária. Para remover esta conta, você deve (1) atualizar essas funções automatizadas dentro do aplicativo para usar outra conta bancária ou (2) cancelar essas funções completamente. Observe que **cancelaremos quaisquer transferências pendentes associadas a esta conta bancária** em seu nome.',
    },
    tradeStopLoss: {
      tradeStopLossEnterText: 'Insira o preço de Stop Loss:',
      tradeStopLossTooLowError:
        'Parece que você inseriu um preço de stop que está acima do preço atual de mercado. Para colocar uma ordem de stop loss, insira um preço de stop que seja inferior ao preço de mercado exibido.',
      tradeStopLossOrderType: 'Stop loss',
      tradeStopLossSummaryLimitPriceLabel: 'Stop price',
      tradeStopLossSummaryLimitPriceTooHigh:
        'Parece que você inseriu um stop loss que está acima do preço atual de mercado. Para colocar uma ordem de stop loss, insira um stop loss que seja inferior ao preço de mercado exibido.',
    },
    tradeOptions: {
      tradeOptionsBuy: 'Comprar',
      tradeOptionsBuySubtitle: 'Adicione aos seus ativos.',
      tradeOptionsSell: 'Vender',
      tradeOptionsSellSubtitle: 'Efetue a venda total ou parcial deste ativo.',
      tradeOptionsRepeatInvestment: 'Investimento recorrente',
      tradeOptionsRepeatInvestmentSubtitle: 'Agende investimentos recorrentes.',
      tradeOptionsEditRepeatInvestment: 'Editar investimento recorrente',
      tradeOptionsEditRepeatInvestmentSubtitle: 'Edite investimento recorrente.',
      tradeOptionsRepeatInvestmentActive: 'Ativo',
      durationOptionsGoodForOneDay: 'Válido por 1 dia',
      durationOptionsGoodFor: 'Válido por',
      durationOptionsPopup:
        'Isso determina por quanto tempo sua ordem será válida se não for executada imediatamente.',
      tradeMarketBuySellLessThanADollarTitle: 'Atenção',
      tradeMarketBuySellLessThanADollarDesc:
        'Após esta ordem de venda, você ficaria com **{{amount}}** em **{{symbol}}**, que é menos do que o mínimo da ordem de venda de $1.\n\nVocê gostaria de incluir os **{{amount}}** restantes da **{{symbol}}** nesta ordem?',
      tradeMarketBuySellLessThanADollarSellAllButton: 'Sim',
      tradeMarketBuySellLessThanADollarKeepButton: 'Manter {{amount}} de {{symbol}}',
      stopLossPreviewInfo:
        'Este valor é o que suas participações atuais (menos quaisquer ordens de venda pendentes) valeriam no preço de stop que você especificou.\n\nNeste caso, suas [{{sharesToBeSold}}] ações em [{{securitySymbol}}] valeriam [{{availableAtStopLossPrice}}] se [{{securitySymbol}}] fosse negociada a [{{stopLoss}}], o preço de stop especificado.',
      stopLossPreviewSharesToBeSold: 'Ações a serem vendidas:',
      stopLossPreviewAvailableAtStopLossPrice: 'Disponível no preço de Stop Loss:',
      tradeLimitBuyOrderType: 'Compra limitada',
      tradeLimitBuyOrderLimit: 'Insira o preço limite',
      tradeLimitBuyOrderLimitDesc: 'Comprar quando o preço da ação cair abaixo de:',
      tradeLimitBuySummaryLimitPriceLabel: 'Preço limite',
      tradeLimitBuySummaryLimitPriceTooHigh:
        'Parece que você inseriu um preço limite que está acima do preço atual de mercado. Para colocar uma ordem limitada, insira um preço limite que seja inferior ao preço de mercado exibido.',
      tradeOptionsSelector: {
        tradeOptionsBuySubtitle: 'Adicione aos seus ativos.',
        tradeOptionsLimitBuySubtitle: 'Compre pelo preço que você definir.',
        tradeOptionsSellSubtitle: 'Venda pelo valor atual do mercado.',
        tradeOptionsStopLossSubtitle: 'Venda pelo preço definido por você.',
        tradeOptionsRepeatInvestment: 'Investimento recorrente',
        tradeOptionsRepeatInvestmentSubtitle: 'Investimentos automáticos agendados.',
      },
    },
    securities: {
      youOwnTitle: 'Você tem:',
      youOwnFooter: 'Ações',
      yourValueTitle: 'Valor:',
      yourValueFooter: 'Hoje',
      portfolioPercentTitle: 'Totalizando:',
      portfolioPercentFooter: 'Do Seu Portfólio',
      costPerShareTitle: 'Custo médio por ação',
      gainLostTitle: 'Seu ganho/perda:',
      dividendRate: 'Taxa de dividendos',
      dividendAmount: 'Montante de dividendos',
      totalDividendsReceived: 'Total de dividendos recebidos:',
      previousDividendReceived: 'Dividendos recebidos anteriormente:',
      securityNotificationRiskQuestion:
        'Estatisticamente falando, 99% do tempo, um conjunto de dados (ou, no nosso caso, o desempenho de um ativo) ficará dentro de 3 desvios padrão da média dos retornos desse conjunto de dados. O limite superior do Intervalo Histórico de 10 Anos que você vê aqui é calculado somando 3 desvios padrão ao retorno médio de 10 anos deste ativo. O limite inferior é calculado subtraindo 3 desvios padrão do retorno médio de 10 anos. Assim, o Intervalo Histórico de 10 Anos apresentado aqui exibirá o intervalo de desempenho de um ativo com 99% de precisão (Estatisticamente falando)!',
      securityNotificationRiskQuestion1:
        'A métrica de tolerância ao risco é o resultado de nossa avaliação proprietária de risco. Fornecemos esse Intervalo de Risco para você como um ponto de referência a considerar, baseado nas respostas que você nos deu ao preencher sua Avaliação de Risco. Acreditamos que assumir riscos excessivos leva a decisões de investimento ruins. Em essência, fornecemos este intervalo para que você se avalie antes de cometer erros! Se você assumir muito risco, talvez venda cedo demais quando deveria ter mantido sua posição. Talvez você simplesmente não tenha estabilidade financeira para arriscar muito em ações de alto risco... Em qualquer caso, observe que isso NÃO é uma recomendação, apenas uma camada extra de consideração que fornecemos a você como cortesia (:',
      tradingVolume: 'Volume do trade',
      tenDay: 'Volume médio (10 dias)',
      bidProvidedBy: 'Fonte: ',
      askProvidedBy: 'Fonte: ',
      mutualFund: {
        transactionFee: 'Taxa de operação',
        noFee: 'Sem taxa',
        nav: 'NAV',
        navChangeDoD: 'Mudança de NAV (DoD)',
        ytdReturns: 'Retornos YTD',
        yield30Day: 'Rendimento de 30d SEC',
        fundCategory: 'Categoria do fundo',
        fundInception: 'Inserção do fundo',
        loadType: 'Tipo de carga',
        load: 'Carga',
        minimumToInvest: 'Mínimo investimento',
        minimumSubsequentInvestment: 'Mínimo investimento seguinte',
        turnoverRate: 'Taxa de rotatividade',
        portfolioNetAssets: 'Ativos líquidos no portfólio',
        shareClassNetAssets: 'Ativos líquidos na classe de ações',
        twelveMonthLowHigh: 'Baixa-alta 12m',
        beta: 'Beta',
        rSquared: 'R2',
        sharpeRatio: 'Índice de Sharpe',
        standardDeviation: 'Volatilidade',
        initialInvestment: 'Investimento inicial',
        additionalInvestment: 'Investimento adicional',
        initialIraInvestment: 'Investimento inicial do IRA',
        additionalIraInvestment: 'Investimento adicional do IRA',
        initialGroupRetirementInvestment: 'Investimento inicial para aposentadoria',
        additionalGroupRetirementInvestment: 'Investimento adicional para aposentadoria',
        expenseRatioGross: 'Taxa de despesa (bruta)',
        expenseRatioNet: 'Taxa de despesa (líquida)',
        contractualExpenseWaiver: 'Isenção de despesa contratual (datada)',
        distributionServiceFee: 'Distribuição/taxa de serviço (12b-1)',
        performanceDisclaimer:
          'A performance representada é o desempenho passado e não se destina a implicar qualquer desempenho futuro do fundo. Ela garante o reinvestimento de dividendos e ganhos de capital, e não reflete taxas de venda, taxas de resgaste ou os efeitos dos impostos sobre quaisquer ganhos de capital e/ou distribuições.',
        averageAnnualReturns: 'Retornos anuais médios',
        disclosure: {
          line1:
            'Considere cuidadosamente os objetivos de investimento, riscos, encargos e despesas da empresa de investimento antes de investir. O prospecto e, se disponível, o prospecto resumido contêm esta e outras informações sobre a empresa de investimento e devem ser lidos atentamente antes de investir. Os prospectos podem ser encontrados em',
          searchMutualFundsButton: 'SEC.gov Mutual Fund Prospectuses Search.',
          searchMutualFundsLink:
            'https://www.sec.gov/search-filings/mutual-funds-search/mutual-fund-prospectuses-search',
          line2:
            'Os dados de desempenho citados representam o desempenho passado e o desempenho passado não garante resultados futuros. O retorno do investimento e o valor principal de um investimento irão flutuar de modo que as ações de um investidor, quando resgatadas, poderão valer mais ou menos do que o seu custo original; e que o desempenho atual pode ser inferior ou superior aos dados de desempenho citados.',
          line3:
            'Antes de investir, considere cuidadosamente os objetivos de investimento, riscos, encargos e despesas do fundo, incluindo taxas de administração, outras despesas e riscos especiais. Esta e outras informações podem ser encontradas no prospecto ou no prospecto resumido de cada fundo, se disponível. Sempre leia atentamente o prospecto ou prospecto resumido antes de investir ou enviar dinheiro.',
        },
      },
    },
    tradeDisplay: {
      tradeOrderBuy: 'Compra',
      tradeOrderSell: 'Venda',
      tradeOrderSubmitButton: 'Enviar',
      tradeOrderContinueButton: 'Continuar',
      tradeOrderSummaryTitle: 'Resumo da ordem',
      tradeOrderTypeLabel: 'Tipos de ordem',
      tradeOrderFrequencyLabel: 'Frequência',
      tradeOrderAmountLabel: 'Valor em dólares',
      tradeOrderEstimatedSharesLabel: 'Quantidade estimada de ações',
      tradeOrderOrderDuration: 'Duração da ordem',
      tradeOrderEstimatedValue: 'Valor estimado em dólar',
      tradeOrderEnterAmount: 'Insira o valor (maior ou igual a $5):',
      tradeOrderCashAvailable: `{{amount}} Disponível`,
      tradeOrderEstimatedCashHoldings: `Participações atuais: {{amount}}`,
      tradeOrderLimitOneDay: 'Válido por 1 dia',
      tradeOrderLimitTillCancelled: 'Válido até o cancelamento',
      tradeOrderBuySellOrderType: `{{side}} a mercado`,
      tradeOrderMarketRepeatSummary: `Seu pedido recorrente de **{{amount}}** **{{frequency}}** para comprar **{{symbol}}** foi enviado.`,
      tradeOrderMarketBuySellSummary:
        'Verifique o seu histórico de atividades para detalhes sobre esta operação.',
      tradeOrderMarketLimitBuyStopLossSummary: `Seu pedido **{{amount}} {{orderType}}** para {{side}} **{{symbol}}** foi enviado. Se o preço do ativo cair abaixo de **{{limit}}**, enviaremos uma ordem de mercado para {{side}}. A duração da ordem está definida como **{{duration}}.**`,
      tradeOrderSellAll: 'Vender Todas',
      tradeOrderStopLoss: 'Stop loss',
      tradeOrderStopLimit: 'Stop limit',
      tradeOrderStopPrice: 'Preço de stop',
      tradeOrderLimitBuy: 'Compra limitada',
      tradeOrderLimitPrice: 'Preço limite',
      tradeOrderLimitOrder: 'Ordem limitada',
      tradeOrderRepeatInvestment: 'Investimento recorrente',
      tradeScreenOrderTypesInfoTitle: 'Tipos de ordem',
      tradeScreenOrderStatus: 'Status da ordem',
      tradeScreenOrderTypesInfoBuySide:
        '**Compra a mercado:** Este tipo de ordem é usado para comprar uma ação ao preço de mercado vigente. Geralmente, as ordens a mercado são executadas imediatamente.\n' +
        '\n' +
        '**Compra Limitada:** Neste tipo de ordem, você pode definir um preço máximo pelo qual está disposto a comprar a ação. Quando o preço da ação atingir o valor estipulado, enviaremos uma ordem de compra automaticamente.',
      tradeScreenOrderTypesInfoSellSide:
        '**Venda a mercado:** Este tipo de ordem é usado para vender uma ação ao preço de mercado vigente. Geralmente, as ordens a mercado são executadas imediatamente.\n' +
        '\n' +
        '**Stop Loss:** Este tipo de ordem é usado para vender ações caso o preço do ativo cair abaixo de um determinado valor. Você define um preço mínimo de venda para a ação e se a ação atingir esse valor, uma ordem de venda será enviada automaticamente.',
      tradeOrderHighlyVolatile: `{{securitySymbol}} é um ativo altamente volátil! Na maioria dos casos, quanto maior a volatilidade de um ativo, maior o risco de perder parte ou todo o seu investimento. Considere seus objetivos de investimento e tolerância ao risco antes de investir.`,
      tradeOrderComplex: `{{securitySymbol}} é um ativo complexo, alavancado ou com características de risco. Você deve ter experiência de investimento de “Boa” a “Extensiva” para adquirir este ativo. Você pode alterar sua experiência de investimento acessando seu perfil de investimentos na página de configurações.`,
      tradeOrderRepeatInvestmentSummary: `Você está enviando um investimento recorrente, o que significa que está instruindo ${FinTronConfig.selfDirected.name} a transferir o valor solicitado de sua conta bancária conectada com a frequência escolhida. Investimentos recorrentes serão enviados a ${FinTronConfig.clearingFirm} para execução como uma ordem de Compra a Mercado com instrução de validade de Um Dia na abertura do mercado no terceiro dia útil após o processamento bem-sucedido da transferência de fundos.\nTítulos são oferecidos pela ${FinTronConfig.selfDirected.name} em caráter fracionário e custodiados pela ${FinTronConfig.clearingFirm}. Investidores devem estar cientes de que ações fracionárias representam propriedade parcial de um título. Investimentos podem perder valor e há risco de perda financeira. Serviços de corretagem são fornecidos pela ${FinTronConfig.selfDirected.name}, uma corretora registrada (membro FINRA & SIPC).`,
      tradeOrderSummaryLegal: `Os títulos são oferecidos pela ${FinTronConfig.selfDirected.name} em caráter fracionário, e custodiados pela ${FinTronConfig.clearingFirm}. Os investidores devem estar cientes de que as ações fracionárias representam propriedade parcial de um título. Os investimentos podem perder valor e existe o risco de perder dinheiro. Os serviços de corretagem são fornecidos pela ${FinTronConfig.selfDirected.name}., Membro FINRA/SIPC. A ${FinTronConfig.selfDirected.name} introduz transações em base totalmente divulgada para nossa empresa de compensação ${FinTronConfig.clearingFirm}.\n\nNada contido ou disponível para você por meio do site deve ser interpretado como uma solicitação ou recomendação para comprar ou vender títulos. Ao prosseguir com uma operação de títulos, você atesta sua avaliação independente dos méritos de investimento e riscos de produto de quaisquer títulos.`,
      bestBidOffer: 'Melhor lance/oferta',
      tradeScreenContinue: 'Página Inicial',
      tradeSellMFCaution: 'Atenção',
      tradeSellMFCautionMessageStart:
        'Sua ordem de venda é 90% ou maior do que a sua posse de fundo mútuo. Você deve reduzir o valor abaixo de',
      tradeSellMFCautionMessageEnd: 'ou Vender Tudo.',
      tradeSellMFCautionSellAll: 'Você gostaria de vender tudo?',
      tradeSellMFCautionSellAllYes: 'Sim, Vender Tudo',
      nbbo: {
        nbboQuoteBSMTitle: 'Melhor lance e oferta nacional (NBBO)',
        nbboQuoteBestBid: 'Melhor lance \n(Bid)',
        nbboQuoteBestAsk: 'Melhor oferta \n(Ask)',
        nbboQuoteBestBidAskDesc:
          'O National Best Bid and Offer (NBBO) é um regulamento da Securities Exchange Commission (SEC) que exige que os corretores negociem com o melhor preço de venda disponível (mais baixo) e o melhor preço de venda disponível (mais alto) ao comprar e vender títulos para clientes. O Melhor Lance e Oferta Nacional (NBBO) é o preço de compra ou venda que o cliente médio verá. O Securities and Exchange Commission Regulation NMS exige que os corretores garantam esse preço aos seus clientes.',
        nbboQuoteBidSize: 'Volume da compra \n(Bid)',
        nbboQuoteBidSizeDesc:
          'O “bid size” representa a quantidade de um ativo que investidores estão dispostos à comprar (bid) no preço de mercado atual. O “bid size” ajuda a determinar a “liquidez”, que indica a abilidade de alguém comprar um ativo rapidamente e a um preço favorável. Se não há “bids”, ninguém está comprando. O volume e preço do Bid são cotados pelas bolsas, que facilitam as vendas de títulos e fornecem cotações públicas de preços, volumes e outras métricas diversas. Abaixo de cada volume de bid você verá o mercado de origem da quotação.',
        nbboQuoteAskSize: 'Volume da oferta \n(Ask)',
        nbboQuoteAskSizeDesc:
          'O “ask size” representa a quantidade de um ativo que investidores estão oferecendo (ask) para venda no preço de mercado atual. O “ask size” ajuda a determinar a “liquidez”, que indica a abilidade de alguém vender um ativo rapidamente e a um preço favorável. Se não há “asks”, ninguém está vendendo. O volume e preço do ask são cotados pelas bolsas, que facilitam as vendas de títulos e fornecem cotações públicas de preços, volumes e outras métricas diversas. Abaixo de cada volume de ask você verá o mercado de origem da quotação.',
        lastTrade: 'Última negociação:',
        refreshedAt: 'Atualizado em:',
        refresh: 'Atualizar',
      },
      pennyStock: {
        pennyStockDisclosureBSMTitle: 'Divulgação de Penny Stocks',
        importantInformation: 'Informações importantes sobre penny stocks',
        statement:
          'Esta declaração é exigida pela Comissão de Valores Mobiliários dos EUA (SEC) e contém informações importantes sobre penny stocks. Recomendamos que você o leia antes de fazer uma compra ou venda.',
        pennyStocksCanBeRisky: 'Penny stocks podem ter um risco alto',
        pennyStockAreLowPriced: `\u2022 Penny stocks são ações de baixo preço de pequenas empresas não negociadas em bolsa ou cotadas na NASDAQ. Os preços muitas vezes não estão disponíveis. Os investidores em penny stocks muitas vezes não conseguem vender as ações de volta ao revendedor que lhes vendeu as ações. Assim, você pode perder seu investimento. Tenha cuidado com penny stocks recém-emitidas.`,
        pennyStockSalesPerson: `\u2022 Seu vendedor não é um consultor imparcial, mas é pago para lhe vender o estoque. Não confie apenas no vendedor, mas procure aconselhamento externo antes de comprar qualquer estoque. Se você tiver problemas com um vendedor, entre em contato com o responsável pela conformidade da empresa ou com os reguladores listados abaixo.`,
      },
    },
    deposits: {
      recurringTitle: 'Fazer depósito recorrente?',
      recurringDeposit: 'Depósito Recorrente',
      depositLimit: 'Limite de depósito instantâneo:',
      depositStatus: 'Status Do Depósito',
      singleDepositSummary:
        'Verifique o seu histórico de atividades para detalhes sobre esta operação.',
      depositPendingTitle: 'Pendente...',
      depositPendingSummary:
        'Seu pedido ainda está pendente. Verifique o histórico de transações para atualizações sobre a disponibilidade dos fundos.',
      getNamedFreqDisplayString: `Seu depósito recorrente de **{{amount}}** {{intervalKind}} foi enviado.`,
      depositLimits: 'Limites de depósito',
      depositSummary: 'Resumo do depósito',
      makeAnotherDeposit: 'Fazer outro depósito',
      infuseSummarySuccess: `Seu depósito de **{{amount}}** será investido no seu portfólio. Consulte seu histórico de atividades para detalhes.`,
      threeDayDepositAmount: 'Valor de depósito (3 dias)',
      limits: {
        instantDepositLimit: 'Limite de depósito instantâneo: ',
        instantDepositLimitDesc:
          'Calculamos esse limite utilizando uma parte do seu portfólio, descontando transferências recentes que ainda não tenham sido liquidadas em sua conta.',
        dailyLimit: 'Limite diário: ',
        dailyLimitDescription: `Existe um limite diário de {{limit}} para depósitos.`,
        dailyLimitDescription2: `Valores de transferência enviados dentro da janela de horários para depósitos no mesmo dia (7:00am – 4:00pm EST) estarão disponíveis imediatamente para negociação no horário de mercado. Os valores de transferência enviados após a janela de depósito não estarão disponíveis para negociação até o próximo dia útil.`,
      },
      fundingSource: 'Conta Bancária',
    },
    withdrawals: {
      title: 'Tem certeza de que deseja retirar todos os fundos?',
      description:
        `Retirar todos os fundos exigirá que ${FinTronConfig.robo.name} venda todos os títulos em sua conta.` +
        '\n Não é garantido que o valor em dólares exibido agora na tela seja igual ao valor em dólares na conta após a venda de todos os títulos e retirada para sua conta bancária.' +
        '\n\n *As retiradas têm consequências fiscais. Consulte um profissional de impostos ou acesse www.irs.gov para mais informações.',
      withdrawFunds: 'Retirar fundos',
      amounts: {
        available: 'Disponível:',
        withdrawButton: 'Retirar tudo',
        selectBank: 'Selecionar conta bancária',
        withdrawalDelay: 'Atraso na retirada',
        withdrawalDelayText1:
          'Para evitar lavagem de dinheiro e fraudes, colocamos uma breve retenção em todos os depósitos antes de serem retirados. Isso significa que, mesmo após o valor estar disponível para negociação, há uma retenção temporária nos fundos para retirada.',
        withdrawalDelayText2: `Se você tiver dúvidas sobre nossos procedimentos contra fraude e lavagem de dinheiro, entre em contato com [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
        withdrawAllText: `Pedidos de retirada de **{{value}}** ou mais automaticamente retirarão todos os fundos. \n\nSe sua retirada for 90% ou mais do valor do seu portfólio, retiraremos automaticamente todos os fundos. \n\nAo enviar esta transação, você autoriza ${FinTronConfig.clearingFirm} a vender o valor indicado de sua meta e depositar em sua conta bancária vinculada. Aguarde de 3 a 4 dias úteis para a conclusão da transação.`,
        withdrawStatus: 'Status da retirada',
        withdrawSummary: `Seu pedido para retirar **{{value}}** foi recebido. Os fundos geralmente serão liquidados em 3 a 4 dias úteis. Consulte seu histórico de atividades para detalhes.`,
        withdrawalSummaryTitle: 'Resumo da retirada',
        withdrawalPendingTitle: 'Pendente...',
        withdrawalPendingSummary:
          'Seu pedido ainda está pendente. Verifique o histórico de transações para atualizações sobre a disponibilidade dos fundos.',
      },
    },
    transfers: {
      transferTypeTitle: 'Tipo de transferência',
      bankNameTitle: 'Conta bancária',
      dollarValueTitle: 'Valor em dólar',
      estimatedDollarValueTitle: 'Valor estimado em dólar',
      recurringSummaryDescription: `Iniciaremos um depósito {{recurringInterval}}, começando em {{userSelectedDate}}. Os depósitos serão liquidados dentro de 3 dias úteis após serem iniciados.`,
      instantTitle: 'Instantâneo',
      regularTitle: 'Regular',
      estimatedSettlementDescription: `Liquidação Estimada {{settlementDate}}`,
      totalAmountTitle: 'Valor total',
      depositSummaryLegalText: `Os depósitos em dinheiro são custodiados pelo ${FinTronConfig.clearingFirm}, que automaticamente colocará seus depósitos em dinheiro neste programa. Se você optar por não participar do ${FinTronConfig.clearingFirm}, seus depósitos não serão assegurados pelo FDIC. Você pode cancelar a inscrição neste programa a qualquer momento. Os Serviços de Corretagem são oferecidos através da ${FinTronConfig.selfDirected.name}, uma corretora registrada, membro FINRA & SIPC, e não pelo Bradesco Bank. Os produtos de valores mobiliários adquiridos ou vendidos em uma operação não são assegurados pela Federal Deposit Insurance Corporation (FDIC), não são depósitos ou outras obrigações do Bradesco Bank, e não são garantidos pelo Bradesco Bank; e estão sujeitos a riscos de investimento, incluindo possível perda do investimento principal.`,
      returnedTransferFee: 'Taxa de transferência retornada',
    },
    activityHistory: {
      recentActivity: 'Atividades Recentes',
      myHistory: 'Meu Histórico',
      filter: {
        title: 'Filtrar por categoria',
        selectDate: 'Selecione datas',
        to: 'até',
        orders: 'Ordens',
        transfers: 'Transferências',
        deposits: 'Depósitos',
        withdrawal: 'Saque',
        dividends: 'Dividendos',
        interests: 'Juros',
        acat: 'ACAT',
        buy: 'Compra',
        sell: 'Venda',
        all: 'Todas',
        filterButton: 'Filtrar',
        clearButton: 'Limpar',
      },
      popups: {
        transferringFrom: 'Transferindo de',
        transferringTo: 'Transferência para',
        dividendReceived: 'Dividendo recebido',
        dividendReinvested: 'Dividendo reinvestido',
        qualifiedDividend: 'Dividendo qualificado',
        qualifiedDividendReinvested: 'Dividendo qualificado reinvestido',
        capitalGainsDistribution: 'Distribuição de ganhos de capital',
        capitalGainsDistributionReinvested: 'Distribuição de ganhos de capital reinvestido',
        returnOfCapitalDistribution: 'Retorno de capital',
        returnOfCapitalDistributionReinvested: 'Retorno de capital reinvestido',
        depositNotice:
          'Se o seu depósito instantâneo já foi processado, o cancelamento deste depósito apenas será aplicado aos depósitos recorrentes.',
        cancelNotAllowedNotice:
          'Sua ordem foi enviada para processamento e não pode mais ser cancelada. Você receberá seu preço de execução na manhã seguinte.',
      },
    },
  },
  generalInvestments: {
    allocation: 'Alocação',
    choosePortfolio: 'Escolher Portfólio',
    changePortfolio: 'Alterar Portfólio',
    currentPortfolio: 'Este é o seu portfólio atual',
    saveAndUpdatePortfolio: 'Salvar e Atualizar Portfólio',
    editQuestionsText: 'Selecione aqui para editar suas perguntas de investidor.',
    recommended: 'Recomendado',
    recommendedDescription:
      'Este nível de risco é o mais recomendado com base em suas respostas no perfil de investidor.',
    notRecommended: 'Não Recomendado',
    notRecommendedDescription:
      'Este nível de risco não é recomendado com base em suas respostas no perfil de investidor.',
    notRecommendedSubDescription: 'Selecione aqui para ir ao portfólio recomendado.',
    cantRecommendDescription:
      'Este nível de risco não pode ser selecionado com base em suas respostas no perfil de investidor.',
    bsm: {
      recommended: 'Confirmar Seleção',
      notRecommended: 'Portfólio não recomendado',
      recommendedText:
        'Você está prestes a escolher o portfólio recomendado. O portfólio escolhido pode ser alterado posteriormente na página de Configurações.',
      notRecommendedText:
        'Este portfólio não é recomendado com base no seu perfil de investidor. Tem certeza de que deseja escolher este portfólio?',
      continueBtnText: 'Continuar com este portfólio',
    },
    stockScreen: {
      holdings: 'Ativos',
      topTen: 'Top 10 Ativos',
      viewAll: 'Ver todos',
      other: 'Outros',
      availableCash: 'Saldo Disponível:',
      pendingCash: 'Saldo Pendente:',
      costPerShare: 'Custo médio\npor ação:',
      value: 'Valor:',
      gainLoss: 'Ganho/Perda:',
      shares: 'Ações',
    },
    allHoldingsScreen: {
      title: `Ativos de {{etfName}}`,
    },
    editDetailsScreen: {
      title: 'Edite seus valores de\ninvestimento automatizado',
      pauseInvestments: 'Pausar investimentos',
      resumeInvestments: 'Retomar investimentos',
      saveButtonText: 'Salvar',
      cancelButtonText: 'Cancelar',
      recurringInvestments: 'Investimentos recorrentes',
      investmentAmount: 'Valor',
      investmentFrequency: 'Frequência',
      fundingSource: 'Fonte de financiamento',
      active: 'Ativo',
      paused: 'Pausado',
      targetAmount: 'Valor alvo',
      target: 'Alvo',
      initialInvestment: 'Investimento inicial',
      cancelNext: 'Cancelar próximo',
      canceledUntil: `Cancelado até {{date}}`,
    },
  },
  robo,
  settings,
  widgets,
  gi,
};
