import WithholdingElectionInnerForm from './WithholdingElectionInnerForm';
import Skeleton from '@/components/common/Skeleton/Skeleton';
import { WebBodyText20 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { useWithholdings } from '@/hooks/api/useWithholdings';
import cn from '@/utils/tailwind';

export type WithholdingElectionFormProps = {
  isSidePanel?: boolean;
};

const displayStrings: Record<string, string> = {
  headingText: 'Settings - Traditional IRA self-directed',
  headingSubText: 'Withholding elections',
};

/*
 * Wrapper component responsible for fetching required data dependencies
 * and rendering the form only after all dependencies are successfully loaded.
 *
 * Ensures form states remain stable and prevents unexpected state changes.
 */
export const WithholdingElectionForm = (props: WithholdingElectionFormProps) => {
  const { isSidePanel } = props;
  const { loading, ...withholdingData } = useWithholdings();

  return (
    <div className="mt-4">
      <WebBodyText20.Bold
        className={cn({
          'text-darkestBlue': isSidePanel,
          'text-grey55': !isSidePanel,
        })}
      >
        {displayStrings.headingSubText}
      </WebBodyText20.Bold>

      {loading ? (
        <Skeleton className="mt-6 w-full" height={390} />
      ) : (
        <WithholdingElectionInnerForm {...props} {...withholdingData} />
      )}
    </div>
  );
};
