import { useState, useLayoutEffect, useCallback } from 'react';
import useBreakpoint from '@/hooks/utils/useBreakpoint';

export type SingleBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
export type BreakpointMatchers = Array<SingleBreakpoint>;
export type ResizeCallback = (itMatches: boolean) => void;

export const Breakpoints: Record<
  'Mobile' | 'Tablet' | 'Desktop' | 'MediumOrSmaller' | 'ExtraSmallAndSmall',
  BreakpointMatchers
> = {
  Mobile: ['xs'],
  Tablet: ['sm', 'md'],
  Desktop: ['lg', 'xl', '2xl'],
  ExtraSmallAndSmall: ['xs', 'sm'],
  MediumOrSmaller: ['xs', 'sm', 'md'],
};

/**
 * useBreakpointResizeMatcher
 *
 * Returns boolean whether the window size matches the breakpoint provided or not.
 * Receives callback as parameter which is called everytime the window size changes (optional).
 */
const useBreakpointResizeMatcher = (
  breakpoint: SingleBreakpoint | BreakpointMatchers,
  callback?: ResizeCallback,
): boolean => {
  const [itMatches, setItMatches] = useState(false);
  const { isBreakpoint } = useBreakpoint();

  const isOneBreakpointOf = useCallback(
    (breakpoints: BreakpointMatchers) => breakpoints.some((b) => isBreakpoint(b)),
    [isBreakpoint],
  );

  const job = useCallback(() => {
    const isSingleBreakpoint = typeof breakpoint === 'string';
    const x = isOneBreakpointOf(isSingleBreakpoint ? [breakpoint] : breakpoint);

    setItMatches(x);
    callback?.(x);
  }, [breakpoint, callback]);

  /* init */
  useLayoutEffect(() => job(), [job]);

  /* resize handler */
  useLayoutEffect(() => {
    const windowResizeHandler = () => job();
    window.addEventListener('resize', windowResizeHandler);
    return () => window.removeEventListener('resize', windowResizeHandler);
  }, [job]);

  return itMatches;
};

export default useBreakpointResizeMatcher;
