import { Popover, PopoverContent, PopoverTrigger } from '../../Popover';
import { Dialog, DialogContent, DialogTrigger } from '@/components/common/Dialog';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import { useMemo } from 'react';

const useResponsiveDatepicker = () => {
  const { isMobileBreakpoint, isDesktopBreakpoint } = useBreakpoint();

  const isMobile = isMobileBreakpoint() || isDesktopBreakpoint();

  const currentDatepickerComponent = useMemo(
    () => ({
      Root: isMobile ? Dialog : Popover,
      Trigger: isMobile ? DialogTrigger : PopoverTrigger,
      Content: isMobile ? DialogContent : PopoverContent,
    }),
    [isMobile],
  );

  return { ResponsiveDatepicker: currentDatepickerComponent };
};

export default useResponsiveDatepicker;
